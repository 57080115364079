import React from 'react'
import { Cell } from 'react-foundation'
import { Dispatch } from 'react-redux'
import Card from '../card/Card'
import EventDetails from './EventDetails'
import EventDetailsHeader from './EventDetailsHeader'
import { IDirectoryEventSearchMasterEventResult } from '../../models/Directory'

interface Props {
  clearSelection: () => void
  dispatch: Dispatch
  eventData: IDirectoryEventSearchMasterEventResult
}

const EventDetailsCard: React.SFC<Props> = ({
  clearSelection,
  dispatch,
  eventData
}) => {
  return (
    <Cell>
      <Card
        className='event-details-card'
        header={
          <EventDetailsHeader
            title='Current Selection'
            onClear={clearSelection}
          />
        }
        section={
          <>
            <div>details</div>
            <EventDetails
              dispatch={dispatch}
              eventData={eventData}
            />
          </>
        }
      />
    </Cell>
  )
}
export default EventDetailsCard