import { Transerializable } from '@athlinks/get-or'
import {
    IDirectoryEventRefreshResult
} from './Directory'

export interface IDirectoryEventRefreshState {
  errorMessage: string,
  isLoading: boolean,
  result: IDirectoryEventRefreshResult
}

export class DirectoryEventRefreshState extends Transerializable
  implements IDirectoryEventRefreshState {
  errorMessage: string = ''
  isLoading: boolean = false
  result: IDirectoryEventRefreshResult = undefined
}