import { Action } from 'redux'
import {
  DirectoryEventActionTypes,
  DirectoryPlatformsActionTypes,
  DirectoryLinkingActionTypes
} from '../actions/external/types'
import {
  IDirectoryEventSearchResponse,
  IDirectoryCreateDatumResponse,
  IDirectoryDeleteDatumResponse,
  IDatumType,
  IDirectoryEntriesLinkResponse,
  IDirectoryEventRefreshResult
} from '../models/Directory'
import {
  IPlatform,
  IPlatformsResult
} from '../models/Platforms'

import {
  DirectorySearchDataActionTypes,
  DirectoryUserPrefsActionTypes,
  InternalLinkingDataActionTypes
} from '../actions/types'

// Platforms
export interface PlatformsAction extends Action {
  type: DirectoryPlatformsActionTypes.DIRECTORY_PLATFORMS
}

export const platforms = (): PlatformsAction => ({
  type: DirectoryPlatformsActionTypes.DIRECTORY_PLATFORMS
})

export interface PlatformsCompleteAction extends Action {
  type: DirectoryPlatformsActionTypes.DIRECTORY_PLATFORMS_COMPLETE,
  platformsResult: IPlatformsResult
}

export const platformsComplete = (platformsResult: IPlatformsResult):
  PlatformsCompleteAction => ({
  type: DirectoryPlatformsActionTypes.DIRECTORY_PLATFORMS_COMPLETE,
  platformsResult
})

export interface PlatformsErrorAction extends Action {
  type: DirectoryPlatformsActionTypes.DIRECTORY_PLATFORMS_ERROR,
  error: {message: string}
}

export const platformsError = (error: string): PlatformsErrorAction => ({
  type: DirectoryPlatformsActionTypes.DIRECTORY_PLATFORMS_ERROR,
  error: {message: error}
})

// Event Search
export interface DirectoryEventSearchAction extends Action {
  type: DirectoryEventActionTypes.DIRECTORY_EVENT_SEARCH
}

export interface DirectoryEventSearchCompleteAction extends Action {
  type: DirectoryEventActionTypes.DIRECTORY_EVENT_SEARCH_COMPLETE,
  eventSearchResult: IDirectoryEventSearchResponse
}

export interface DirectoryEventSearchErrorAction extends Action {
  type: DirectoryEventActionTypes.DIRECTORY_EVENT_SEARCH_ERROR,
  error: {message: string}
}

export interface DirectoryChangePlatformAction extends Action {
  type: DirectorySearchDataActionTypes.DIRECTORY_CHANGE_PLATFORM,
  platform: IPlatform
}

export const changePlatform = (
    platform: IPlatform
  ): DirectoryChangePlatformAction => ({
  type: DirectorySearchDataActionTypes.DIRECTORY_CHANGE_PLATFORM,
  platform
})

export interface DirectoryChangeTypeAction extends Action {
  type: DirectorySearchDataActionTypes.DIRECTORY_CHANGE_TYPE,
  datumType: IDatumType
}

export const changeType = (datumType: IDatumType): DirectoryChangeTypeAction => ({
  type: DirectorySearchDataActionTypes.DIRECTORY_CHANGE_TYPE,
  datumType
})

export interface DirectoryChangeIDAction extends Action {
  type: DirectorySearchDataActionTypes.DIRECTORY_CHANGE_ID,
  id: string
}

export const changeEventId = (id: string): DirectoryChangeIDAction => ({
  type: DirectorySearchDataActionTypes.DIRECTORY_CHANGE_ID,
  id
})

export interface DirectoryClearEventAction extends Action {
  type: DirectorySearchDataActionTypes.DIRECTORY_CLEAR_EVENT
}

export const clearEventSelection = (): DirectoryClearEventAction => ({
  type: DirectorySearchDataActionTypes.DIRECTORY_CLEAR_EVENT
})

// datum create
export interface DirectoryCreateDatumAction extends Action {
  type: DirectoryEventActionTypes.DIRECTORY_CREATE_DATUM
}

export interface DirectoryCreateDatumCompleteAction extends Action {
  type: DirectoryEventActionTypes.DIRECTORY_CREATE_DATUM_COMPLETE,
  createDatumResult: IDirectoryCreateDatumResponse
}

export interface DirectoryCreateDatumErrorAction extends Action {
  type: DirectoryEventActionTypes.DIRECTORY_CREATE_DATUM_ERROR,
  error: {message: string}
}

// datum delete
export interface DirectoryDeleteDatumAction extends Action {
  type: DirectoryEventActionTypes.DIRECTORY_DELETE_DATUM
}

export interface DirectoryDeleteDatumCompleteAction extends Action {
  type: DirectoryEventActionTypes.DIRECTORY_DELETE_DATUM_COMPLETE,
  deleteDatumResult: IDirectoryDeleteDatumResponse
}

export interface DirectoryDeleteDatumErrorAction extends Action {
  type: DirectoryEventActionTypes.DIRECTORY_DELETE_DATUM_ERROR,
  error: {message: string}
}

// Entries Link
export interface DirectoryEntriesLinkAction extends Action {
  type: DirectoryLinkingActionTypes.DIRECTORY_ENTRIES_LINK,
  args: any
}

export interface DirectoryEntriesLinkCompleteAction extends Action {
  type: DirectoryLinkingActionTypes.DIRECTORY_ENTRIES_LINK_COMPLETE,
  args: any,
  linkEntriesResult: IDirectoryEntriesLinkResponse
}

export interface DirectoryEntriesLinkErrorAction extends Action {
  type: DirectoryLinkingActionTypes.DIRECTORY_ENTRIES_LINK_ERROR,
  args: any,
  error: {message: string}
}

// Refresh Event
export interface DirectoryEventRefreshAction extends Action {
  type: DirectoryEventActionTypes.DIRECTORY_EVENT_REFRESH
}

export interface DirectoryEventRefreshCompleteAction extends Action {
  type: DirectoryEventActionTypes.DIRECTORY_EVENT_REFRESH_COMPLETE,
  refreshEventResult: IDirectoryEventRefreshResult
}

export interface DirectoryEventRefreshErrorAction extends Action {
  type: DirectoryEventActionTypes.DIRECTORY_EVENT_REFRESH_ERROR,
  error: {message: string}
}

export interface DirectoryClearLinkingActiveSelection extends Action {
  type: InternalLinkingDataActionTypes.CLEAR_LINKING_ACTIVE_SELECTION
}

export const clearLinkingActiveSelection = (): DirectoryClearLinkingActiveSelection => ({
  type: InternalLinkingDataActionTypes.CLEAR_LINKING_ACTIVE_SELECTION
})

// User Prefs
export interface DirectorySetUserPrefsAction extends Action {
  type: DirectoryUserPrefsActionTypes.DIRECTORY_SET_USER_PREFS,
  userPrefs: any
}

export const setUserPrefs = (
  userPrefs: any
): DirectorySetUserPrefsAction => ({
  type: DirectoryUserPrefsActionTypes.DIRECTORY_SET_USER_PREFS,
  userPrefs
})