import { mergeMap } from 'rxjs/operators'
import { Observable } from 'rxjs'
import {
  eventSearch
} from '../../actions/external/eventSearch'
import { StateObservable, ofType } from 'redux-observable'
import { IState } from '../../models/index'
import { DirectoryEventActionTypes } from '../../actions/external/types'

import {
    DirectoryCreateDatumCompleteAction,
    DirectoryEventSearchAction
  } from '../../actions/directory'

export function findCreatedEvent(
  actions$: Observable<DirectoryCreateDatumCompleteAction>,
  state$: StateObservable<IState>
): Observable<DirectoryEventSearchAction> {
  return actions$.pipe(
    ofType(DirectoryEventActionTypes.DIRECTORY_CREATE_DATUM_COMPLETE),
    mergeMap(async (action$: DirectoryCreateDatumCompleteAction) => {
      const {
        app,
        directoryState: {
          searchData: {
            platform,
            datumType,
            id
          }
        }
      } = state$.value
      /*
        TODO For now we can just redirect to created event
        But there should probably be some id validation in the future
      */
      const searchAction = eventSearch({
        platform: platform.id,
        type: datumType.value,
        id,
        pullMetadata: true,
        token: app.token
      })
      return searchAction
    })
  )
}