import React, { Component } from 'react'
import { applyRulesToProps } from '@athlinks/lib-react-utils'

let styles = require('./ImageComponent.scss')

export interface UnitAble {
    units: 'rem' | 'em' | 'pt' | 'px'
}

export interface IImageProps extends UnitAble {
    height?: string | number
    width?: string | number
}

export abstract class ImageComponent extends Component<IImageProps> {
  divClass: string

  public getSize() {
    return applyRulesToProps<IImageProps, {}>(
      {
        props: ['height', 'width', 'units'],
        rule: props => ({
          height: `${props.height}${props.units}`,
          width: `${props.width}${props.units}`
        })
      },
      {
        props: ['width', 'units'],
        rule: props => ({
          width: `${props.width}${props.units}`
        })
      },
      {
        props: ['height', 'units'],
        rule: props => ({
          height: `${props.height}${props.units}`
        })
      }
    )(this.props)
  }

  public baseRender(className: string) {
    return (
      <div className={className} style={this.getSize()} />
    )
  }

  public render() {
    return this.baseRender(styles.image)
  }
}
