import { InternalClientActionType } from './types'
import {
  InternalAction,
  DataAction,
  DataActionCreator,
  InternalErrorAction,
  InternalDataErrorCreator
} from '../../actions'
import {
  PlatformsArgs,
  PlatformsAction
} from '../external/platforms'
import { IInternalActionCreator } from '../../reducers/external/actionMapper'

export interface PlatformsInternalAction
  extends InternalAction, DataAction<PlatformsArgs> {
  type: InternalClientActionType.PLATFORMS
}

export const platformsInternal:
  IInternalActionCreator<PlatformsInternalAction, PlatformsAction> = (
    action: PlatformsAction
  ): PlatformsInternalAction => ({
    ...action,
    type: InternalClientActionType.PLATFORMS
  })

export interface PlatformsCompleteInternalAction
  extends InternalAction, DataAction<PlatformsArgs> {
    type: InternalClientActionType.PLATFORMS_COMPLETE,
    platformsResult: any // TODO Pending
  }

export const platformsCompleteInternal:
  DataActionCreator<PlatformsCompleteInternalAction, PlatformsArgs> = (
    platformsResult: any, // TODO Pending
    args: PlatformsArgs
  ): PlatformsCompleteInternalAction => ({
    type: InternalClientActionType.PLATFORMS_COMPLETE,
    platformsResult,
    args
  })

export interface PlatformsErrorInternalAction
  extends InternalErrorAction<PlatformsArgs>, DataAction<PlatformsArgs> {
    type: InternalClientActionType.PLATFORMS_ERROR
  }

export const platformsErrorInternal:
  InternalDataErrorCreator<PlatformsErrorInternalAction, PlatformsArgs> = (
    args: PlatformsArgs,
    error: any
  ): PlatformsErrorInternalAction => {
    return {
      type: InternalClientActionType.PLATFORMS_ERROR,
      error,
      args
    }
  }