import React from 'react'
import { Trans } from 'react-i18next'
import './Button.scss'

interface Props {
  className?: string,
  label: string,
  onClick: () => void,
  disabled?: Boolean,
  primary?: Boolean
}

const DirectoryActionButton: React.SFC<Props> = ({
  className,
  label,
  onClick,
  disabled
}) =>
  <div
    onClick={disabled ? () => null : onClick}
    className={`button-container ${disabled ? 'disabled ' : ''}${className || ''}`}
  >
    <Trans>{label}</Trans>
  </div>

export default DirectoryActionButton