import { IExternalCalendarTime } from '../models/Directory'
import { dateWithoutTime } from './directoryUtil'
import { convertCalendarTime, CommonDateFormats } from '@athlinks/lib-units'

export const shortDate = (date: IExternalCalendarTime) => !date
? '-'
: dateWithoutTime(
  convertCalendarTime(
    {
      timeInMillis: parseInt(date.timeInMilliseconds, 10),
      timeZone: date.timeZone
    },
    {
      dateFormat: CommonDateFormats.MONTH_DAY_YEAR_SLASHES
    }
  ).flatten())
