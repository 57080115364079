export enum InternalClientActionType {
  COMPARE_REG = 'COMPARE_REG',
  COMPARE_REG_COMPLETE = 'COMPARE_REG_COMPLETE',
  COMPARE_REG_ERROR = 'COMPARE_REG_ERROR',
  CREATE_DATUM = 'CREATE_DATUM',
  CREATE_DATUM_COMPLETE = 'CREATE_DATUM_COMPLETE',
  CREATE_DATUM_ERROR = 'CREATE_DATUM_ERROR',
  DELETE_DATUM = 'DELETE_DATUM',
  DELETE_DATUM_COMPLETE = 'DELETE_DATUM_COMPLETE',
  DELETE_DATUM_ERROR = 'DELETE_DATUM_ERROR',
  EVENT_SEARCH = 'EVENT_SEARCH',
  EVENT_SEARCH_COMPLETE = 'EVENT_SEARCH_COMPLETE',
  EVENT_SEARCH_ERROR = 'EVENT_SEARCH_ERROR',
  PLATFORMS = 'PLATFORMS',
  PLATFORMS_COMPLETE = 'PLATFORMS_COMPLETE',
  PLATFORMS_ERROR = 'PLATFORMS_ERROR',
  LINK_ENTRIES = 'LINK_ENTRIES',
  LINK_ENTRIES_COMPLETE = 'LINK_ENTRIES_COMPLETE',
  LINK_ENTRIES_ERROR = 'LINK_ENTRIES_ERROR',
  REFRESH_EVENT = 'REFRESH_EVENT',
  REFRESH_EVENT_COMPLETE = 'REFRESH_EVENT_COMPLETE',
  REFRESH_EVENT_ERROR = 'REFRESH_EVENT_ERROR'
}