import { Transerializable } from '@athlinks/get-or'
import {
    IDirectoryEntriesLinkResult
} from './Directory'

export interface IDirectoryEntriesLinkState {
  errorMessage: string,
  isLoading: boolean,
  result: IDirectoryEntriesLinkResult,
  activeEntry: string
}

export class DirectoryEntriesLinkState extends Transerializable
  implements IDirectoryEntriesLinkState {
  errorMessage: string = ''
  isLoading: boolean = false
  result: IDirectoryEntriesLinkResult = undefined
  activeEntry: string = ''
}