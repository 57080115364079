import { ActionsObservable, ofType } from 'redux-observable'
import {
  CompareRegInternalAction,
  CompareRegCompleteInternalAction,
  CompareRegErrorInternalAction,
  compareRegCompleteInternal,
  compareRegErrorInternal
} from '../actions/internal/compareReg'
import {
  Observable,
  from,
  of
} from 'rxjs'
import { InternalClientActionType } from '../actions/internal/types'
import {
  flatMap,
  catchError
} from 'rxjs/operators'
import { waitForData } from './util'
import { compareRegQueryBuilder } from './util/queries'

export function compareRegEpic(
  actions$: ActionsObservable<CompareRegInternalAction>
): Observable<CompareRegCompleteInternalAction |
  CompareRegErrorInternalAction> {
    return actions$.pipe(
      ofType(InternalClientActionType.COMPARE_REG),
      flatMap(action$ => {
        const { args } = action$
        return from(
          waitForData(
            args,
            compareRegQueryBuilder,
            compareRegCompleteInternal,
            compareRegErrorInternal
          )
        ).pipe(
          catchError(err => of(err))
        )
      }),
      catchError(err => of(err))
    )
  }