import { flow, over, partialRight, reduce } from 'lodash'

export enum Environment {
  LOCAL = 'LOCAL',
  LOCALSTAGE = 'LOCALSTAGE',
  STAGE = 'STAGE',
  PROD = 'PROD'
}

export const buildRegExp = (
  baseUrl: string
) => new RegExp(`(${baseUrl})`)

export const matchEnvironment = (
  environment: Environment,
  baseUrl: string
) => (
  path: string
): Environment => {
  const exists = buildRegExp(baseUrl).test(path)
  console.log('exists', exists, environment, baseUrl, path)
  return exists ? environment : undefined
}

export const determineEnvironment: (path: string) => Environment = flow(
  over(
    [
      matchEnvironment(
        Environment.LOCAL,
        'http://localhost'
      ),
      matchEnvironment(
        Environment.LOCALSTAGE,
        'http://hub-stage.athlinks.com'
      ),
      matchEnvironment(
        Environment.STAGE,
        'https://hub-stage.athlinks.com'
      ),
      matchEnvironment(
        Environment.PROD,
        'https://hub.athlinks.com'
      )
    ]),
  partialRight(
    reduce,
    (acc: Environment, val: Environment) => val || acc,
    undefined
  )
)

let environ: Environment = undefined

export const getEnvironment = (path: string = window.location.href) => {
  if (!environ) {
    environ = determineEnvironment(path)
  }
  return environ
}