import {
  ExternalAction,
  ExternalErrorAction,
  QueryArgs
} from '../../actions'
import { DirectoryEventActionTypes } from './types'
import { IExternalActionCreator } from '../../reducers/external/actionMapper'
import {
  RefreshEventCompleteInternalAction,
  RefreshEventErrorInternalAction
} from '../internal/refreshEvent'

export interface RefreshEventArgs extends QueryArgs {
  platform: string,
  type: string,
  id: string,
  token: string
}

export interface RefreshEventAction
  extends ExternalAction<RefreshEventArgs> {
  type: DirectoryEventActionTypes.DIRECTORY_EVENT_REFRESH
}

export const refreshEvent =
  (args: RefreshEventArgs): RefreshEventAction => ({
    args,
    type: DirectoryEventActionTypes.DIRECTORY_EVENT_REFRESH
  })

export interface RefreshEventCompleteAction
  extends ExternalAction<RefreshEventArgs> {
    type: DirectoryEventActionTypes.DIRECTORY_EVENT_REFRESH_COMPLETE,
    refreshEventResult: any
}

export const refreshEventComplete:
  IExternalActionCreator<RefreshEventCompleteAction,
  RefreshEventCompleteInternalAction> = (
    action: RefreshEventCompleteInternalAction
  ): RefreshEventCompleteAction => ({
    ...action,
    type: DirectoryEventActionTypes.DIRECTORY_EVENT_REFRESH_COMPLETE
  })

export interface RefreshEventErrorAction
  extends ExternalErrorAction<RefreshEventArgs> {
  type: DirectoryEventActionTypes.DIRECTORY_EVENT_REFRESH_ERROR
}

export const refreshEventError:
  IExternalActionCreator<RefreshEventErrorAction,
  RefreshEventErrorInternalAction> = (
    action: RefreshEventErrorInternalAction
  ): RefreshEventErrorAction => ({
    ...action,
    type: DirectoryEventActionTypes.DIRECTORY_EVENT_REFRESH_ERROR
  })