import { ActionsObservable, ofType } from 'redux-observable'
import {
  PlatformsInternalAction,
  PlatformsCompleteInternalAction,
  PlatformsErrorInternalAction,
  platformsCompleteInternal,
  platformsErrorInternal
} from '../actions/internal/platforms'
import {
  Observable,
  from,
  of
} from 'rxjs'
import { InternalClientActionType } from '../actions/internal/types'
import {
  flatMap,
  catchError
} from 'rxjs/operators'
import { waitForData } from './util'
import { platformsQueryBuilder } from './util/queries'

export function platformsEpic(
  actions$: ActionsObservable<PlatformsInternalAction>
): Observable<PlatformsCompleteInternalAction |
  PlatformsErrorInternalAction> {
    return actions$.pipe(
      ofType(InternalClientActionType.PLATFORMS),
      flatMap(action$ => {
        const { args } = action$
        return from(
          waitForData(
            args,
            platformsQueryBuilder,
            platformsCompleteInternal,
            platformsErrorInternal
          )
        ).pipe(
          catchError(err => of(err))
        )
      }),
      catchError(err => of(err))
    )
  }