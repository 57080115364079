import React, { ReactNode } from 'react'
import './Card.scss'

interface Props {
  header?: ReactNode
  section?: ReactNode
  className?: string
}

const Card: React.SFC<Props> = ({
  className = '',
  header,
  section
}) =>
  <div className={`card ${className} card-overrides`}>
    {
      header
        ? <div className='card-divider card-overrides'>{header}</div>
        : null
    }
    {
      section
        ? <div className='card-section'>{section}</div>
        : null
    }
  </div>

export default Card