import Keycloak, {
  KeycloakInstance,
  KeycloakInitOptions,
  KeycloakError,
  KeycloakConfig
} from 'keycloak-js'

import { getKeycloakConfig } from './getKeycloakConfig'
import {
  KeycloakUserInfoInstance,
  KeycloakUserInfo,
  KeycloakInfo
} from '../models/KeycloakUserInfo'
import { flow } from 'lodash'

let keycloakInstance: KeycloakInstance = undefined

export const defaultInitOptions: KeycloakInitOptions = {
  onLoad: 'login-required'
}

export const extractKeycloakInfo = (
  instance: KeycloakInstance
) => (
  userInfo: KeycloakUserInfo = {}
): KeycloakInfo => {
  const {
    authenticated,
    realmAccess,
    token,
    tokenParsed: {
      exp,
      resource_access
    }
  } = instance
  return {
    ...userInfo,
    token,
    authenticated,
    exp,
    realmAccess,
    resourceAccess: resource_access
  }
}

export const loadUserInfo = (
  instance: KeycloakInstance = keycloakInstance
) => new Promise<KeycloakUserInfo>((
  resolve,
  reject) => {
    if (instance) {
      instance.loadUserInfo().success((info: object) => {
        const userInfo = KeycloakUserInfoInstance.parseJson(info)
        resolve(userInfo)
      }).error(err => {
        console.error(err)
        resolve()
      })
    } else {
      reject('Keycloak not running')
    }
  }
)

export const resolveWithKeycloakInfo = (
  resolve: (value: KeycloakInfo) => void
) => () => {
  const extract = flow([
    extractKeycloakInfo(keycloakInstance),
    resolve])
  loadUserInfo().then(extract).catch(err => extract())
}

export const rejectWithError = (
  reject: (reason?: any) => void
) => (reason: KeycloakError) => reject(reason)

export const refreshToken = (): Promise<KeycloakInfo> => new Promise((
  resolve,
  reject
) => {
  const rej = () => reject('Could not refresh token')
  const {
    timeSkew,
    refreshTokenParsed: {
      exp
    }
  } = keycloakInstance

  const extract = extractKeycloakInfo(keycloakInstance)

  // If the token is due to expire before the next refresh then update
  if (keycloakInstance && new Date().getTime() / 1000 > exp - 60) {
    keycloakInstance.updateToken(timeSkew).success((refreshed: boolean) => {
      resolve(extract())
    }).error(rej)
  } else {
    resolve(extract())
  }
})

export const initKeycloak = (
  redirectUri: string,
  configFile: KeycloakConfig = getKeycloakConfig(),
  initOptions: KeycloakInitOptions = defaultInitOptions
): Promise<KeycloakInfo> => new Promise((
  resolve, reject
) => {
    const authenticated = resolveWithKeycloakInfo(resolve)
    const error = rejectWithError(reject)
    if (keycloakInstance && keycloakInstance.authenticated) {
      authenticated()
    } else {
      keycloakInstance = Keycloak(configFile)
      keycloakInstance.init({
        ...initOptions,
        redirectUri: window.location.href + '?redirectUri=' + redirectUri
      }).success((auth: boolean) => {
        auth ? authenticated() : error({
          error: 'Auth failed',
          error_description: 'AUTH_FAIL'
        })
    }).error(error)
  }
})

export const logout = (): Promise<boolean> => new Promise((
  resolve,
  reject
) => {
  const loggedOut = () => resolve(true)
  if (!keycloakInstance) {
    loggedOut()
  } else {
    keycloakInstance.logout().success(() => {
      loggedOut()
    }).error(reject)
  }
})