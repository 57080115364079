import React from 'react'
import { Draggable, DragDropContext, Droppable } from 'react-beautiful-dnd'
import { Cell, Grid } from 'react-foundation'
import { Trans } from 'react-i18next'
import { connect, Dispatch } from 'react-redux'
import { sortBy } from 'lodash'

import './EventDetailsTable.scss'
import { IExternalRace } from '../../models/Directory'
import { OnDragEndArgs, onDragEnd } from '../../util/onDragEnd'
import { App } from '../../models/App'
import { shortDate } from '../../util/shortDate'
import { formattedDistance } from '../../util/formattedDistance'
import { getItemStyle, getListStyle } from '../shared/dragStyles'
import { getPlatformIcon } from '../../util/getPlatformIcon'
import { State } from '../../models'
import { linkEntries } from '../../actions/external/linkEntries'
import { LinkIcon } from './Icons'

interface RProps {
  app: App,
  dispatch: Dispatch
}

interface IProps extends RProps {
  races: IExternalRace[]
}

const RacesTable: React.SFC<IProps> = ({
  app,
  dispatch,
  races = []
}) =>
  <div className='details-table'>
    <h5 className='details-title'>
      <Trans>{'Races'}</Trans>
    </h5>
    <Grid vertical>
      {
        // header
      }
      <Grid className={`grid-x details-table-header`}>
        <Cell small={1}>
          <Trans>Partner</Trans>
        </Cell>
        <Cell small={2}>
          <Trans>ID</Trans>
        </Cell>
        <Cell className='auto'>
          <Trans>Race</Trans>
        </Cell>
        <Cell small={1}>
          <Trans>Date</Trans>
        </Cell>
        <Cell small={2}>
          <Trans>Distance</Trans>
        </Cell>
      </Grid>
      <DragDropContext onDragEnd={(e: OnDragEndArgs) => {
        if (!e.draggableId
          || !e.destination || !e.destination.droppableId
          || !e.source || !e.source.droppableId
        ) {
          return
        }

        const destination = races.find(x => x.id === e.destination.droppableId)
        console.log('destination:', destination)
        const link = races.map(x => x.links.find(y => y.id === e.draggableId)).filter(_ => !!_)[0]
        console.log('link:', link)
        if (!destination || !link || destination.links.map(x => x.platform.id).includes(link.platform.id)) return
        const entryId = destination.id
        const linkParentId = (races.find(x => x.id === e.source.droppableId) || {}).id
        onDragEnd(e, {
          dispatch,
          entryId,
          linkId: e.draggableId,
          linkParentId,
          token: app.token
        })
      }}>
        {
          // data rows
          races.length
            ? sortBy(races, ['distance.distanceInMeters']).map(race =>
              <Grid className='grid-x details-table-row' key={race.id}>
                <Cell className='auto'>
                  <Droppable droppableId={race.id}
                    style={{
                      flex: 1
                    }}>
                    {(droppableProvided, droppableSnapshot) => (
                      <div
                        ref={droppableProvided.innerRef}
                        style={{
                          ...getListStyle(droppableSnapshot.isDraggingOver),
                          flex: 1
                        }}
                        {...droppableProvided.droppableProps}
                      >
                        {race.links.map((link, index) =>
                          <Grid className='grid-x details-table-row' key={index}>
                            {(index === 0 || race.links.length <= 1) ? null : LinkIcon}
                            <Cell small={1}>
                              <Draggable draggableId={link.id} key={link.id} index={index}>
                                {(draggableProvided, draggableSnapshot) => (
                                  <div
                                    ref={draggableProvided.innerRef}
                                    {...draggableProvided.draggableProps}
                                    {...draggableProvided.dragHandleProps}
                                    style={{
                                      ...getItemStyle(
                                        draggableSnapshot.isDragging,
                                        draggableProvided.draggableProps.style
                                      ),
                                      display: 'inline'
                                    }}
                                  >
                                    {getPlatformIcon(link.platform)}
                                  </div>
                                )}
                              </Draggable>
                              {droppableProvided.placeholder}
                            </Cell>
                            <Cell small={2}>
                              {link.platformOwnedIdentifier}
                            </Cell>
                            <Cell className='auto'>
                              {race.name}
                            </Cell>
                            <Cell small={1}>
                              {shortDate(race.date)}
                            </Cell>
                            <Cell small={2}>
                              {formattedDistance(race.distance)}
                            </Cell>
                            {(index === 0 || race.links.length <= 1)
                              ? null
                              : LinkIcon(() => {
                                if (window.confirm('are you sure you want to unlink these races?'))
                                  dispatch(
                                    linkEntries({
                                      linkId: link.id,
                                      isUnlink: true,
                                      token: app.token
                                    })
                                  )
                              })}
                          </Grid>
                        )}
                      </div>
                    )}
                  </Droppable>
                </Cell>
              </Grid>
            )
            : null
        }
      </DragDropContext>
    </Grid>
  </div>

export default connect((state: State) => ({
  app: state.app,
  dispatch: undefined
}))(RacesTable)