import {
  applyMiddleware,
  createStore,
  Store,
  Action
} from 'redux'
import { createEpicMiddleware } from 'redux-observable'

import { configureEventsClient, eventsMiddleWare } from '@athlinks/admin-events-client'
import { directoryMiddleWare } from '../reducers/external/directoryMiddleWare'
import { combinedEpics } from '../epics/index'
import { combinedReducers } from '../reducers/index'
import { getAppConfig } from '../util/config'
import errorsHandleMiddleware from '../util/errorsHandleMiddleware'
import { configureDirectoryClient } from '../config'
const epicMiddleware = createEpicMiddleware()

let store: Store = undefined

configureEventsClient(getAppConfig().eventsGraphUrl)
configureDirectoryClient(getAppConfig().directoryGraphUrl)
/**
 * This is a utility function only for use in special cases,
 * like keycloak.  DO NOT USE THIS for 99.99% of async ops.
 *
 * It is an anti-pattern
 *
 * @param action Any action
 */
export function dispatchOnStore(action: Action) {
  if (store) {
    store.dispatch(action)
  } else {
    console.error(
      'STORE NOT CONFIGURED, COULD NOT DISPATCH ACTION: ',
      action.type
    )
  }
}

export function configureStore() {

  store = createStore(
    combinedReducers,
    applyMiddleware(
      epicMiddleware,
      eventsMiddleWare,
      directoryMiddleWare,
      errorsHandleMiddleware()
    )
  )

  epicMiddleware.run(combinedEpics)

  return store
}