import { ActionsObservable, ofType } from 'redux-observable'
import {
  Observable,
  from,
  of
} from 'rxjs'
import {
  flatMap,
  catchError
} from 'rxjs/operators'

import {
  RefreshEventInternalAction,
  RefreshEventCompleteInternalAction,
  RefreshEventErrorInternalAction,
  refreshEventCompleteInternal,
  refreshEventErrorInternal
} from '../actions/internal/refreshEvent'
import { InternalClientActionType } from '../actions/internal/types'
import { waitForMutationResponse } from './util'
import { refreshEventMutationBuilder } from './util/mutations'

export function eventRefreshEpic(
  actions$: ActionsObservable<RefreshEventInternalAction>
): Observable<RefreshEventCompleteInternalAction |
  RefreshEventErrorInternalAction> {
    return actions$.pipe(
      ofType(InternalClientActionType.REFRESH_EVENT),
      flatMap(action$ => {
        const { args } = action$

        return from(
          waitForMutationResponse(
            args,
            refreshEventMutationBuilder,
            refreshEventCompleteInternal,
            refreshEventErrorInternal
          )
        ).pipe(
          catchError(err => of(err))
        )
      }),
      catchError(err => of(err))
    )
  }