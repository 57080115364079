import { IPlatform } from '../models/Platforms'
import { AthlinksIcon, CtLiveIcon, RunSignUpIcon, XIcon } from '../components/directory/Icons'

export const getPlatformIcon = (platform: IPlatform) => {
  if (!platform) {
    return XIcon
  }
  switch (platform.name) {
    case 'Athlinks':
      return AthlinksIcon
    case 'CTLive':
      return CtLiveIcon
    case 'RunSignUp':
      return RunSignUpIcon
    default:
      return XIcon
  }
}