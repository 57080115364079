import React from 'react'
import { Trans } from 'react-i18next'
import Card from '../../components/card/Card'
import AccessLock from '../../shared/images/access_lock.svg'
import './Error.scss'

interface Props {
  statusCode: number,
  text: string,
  subText: string
}

const statusImages = {
  403: AccessLock
}

const HttpError: React.SFC<Props> = ({ statusCode, text, subText }) => (
  <Card
    className='error-card'
    section={
      <div className='error-content'>
        <img alt='error' className='error-image' src={statusImages[statusCode]} />
        <div className='error-text'>
          <Trans>{text}</Trans>
        </div>
        <div className='error-subtext'>
          <Trans>{subText}</Trans>
        </div>
      </div>
    }
  />
)

export default HttpError