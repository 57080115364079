import { Distance } from '@athlinks/eventmetadata-types'

const distanceUnitMap = {
  'feet': 'ft',
  'kilometers': 'km',
  'hectameters': '100m',
  'hectayards': '100yd',
  'meters': 'm',
  'yards': 'yd'
}

export const formatDistance = (distance: Distance) => {
  const { distanceInMeters, distanceUnit } = distance
  const convertedUnit = distanceUnitMap[distanceUnit] || distance

  return {
    distanceInMeters: +distanceInMeters,
    distanceUnit: convertedUnit
  }
}