import {
  createStore,
  applyMiddleware,
  combineReducers,
  compose as composeEnhancers,
  Middleware,
  MiddlewareAPI,
  Dispatch,
  Action,
  Store
} from 'redux'
import {
  createEpicMiddleware
} from 'redux-observable'
import thunk from 'redux-thunk'

import { Log } from '../../logger'
import {
  externalToInternalTypeMap,
  internalToExternalTypeMap
} from './actionMapper'
import rootEpic from '../../epics/directory'
import { createDatumReducer } from './createDatumReducer'
import { eventSearchReducer } from './eventSearchReducer'
import { linkEntriesReducer } from './linkEventsReducer'
import { deleteDatumReducer } from './deleteDatumReducer'
import { refreshEventReducer } from './refreshEventReducer'
import { platformsReducer } from './platformsReducer'
import { IDataState } from '../../models/state'

export interface DirectoryClientState {
  createDatumResult: IDataState<any>,
  eventSearchResult: IDataState<any>,
  createMasterDataResult: IDataState<any>,
  deleteDatumResult: IDataState<any>,
  platformsResult: IDataState<any>,
  linkEntriesReducer: IDataState<any>,
  refreshEventReducer: IDataState<any>
}

const rootReducer = combineReducers({
  createDatumResult: createDatumReducer,
  eventSearchResult: eventSearchReducer,
  deleteDatumResult: deleteDatumReducer,
  platformsResult: platformsReducer,
  linkEntriesReducer: linkEntriesReducer,
  refreshEventReducer: refreshEventReducer
})

let internalStore: DirectoryClientState & Store = undefined,
  externalStore: Store = undefined

const configureStores = (
  apiStore: Store
) => {
  if (!internalStore) {
    internalStore = store as DirectoryClientState & Store
    Log.debug('SETTING INTERNAL STORE TO: ', internalStore)
  }
  if (!externalStore) {
    externalStore = apiStore
    Log.debug('SETTING EXTERNAL STORE TO: ', externalStore)
  }
}

export const directoryMiddleWare: Middleware =
  (api: MiddlewareAPI<any>) =>
  (next: Dispatch<Action>) =>
  (action: Action) => {
    configureStores(api as Store)
    const externalActionType = (action as { type: string }).type
    const newInternalAction = externalToInternalTypeMap[externalActionType]
    Log.debug('EXTERNAL ACTION TYPE IS: ', externalActionType)
    if (newInternalAction) {
      internalStore.dispatch(newInternalAction(action))
    }
    next(action)
  }

export const internalMiddleWare: Middleware =
  (api: MiddlewareAPI<any>) =>
  (next: Dispatch<Action>) =>
  (action: Action) => {
    const internalActionType = (action as { type: string }).type
    const newExternalAction = internalToExternalTypeMap[internalActionType]
    Log.debug('INTERNAL ACTION TYPE IS: ', internalActionType)
    /**
     * In the event of integration tests, the middleware will not have
     * an external store
     */
    if (newExternalAction && externalStore) {
      externalStore.dispatch(newExternalAction(action))
    }
    next(action)
}

const epicMiddleWare = createEpicMiddleware()

const enhancer = composeEnhancers(
  applyMiddleware(thunk, epicMiddleWare, internalMiddleWare)
)

const configureStore =  (initialState: Object | void) => {
  const $store = createStore(rootReducer, enhancer)
  epicMiddleWare.run(rootEpic)
  return $store
}

const store = configureStore()

export default store