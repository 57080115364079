export interface ModalAction {
  label: string
  dispatchHandler: Function
}

export interface ModalData<T> {
  type: string
  args: T
  action?: ModalAction
}

export enum ModalTypes {
  DIRECTORY_ADD_EVENT = 'DIRECTORY_ADD_EVENT',
  DIRECTORY_ADD_MASTER_EVENT = 'DIRECTORY_ADD_MASTER_EVENT'
}