import { Transerializable } from '@athlinks/get-or'
import {
  IDirectoryDataMutationResult
} from './Directory'

export interface IDirectoryCreateDatumState {
  errorMessage: string,
  isLoading: boolean,
  result: IDirectoryDataMutationResult
}

export class DirectoryCreateDatumState extends Transerializable
  implements IDirectoryCreateDatumState {
  errorMessage: string = ''
  isLoading: boolean = false
  result: IDirectoryDataMutationResult = undefined
}