import { combineEpics } from 'redux-observable'
import { createDatumEpic } from '../createDatumEpic'
import { eventSearchEpic } from '../eventSearchEpic'
import { deleteDatumEpic } from '../deleteDatumEpic'
import { platformsEpic } from '../platformsEpic'
import { eventRefreshEpic } from '../eventRefreshEpic'
import { linkEntriesEpic } from '../linkEntriesEpic'

const rootEpic = combineEpics<any, any>(
  createDatumEpic,
  eventSearchEpic,
  deleteDatumEpic,
  platformsEpic,
  linkEntriesEpic,
  eventRefreshEpic
)

export default rootEpic