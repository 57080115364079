import { concat, size } from 'lodash'

// function for finding the page numbers to display on
// either side of the current page
export function findLeftRightPages(
  leftEllipsis: boolean, rightEllipsis: boolean,
  currentPage: number, numPages: number, delta: number,
  acc: { left: Array<number>, right: Array<number> }) {
  const left = acc.left
  const right = acc.right
  const leftPage: number = currentPage - delta
  const rightPage: number = currentPage + delta
  const sizeModLeft = !rightEllipsis || currentPage === 0 ? 0 : 1
  const sizeModRight = !leftEllipsis || currentPage === numPages - 1 ? 0 : 1

  // we've filled up what we need to return
  if ( ((size(left) + sizeModLeft) + (size(right) + sizeModRight) > 6) ||
    (leftPage <= 0 && rightPage >= numPages)) {
    return acc
  }

  const leftArr = leftPage > 0 ? [leftPage] : []
  const rightArr = rightPage < (numPages - 1) &&
    rightPage > currentPage ? [rightPage] : []
  const updatedAcc = {
    left: concat(left, leftArr).sort((a, b) => a - b),
    right: concat(right, rightArr)
  }

  return findLeftRightPages(leftEllipsis, rightEllipsis,
    currentPage, numPages, delta + 1, updatedAcc)
}