import { Transerializable } from '@athlinks/get-or'
import { IPlatform } from './Platforms'

export interface LinkingPrefs {
  platform1: IPlatform,
  platform2: IPlatform
}

export interface IDirectoryUserPrefsState {
  linking: LinkingPrefs
}

export class DirectoryUserPrefsState extends Transerializable
  implements IDirectoryUserPrefsState {
  linking: LinkingPrefs = {} as LinkingPrefs
}