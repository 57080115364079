import { mergeMap } from 'rxjs/operators'
import { Observable } from 'rxjs'
import { ofType } from 'redux-observable'

import {
  AuthAction,
  AuthActionComplete,
  authComplete,
  authError,
  AuthActionError
} from '../../actions/auth'
import { AuthActionTypes } from '../../actions/types'
import { initKeycloak } from '../../keycloak/KeycloakService'

export function authenticate(
  actions$: Observable<AuthAction>
): Observable<AuthActionComplete | AuthActionError> {
  return actions$.pipe(
    ofType(AuthActionTypes.AUTH),
    mergeMap(async (action$: AuthAction) => {
      try {
        const profile = await initKeycloak(action$.redirectUri)
        return authComplete(profile)
      } catch (error) {
        return authError(error)
      }
    })
  )
}