import { combineEpics } from 'redux-observable'
import { authenticate } from './auth/auth'
import { unauthenticate } from './auth/unauth'
import {
  startRefreshToken,
  refreshTokenEpic
} from './auth/refreshToken'
import { findCreatedEvent } from './event/findCreatedEvent'
import { refreshUpdatedEvent } from './event/refreshUpdatedEvent'
import { compareRegEpic } from './compareRegEpic'

export const combinedEpics = combineEpics(
  authenticate,
  unauthenticate,
  startRefreshToken,
  refreshTokenEpic,
  findCreatedEvent,
  refreshUpdatedEvent,
  compareRegEpic
)