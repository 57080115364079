import {
    ExternalAction,
    ExternalErrorAction,
    QueryArgs
  } from '../../actions'
  import { DirectoryLinkingActionTypes } from './types'
  import { IExternalActionCreator } from '../../reducers/external/actionMapper'
  import {
    LinkEntriesCompleteInternalAction,
    LinkEntriesErrorInternalAction
  } from '../internal/linkEntries'

  export interface LinkEntriesArgs extends QueryArgs {
    entryId?: string,
    linkId: string,
    linkParentId?: string,
    isUnlink: boolean
  }

  export interface LinkEntriesAction
    extends ExternalAction<LinkEntriesArgs> {
    type: DirectoryLinkingActionTypes.DIRECTORY_ENTRIES_LINK
  }

  export const linkEntries =
    (args: LinkEntriesArgs): LinkEntriesAction => ({
      args,
      type: DirectoryLinkingActionTypes.DIRECTORY_ENTRIES_LINK
    })

  export interface LinkEntriesCompleteAction
    extends ExternalAction<LinkEntriesArgs> {
      type: DirectoryLinkingActionTypes.DIRECTORY_ENTRIES_LINK_COMPLETE,
      linkEntriesResult: any // TODO Pending
  }

  export const linkEntriesComplete:
    IExternalActionCreator<LinkEntriesCompleteAction,
    LinkEntriesCompleteInternalAction> = (
      action: LinkEntriesCompleteInternalAction
    ): LinkEntriesCompleteAction => ({
      ...action,
      type: DirectoryLinkingActionTypes.DIRECTORY_ENTRIES_LINK_COMPLETE
    })

  export interface LinkEntriesErrorAction
    extends ExternalErrorAction<LinkEntriesArgs> {
    type: DirectoryLinkingActionTypes.DIRECTORY_ENTRIES_LINK_ERROR
  }

  export const linkEntriesError:
    IExternalActionCreator<LinkEntriesErrorAction,
    LinkEntriesErrorInternalAction> = (
      action: LinkEntriesErrorInternalAction
    ): LinkEntriesErrorAction => ({
      ...action,
      type: DirectoryLinkingActionTypes.DIRECTORY_ENTRIES_LINK_ERROR
    })