import React, { Component } from 'react'
import { Cell, Grid } from 'react-foundation'
import { connect, Dispatch } from 'react-redux'
import { groupBy, keys, flatMap } from 'lodash'
import './ResultsPage.module.scss'
import { App } from '../../models/App'
import { HistoryProps } from '../../models/HistoryProps'
import { State } from '../../models'
import { Log } from '../../logger'
import { getAppConfig } from '../../util/config'
import Header from '../../components/header/Header'
import { Entry, Result } from '@athlinks/eventmetadata-types'
import { convertTime } from '@athlinks/lib-units'

interface IProps {
  app: App
  dispatch: Dispatch
  eventId?: string
}

interface RaceManifest {
  rbis: {
    count: number
    divisionName: string
    intervalName: string
    pages: number
    raceBracketIntervalId: string
  }[]
}

interface RbiResult {
  entry: Entry
  entryId: string
  result: Result
}

interface IState {
  blob?: any
  event: {
    name: string,
    races: {
      id: number,
      name: string
    }[]
  },
  manifests: {
    [eventCourseId: number]: RaceManifest
  },
  rbis: {
    [rbi: string]: {
      selectedPage: number,
      [page: number]: RbiResult[]
    }
  }
}

const config = getAppConfig()

class ResultsComponent extends Component<IProps & HistoryProps, IState> {

  socket: WebSocket = undefined

  state: IState = {
    event: {
      name: '???',
      races: []
    },
    manifests: {},
    rbis: {}
  }

  componentWillUnmount() {
    try {
      this.socket.close()
    }
    catch (err) {
      Log.error('websocket.close.error:', err)
    }
  }

  componentDidMount() {
    const {
      eventId
    } = this.props

    this.socket = new WebSocket(config.athleteApiWsBase)
    this.socket.onclose = (ev: CloseEvent) => {
      Log.info('websocket.onclose:', ev)
    }
    this.socket.onerror = (err) => {
      Log.error('websocket.onerror:', err)
    }
    this.socket.onmessage = async (message: any) => {
      Log.info('websocket.onmessage:', message)
      const data = JSON.parse(message.data)
      Log.info('websocket.onmessage.data:', data)
      if (data.eventId) {
        const event = data.event
        for (const race of event.races) {
          this.socket.send(JSON.stringify({ raceId: race.id.toString() }))
        }
        this.setState({
          event
        })
      }
      else if (data.raceId) {
        const manifests = this.state.manifests
        manifests[data.raceId] = data.manifest
        this.setState({
          manifests
        })
      }
      else if (data.raceBracketIntervalId) {
        const raceBracketIntervalId: string = data.raceBracketIntervalId
        const page = +data.page
        console.log('raceBracketIntervalId:', raceBracketIntervalId, 'page:', page)
        const rbis = this.state.rbis
        if (!rbis[raceBracketIntervalId]) {
          rbis[raceBracketIntervalId] = { selectedPage: page }
        }
        rbis[data.raceBracketIntervalId][+page] = data.results as RbiResult[]

        this.setState({
          rbis: rbis
        })
      }
      else this.setState({
        blob: JSON.parse(message.data)
      })
    }
    this.socket.onopen = () => {
      this.socket.send(JSON.stringify({
        // entryId: "5f15445b-48cc-4262-90b6-39cf69821878",
        // raceId: eventCourseId
        eventId
        // raceBracketIntervalIds: ["137939-111-1123", "137939-112-1123"],
      }))
    }
  }

  render() {
    const {
      event,
      manifests,
      rbis
    } = this.state
    const eventCourseIds = keys(manifests)
    return (
      <div className='fullscreen'>
        <Grid>
          <Cell>
            <Header title={'LIVE RESULTS'} subTitle={'PartnerSync'} />
          </Cell>
          <Cell className='results-page'>
            <h1>Event: {event.name}</h1>
            {eventCourseIds.map(eventCourseId => {
              const manifest = manifests[eventCourseId]
              if (!manifest) {
                return null
              }
              const intervals = groupBy(manifest.rbis, x => x.intervalName)
              return (
                <div className='rbis-container' key={eventCourseId}>
                  <h2>{event.races.find(x => x.id === +eventCourseId).name} RBI's</h2>
                  {!keys(intervals).length
                    ? null
                    :
                    <div>
                      <input
                        id='selectAll'
                        type='checkbox'
                        onChange={e => {
                          console.log('e:', e.target.checked)
                          if (e.target.checked) {
                            manifest.rbis.forEach(rbi => {
                              this.socket.send(JSON.stringify({
                                rbiPages: [rbi.raceBracketIntervalId + '-' + 0]
                              }))
                              rbis[rbi.raceBracketIntervalId] = { selectedPage: 0, 0: [] }
                            })
                          }
                          else {
                            manifest.rbis.forEach(rbi => {
                              // TODO: unsubscribe from ws subscription?
                              delete rbis[rbi.raceBracketIntervalId]
                            })
                          }
                          this.setState({
                            rbis: rbis
                          })
                        }} />
                      <label htmlFor='selectAll'>Select All</label>
                    </div>
                  }
                  {keys(intervals).map((intervalName, intervalKey) => {
                    const divisions = intervals[intervalName]
                    return (
                      <div key={intervalKey} className='interval-divisions'>
                        <h2>{intervalName}</h2>
                        {divisions.map((division, divisionKey) => (
                          <div key={divisionKey}>
                            <input
                              checked={!!rbis[division.raceBracketIntervalId]}
                              id={division.raceBracketIntervalId}
                              type='checkbox'
                              onChange={e => {
                                console.log('e:', e.target.value, e.target.checked)
                                if (e.target.checked) {
                                  this.socket.send(JSON.stringify({
                                    rbiPages: [e.target.value + '-' + 0]
                                  }))
                                  rbis[e.target.value] = { selectedPage: 0, 0: [] }
                                }
                                else delete rbis[e.target.value]

                                this.setState({
                                  rbis: rbis
                                })
                              }} value={division.raceBracketIntervalId} />
                            <label htmlFor={division.raceBracketIntervalId}>{division.divisionName}</label>
                          </div>
                        ))}
                      </div>
                    )
                  })}
                </div>
              )
            })}

            <div className='results-container'>
              <h2>Results</h2>
              {Object.keys(rbis).map((rbi, rbiKey) => {
                const rbi2 = flatMap(eventCourseIds.filter(x => manifests[x]), x => manifests[x].rbis)
                  .find(x => x.raceBracketIntervalId === rbi) || {
                  count: -1,
                  intervalName: rbi.split('-')[2],
                  divisionName: rbi.split('-')[1],
                  pages: 0,
                  raceBracketIntervalId: rbi
                }
                console.log('rbi2:', rbi2)
                return (
                  <div key={rbiKey} className='rbi-container'>
                    <h3>{`${rbi2.intervalName} - ${rbi2.divisionName} (${rbi2.count})`}</h3>
                    <div className='rbi-results'>
                      {rbis[rbi][
                        rbis[rbi].selectedPage
                      ].map((result, resultKey) => {
                        const intervalFull = result.result?.intervalResults.find(x => x.isFull)
                        const ranking = result.result.intervalResults.map(x =>
                          x.divisionRankings.find(y =>
                            `${result.result.scoringProviderRaceId}-${y.scoringProviderDivisionId}-${x.scoringProviderIntervalId}` === rbi
                          )
                        ).filter(_ => !!_)[0]
                        return (
                          <div key={resultKey} className='result-container' style={{ cursor: 'pointer' }}
                            onClick={() =>
                              window.open(config.athlinksBase + '/entry/' + result.entryId)
                            }>
                            name: {result.entry?.athlete?.firstName}&nbsp;{result.entry?.athlete.lastName}<br />
                            time: {convertTime({
                                  timeInMillis: intervalFull.timeElapsedInMilliseconds,
                                  timeUnit: 'h' as any
                                }).value}<br />
                            place: {ranking?.place}<br />
                            bib: {result.entry?.bib}<br />
                            <hr />
                          </div>
                        )
                      })}
                    </div>
                    <div className='paging'>
                      {Array.from(Array(rbi2.pages).keys()).map(page => (
                        <input
                          type='button'
                          key={page}
                          value={`${page}`}
                          onClick={() => {
                            const rbiPage = rbis[rbi]
                            console.log('rbi:', rbi)
                            console.log('page:', page)
                            console.log('rbiPage:', rbiPage)
                            this.setState({
                              rbis: {
                                ...rbis,
                                [rbi]: {
                                  [page]: [],
                                  ...rbiPage,
                                  selectedPage: page
                                }
                              }
                            })
                            const selectedPageResults = rbiPage[page]
                            console.log('selectedPageResults:', selectedPageResults)
                            if (!selectedPageResults || !selectedPageResults.length) {
                              this.socket.send(JSON.stringify({
                                rbiPages: [rbi + '-' + page]
                              }))
                            }
                          }} />
                      ))}
                    </div>
                  </div>
                )
              })}
            </div>
          </Cell>
        </Grid>
      </div>
    )
  }
}

export default connect(
  (state: State): IProps => ({
    app: state.app,
    dispatch: undefined,
    eventId: new URLSearchParams(window.location.search).get('eventId')
  })
)(ResultsComponent)