import { Transerializable } from '@athlinks/get-or'
import { KeycloakResourceAccess, KeycloakRoles } from 'keycloak-js'

export interface KeycloakUserInfo {
  city?: string
  dateOfBirth?: string
  email?: string
  emailVerified?: string
  familyName?: string
  givenName?: string
  name?: string
  preferredUsername?: string
  sub?: string
}

export class KeycloakUserInfoInstance extends
  Transerializable implements KeycloakUserInfo {
  city: string = ''
  dateOfBirth: string = ''
  email: string = ''
  emailVerified: string = ''
  familyName: string = ''
  givenName: string = ''
  name: string = ''
  preferredUsername: string = ''
  sub: string = ''
}

export interface KeycloakInfo extends KeycloakUserInfo {
  token: string
  authenticated: boolean
  exp: number
  realmAccess: KeycloakRoles
  resourceAccess: KeycloakResourceAccess
}

export const Roles = {
  EVENT_MANAGEMENT: 'athlinks.events.management'
}
