import React, { Component } from 'react'
import ArrowBack from '../../shared/images/ArrowBack'
import ArrowForward from '../../shared/images/ArrowForward'
import { ceil } from 'lodash'
import { findLeftRightPages } from '../../util/PagerUtil'

import styles from './Pager.module.scss'

interface IProps {
  currentPage: number,
  perPage: number,
  total: number,
  onClickHandler: (id: number) => void
}

export default class Pager extends Component<IProps> {

  getLeftRightPages = (numPages: number,
    leftEllipsis: boolean, rightEllipsis: boolean) => {
    const {
      currentPage
    } = this.props

    if (leftEllipsis && rightEllipsis) {
      return {
        left: [currentPage - 3, currentPage - 2, currentPage - 1],
        right: [currentPage + 1, currentPage + 2, currentPage + 3]
      }
    }

    return findLeftRightPages(leftEllipsis, rightEllipsis,
      currentPage, numPages, 1, { left: [], right: [] })
  }

  renderButton(disabled: boolean, onClick: any, text: any, key: string) {
    // TODO: remove inline styling below
    return (
      <div key={key} style={{display: 'inline-block', marginRight: 8}}>
        <button disabled={disabled} onClick={onClick} value={text}>
         {text}
        </button>
      </div>
    )
  }

  renderDynamicButtons(numPages: number,
    previousDisabled: boolean, nextDisabled: boolean) {
    const {
      currentPage,
      onClickHandler
    } = this.props
    const showLeftEllipsis = numPages > 9 && currentPage > 4
    const showRightEllipsis = numPages > 9 && ((numPages - currentPage) > 5)
    const arrs = this.getLeftRightPages(numPages,
      showLeftEllipsis, showRightEllipsis)

    // TODO: remove inline styling below
    return (
      <div style={{display: 'inline-block'}}>
        {
          showLeftEllipsis &&
          this.renderButton(true, null, '...', 'page-ellipsis-left')
        }
        {
          arrs.left.map((i: number) =>
            this.renderButton(
              false,
              () => onClickHandler(i),
              `${i + 1}`,
              `page-button-${i}`
            )
           )
        }
        {
          !previousDisabled && !nextDisabled &&
          this.renderButton(
            true,
            null,
            `${currentPage + 1}`,
            'page-button-current'
          )
        }
        {
          arrs.right.map((i: number) =>
            this.renderButton(
              false,
              () => onClickHandler(i),
              `${i + 1}`,
              `page-button-${i}`
            )
          )
        }
        {
          showRightEllipsis &&
          this.renderButton(true, null, '...', 'page-ellipsis-right')
        }
      </div>
    )
  }

  // TODO: remove this once the Button component is used
  getArrowProps = (disabled: boolean) => {
    return {
      size: 12,
      color: `rgba(0,0,0,${disabled ? 0.247 : 1})`
    }
  }

  renderButtons() {
    const {
      currentPage,
      onClickHandler,
      perPage,
      total
    } = this.props

    const numPages: number = ceil(total / perPage)
    const previousDisabled = currentPage === 0
    const nextDisabled = currentPage === (numPages - 1) || numPages <= 1
    const leftArrow = <ArrowBack {...this.getArrowProps(previousDisabled)} />
    const rightArrow = <ArrowForward {...this.getArrowProps(nextDisabled)} />

    // TODO: remove inline styling below
    return (
      <div style={{display: 'inline-block'}}>
        {/* LEFT */}
        {
          this.renderButton(
            previousDisabled,
            () => onClickHandler(currentPage - 1),
            leftArrow,
            'page-button-previous'
          )
        }
        {
          this.renderButton(
            previousDisabled,
            () => onClickHandler(0),
            '1',
            'page-button-1'
          )
        }
        {/* MIDDLE */}
        {
          this.renderDynamicButtons(numPages, previousDisabled, nextDisabled)
        }
        {/* RIGHT */}
        {
          numPages > 1 &&
          this.renderButton(
            nextDisabled,
            () => onClickHandler(numPages - 1),
            `${numPages}`,
            `page-button-${numPages}`
          )
        }
        {
          this.renderButton(
            nextDisabled,
            () => onClickHandler(currentPage + 1),
            rightArrow,
            'page-button-next'
          )
        }
      </div>
    )
  }

  render() {
    return (
      <div className={styles.container}>
        {this.renderButtons()}
      </div>
    )
  }
}
