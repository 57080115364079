import {
  EventSearchState,
  IEventSearchState
} from '../models/EventSearchState'
import {
  EventSearchAction,
  EventSearchCompleteAction,
  EventSearchErrorAction
} from '../actions/events'
import { EventActionTypes as Action } from '@athlinks/admin-events-client'

type ActionTypes = EventSearchAction |
  EventSearchCompleteAction |
  EventSearchErrorAction

export const eventSearchReducer =
  (state: IEventSearchState = new EventSearchState(),
   action: ActionTypes): IEventSearchState => {
  switch (action.type) {
    case Action.EVENT_SEARCH:
      return EventSearchState.build({
        errorMessage: '',
        isLoading: true,
        result: undefined
      })
    case Action.EVENT_SEARCH_COMPLETE:
      return EventSearchState.build({
        errorMessage: '',
        isLoading: false,
        result: action.eventSearchResult.eventSearch
      })
    case Action.EVENT_SEARCH_ERROR:
      return EventSearchState.build({
        ...state,
        errorMessage: action.error.message || 'the event search operation was unsuccessful',
        isLoading: false
      })
    default:
      return state
  }
}
