import {
  ICompareRegState,
  CompareRegState
} from '../models/CompareRegState'
import {
  CompareRegAction, CompareRegCompleteAction,
  CompareRegErrorAction
} from '../actions/external/compareReg'
import {
  DirectoryCompareActionTypes
} from '../actions/external/types'

type ActionTypes = CompareRegAction |
  CompareRegCompleteAction |
  CompareRegErrorAction

export const compareRegReducer =
  (state: ICompareRegState = new CompareRegState(),
    action: ActionTypes): ICompareRegState => {
    switch (action.type) {
      case DirectoryCompareActionTypes.COMPARE_REG:
        return CompareRegState.build({
          errorMessage: '',
          isLoading: true,
          result: undefined
        })
      case DirectoryCompareActionTypes.COMPARE_REG_COMPLETE:
        console.log('complete:', action.compareRegResult)
        return CompareRegState.build({
          errorMessage: '',
          isLoading: false,
          result: action.compareRegResult.compareRegistrations
        })
      case DirectoryCompareActionTypes.COMPARE_REG_ERROR:
        return CompareRegState.build({
          ...state,
          errorMessage: action.error.message || 'the event search operation was unsuccessful',
          isLoading: false
        })
      default:
        return state
    }
  }
