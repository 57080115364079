import { InternalClientActionType } from './types'
import {
  InternalAction,
  DataAction,
  DataActionCreator,
  InternalErrorAction,
  InternalDataErrorCreator
} from '../../actions'
import {
  RefreshEventArgs,
  RefreshEventAction
} from '../external/refreshEvent'
import { IInternalActionCreator } from '../../reducers/external/actionMapper'

export interface RefreshEventInternalAction
  extends InternalAction, DataAction<RefreshEventArgs> {
  type: InternalClientActionType.REFRESH_EVENT
}

export const refreshEventInternal:
  IInternalActionCreator<RefreshEventInternalAction, RefreshEventAction> = (
    action: RefreshEventAction
  ): RefreshEventInternalAction => ({
    ...action,
    type: InternalClientActionType.REFRESH_EVENT
  })

export interface RefreshEventCompleteInternalAction
  extends InternalAction, DataAction<RefreshEventArgs> {
    type: InternalClientActionType.REFRESH_EVENT_COMPLETE,
    refreshEventResult: any
  }

export const refreshEventCompleteInternal:
  DataActionCreator<RefreshEventCompleteInternalAction, RefreshEventArgs> = (
    refreshEventResult: any,
    args: RefreshEventArgs
  ): RefreshEventCompleteInternalAction => {
    if (!refreshEventResult.refreshEvent.success) {
      console.error(refreshEventResult.refreshEvent.message)
      throw new Error(refreshEventResult.refreshEvent.message || 'unspecified')
    }
    return ({
    type: InternalClientActionType.REFRESH_EVENT_COMPLETE,
    refreshEventResult,
    args
  })
}

export interface RefreshEventErrorInternalAction
  extends InternalErrorAction<RefreshEventArgs>, DataAction<RefreshEventArgs> {
    type: InternalClientActionType.REFRESH_EVENT_ERROR
  }

export const refreshEventErrorInternal:
  InternalDataErrorCreator<RefreshEventErrorInternalAction, RefreshEventArgs> = (
    args: RefreshEventArgs,
    error: any
  ): RefreshEventErrorInternalAction => {
    return {
      type: InternalClientActionType.REFRESH_EVENT_ERROR,
      error,
      args
    }
  }