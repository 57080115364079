import { Distance } from '@athlinks/eventmetadata-types'
import { convertDistance } from '@athlinks/lib-units'
import { formatDistance } from './unitUtil'
import { LabelVerbosity } from '@athlinks/lib-units/dist/units'

export const formattedDistance = (distance: Distance): string => {
  if (distance) {
    const formatted = convertDistance(
      formatDistance(distance),
      { verbosity: LabelVerbosity.SHORT, capitalize: false }
    )

    if (formatted) {
      return `${formatted.value}${formatted.label}`
    }
  }
  return '-'
}
