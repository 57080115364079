import { Transerializable } from '@athlinks/get-or'
import {
  IDirectoryDataMutationResult
} from './Directory'

export interface IDirectoryDeleteDatumState {
  errorMessage: string,
  isLoading: boolean,
  result: IDirectoryDataMutationResult
}

export class DirectoryDeleteDatumState extends Transerializable
  implements IDirectoryDeleteDatumState {
  errorMessage: string = ''
  isLoading: boolean = false
  result: IDirectoryDataMutationResult = undefined
}