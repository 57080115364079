import { ActionsObservable, ofType } from 'redux-observable'
import {
  Observable,
  from,
  of
} from 'rxjs'
import {
  flatMap,
  catchError
} from 'rxjs/operators'

import {
  CreateDatumInternalAction,
  CreateDatumCompleteInternalAction,
  CreateDatumErrorInternalAction,
  createDatumCompleteInternal,
  createDatumErrorInternal
} from '../actions/internal/createDatum'
import { InternalClientActionType } from '../actions/internal/types'
import { waitForMutationResponse } from './util'
import { createDatumMutationBuilder } from './util/mutations'

export function createDatumEpic(
  actions$: ActionsObservable<CreateDatumInternalAction>
): Observable<CreateDatumCompleteInternalAction |
  CreateDatumErrorInternalAction> {
    return actions$.pipe(
      ofType(InternalClientActionType.CREATE_DATUM),
      flatMap(action$ => {
        const { args } = action$

        return from(
          waitForMutationResponse(
            args,
            createDatumMutationBuilder,
            createDatumCompleteInternal,
            createDatumErrorInternal
          )
        ).pipe(
          catchError(err => of(err))
        )
      }),
      catchError(err => of(err))
    )
  }
