import { get } from 'lodash'
import {
  IEvent,
  IEventLink,
  IMasterEvent,
  IMasterEventLink,
  IRace,
  IRaceLink,
  IRegChoice,
  IRegChoiceLink,
  IExternalEvent,
  IExternalRegChoice,
  IExternalRace
} from '../models/Directory'

type ParentLink = IMasterEventLink | IEventLink

// event lookup data mappers
// master event
export const mapMasterEventLink =
  (parentId: string, link: IMasterEventLink): IMasterEvent => ({
  id: link.id,
  name: link.name,
  parentId,
  platform: link.platform.name,
  platformMetadataEndpoint: link.platform.metadataEndpoint,
  platformOwnedId: link.platformOwnedIdentifier
})

export const getMasterEvents =
  (parentId: string, masterEventLinks: IMasterEventLink[]): IMasterEvent[] => {
  return masterEventLinks.map((link) => mapMasterEventLink(parentId, link))
}

// event
const getParentPlatformOwnedId = (parentLinks: ParentLink[], platformId: string): string => {
  const link = parentLinks.find((pLink) => pLink.platform.id === platformId)
  return get(link, 'platformOwnedIdentifier', undefined)
}

export const mapEventLink =
  (parentId: string, parentPlatformOwnedId: string, link: IEventLink): IEvent => ({
  date: link.date,
  id: link.id,
  name: link.name,
  parentId,
  parentPlatformOwnedId,
  platform: link.platform.name,
  platformOwnedId: link.platformOwnedIdentifier
})

export const getEventsFromLinks =
  (parentId: string, parentLinks: IMasterEventLink[], links: IEventLink[]): IEvent[] => {
  return links.map((link) => {
    const parentPlatformOwnedId = getParentPlatformOwnedId(parentLinks, link.platform.id)
    return mapEventLink(parentId, parentPlatformOwnedId, link)
  })
}

export const getEvents =
  (events: IExternalEvent[], masterEventLinks: IMasterEventLink[]): IEvent[] => {
  return events.reduce((acc: IEvent[], event: IExternalEvent) => {
    return acc.concat(getEventsFromLinks(event.id, masterEventLinks, event.links))
  }, [])
}

// race
export const mapRaceLink =
  (parentId: string, parentPlatformOwnedId: string, link: IRaceLink, eventId): IRace => ({
  date: link.date,
  distance: link.distance,
  id: link.id,
  name: link.name,
  parentId,
  parentPlatformOwnedId,
  platform: link.platform.name,
  platformOwnedId: link.platformOwnedIdentifier,
  eventId: eventId
})

export const getRacesFromLinks =
  (parentId: string, parentLinks: IEventLink[], links: IRaceLink[], eventId): IRace[] => {
  return links.map((link) => {
    const parentPlatformOwnedId = getParentPlatformOwnedId(parentLinks, link.platform.id)
    return mapRaceLink(parentId, parentPlatformOwnedId, link, eventId)
  })
}

export const getRaces = (events: IExternalEvent[]): IRace[] => {
  return events.reduce((acc: IRace[], event: IExternalEvent) => {
    const races: IExternalRace[] = event.races || []
    return acc.concat(races.reduce((_acc: IRace[], race: IExternalRace) => {
      const raceLinks: IRaceLink[] = race.links
      return _acc.concat(getRacesFromLinks(race.id, event.links, raceLinks, event.id))
    }, []))
  }, [])
}

// reg choice
export const mapRegChoiceLink =
  (parentId: string, parentPlatformOwnedId: string, link: IRegChoiceLink, eventId): IRegChoice => ({
  date: link.date,
  id: link.id,
  name: link.name,
  parentId,
  parentPlatformOwnedId,
  platform: link.platform.name,
  platformOwnedId: link.platformOwnedIdentifier,
  eventId: eventId
})

export const getRegChoicesFromLinks =
  (parentId: string, parentLinks: IEventLink[], links: IRegChoiceLink[], eventId): IRegChoice[] => {
  return links.map((link) => {
    const parentPlatformOwnedId = getParentPlatformOwnedId(parentLinks, link.platform.id)
    return mapRegChoiceLink(parentId, parentPlatformOwnedId, link, eventId)
  })
}

export const getRegChoices = (events: IExternalEvent[]): IRegChoice[] => {
  return events.reduce((acc: IRegChoice[], event: IExternalEvent) => {
    const regChoices: IExternalRegChoice[] = event.regChoices
    return acc.concat(regChoices.reduce((_acc: IRegChoice[], regChoice: IExternalRegChoice) => {
      const regChoiceLinks: IRegChoiceLink[] = regChoice.links
      return _acc.concat(getRegChoicesFromLinks(regChoice.id, event.links, regChoiceLinks, event.id))
    }, []))
  }, [])
}

/*
NOTE: Did not had time to check see if lib itself has method to return this witout time
added this temp just to get UI to match. Will have to update this before push
*/

export const dateWithoutTime = (date: string) => date ? date.split(',')[0] : null