import { Observable } from 'rxjs'
import { ofType } from 'redux-observable'
import { mergeMap, map, delay, mapTo } from 'rxjs/operators'

import {
  TokenRefreshAction,
  TokenRefreshActionComplete,
  AuthActionComplete,
  tokenRefreshComplete,
  tokenRefresh,
  authError,
  AuthActionError
} from '../../actions/auth'
import { AuthActionTypes } from '../../actions/types'
import { refreshToken } from '../../keycloak/KeycloakService'

type StartRefreshTypes = AuthActionComplete |
 TokenRefreshActionComplete

export function startRefreshToken(
  actions$: Observable<StartRefreshTypes>
): Observable<TokenRefreshAction> {
  return actions$.pipe(
    ofType(AuthActionTypes.AUTH_COMPLETE,
      AuthActionTypes.TOKEN_REFESH_COMPLETE),
    delay(1000 * 60), // hack for now, see below
    mapTo(tokenRefresh())
  )
}

/**
 * I probably just need a break, but we need to only emit a
 * refresh when the token is _about_ to expire. - MW
 * @param actions$
 */
export function startRefreshTokenProperly(
  actions$: Observable<StartRefreshTypes>
): Observable<TokenRefreshAction> {
  return actions$.pipe(
    ofType(AuthActionTypes.AUTH_COMPLETE,
      AuthActionTypes.TOKEN_REFESH_COMPLETE),
    mergeMap((action$: StartRefreshTypes) => {
      const { exp } = action$
      const obs = new Observable<TokenRefreshAction>().pipe(
        delay(exp),
        map(() => tokenRefresh())
      )
      return obs
    })
  )
}

export function refreshTokenEpic(
  actions$: Observable<TokenRefreshAction>
): Observable<TokenRefreshActionComplete | AuthActionError> {
  return actions$.pipe(
    ofType(AuthActionTypes.TOKEN_REFRESH),
    mergeMap(async (action$: TokenRefreshAction) => {
      try {
        const keycloakInfoWithNewToken = await refreshToken()
        return tokenRefreshComplete(keycloakInfoWithNewToken)
      } catch (error) {
        return authError(error)
      }
    })
  )
}