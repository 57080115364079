import { Transerializable } from '@athlinks/get-or'
import { IDatumType } from './Directory'
import { IPlatform } from './Platforms'

export interface IDirectorySearchDataState {
  platform: IPlatform,
  datumType: IDatumType,
  id: string,
}

export class DirectorySearchDataState extends Transerializable
  implements IDirectorySearchDataState {
  platform: IPlatform = {} as IPlatform
  datumType: IDatumType = {} as IDatumType
  id: string = ''
}