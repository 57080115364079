export enum Routes {
  DEFAULT         = '/partnersync',
  PARTNER_SYNC    = '/partnersync',
  EVENTS          = '/events',
  LOGIN           = '/login',
  LOGOUT          = '/logout',
  NOT_FOUND       = '/notfound',
  MANAGE_LINKS    = '/manage-links',
  PROPOSITION     = '/',
  COMPARE_REG     = '/compare-reg',
  RESULTS     = '/results'
}