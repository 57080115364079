import React, { Component } from 'react'
import { Draggable, DragDropContext, Droppable } from 'react-beautiful-dnd'
import { Cell, Grid } from 'react-foundation'
import { Trans } from 'react-i18next'
import { connect, Dispatch } from 'react-redux'
import {
  sortBy,
  groupBy,
  keys
} from 'lodash'
import { deleteDatum } from '../../actions/external/deleteDatum'
import './EventDetailsTable.scss'
import MenuPopup, { MenuItem } from '../menuPopup/MenuPopup'
import { State } from '../../models'
import { App } from '../../models/App'
import { IExternalEvent } from '../../models/Directory'
import { shortDate } from '../../util/shortDate'
import { getPlatformIcon } from '../../util/getPlatformIcon'
import { getListStyle, getItemStyle } from '../shared/dragStyles'
import { OnDragEndArgs, onDragEnd } from '../../util/onDragEnd'
import { getPlatformEventLink } from '../../util/getPlatformLink'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Routes } from '../../util/routes'
import RegChoicesTable from './RegChoicesTable'
import RacesTable from './RacesTable'
import { linkEntries } from '../../actions/external/linkEntries'
import { LinkIcon } from './Icons'
import { AppActionTypes } from '../../actions/types'

interface RProps extends RouteComponentProps {
  app: App
  dispatch: Dispatch
}

interface IProps extends RProps {
  app: App
  dispatch: Dispatch
  events: IExternalEvent[]
}

class EventsTable extends Component<IProps> {

  updateActiveEvents = (activeEvent: string) => {
    const { app: { activeEvents }, dispatch } = this.props
    dispatch(
      {
        type: AppActionTypes.SET_ACTIVE_EVENTS,
        activeEvents: activeEvents.includes(activeEvent) ?
          activeEvents.filter(i => i !== activeEvent) :
          [...activeEvents, activeEvent]
      }
    )
  }

  render() {
    const {
      app,
      dispatch,
      events = [],
      history
    } = this.props
    const grouped = groupBy(events, x => new Date(+x.date.timeInMilliseconds).getFullYear())
    console.log('activeEvents:', app.activeEvents)
    return (
      <div className='event-details-table events'>

        <Grid vertical>
          {
            // header
          }
          <Grid className={`grid-x details-table-header`}>
            <Cell className='accordion-icon' />
            <Cell large={10} style={{
              flex: 1
            }}>
              <Grid>
                <Cell small={1}>
                  <Trans>Partner</Trans>
                </Cell>
                <Cell small={2}>
                  <Trans>ID</Trans>
                </Cell>
                <Cell className='auto' style={{ flex: 1 }}>
                  <Trans>Name</Trans>
                </Cell>
                <Cell small={1}>
                  <Trans>Date</Trans>
                </Cell>
                <Cell small={1} />
              </Grid>
            </Cell>
            <Cell
              className='shrink details-table-row-menu'
            />
          </Grid>
          {keys(grouped).sort((x, y) => x < y ? 1 : -1).map(year => {
            const sorted = sortBy(grouped[year], ['date.timeInMilliseconds']).reverse()
            return (
              <div key={year}>
                <Grid className={`grid-x details-table-row`}>
                  <Cell large={12}>
                    <h4>{year}</h4>
                  </Cell>
                </Grid>
                <DragDropContext onDragEnd={(e: OnDragEndArgs) => {
                  const destination = sorted.find(x => x.id === e.destination.droppableId)
                  console.log('destination:', destination)
                  const link = sorted.map(x => x.links.find(y => y.id === e.draggableId)).filter(_ => !!_)[0]
                  console.log('link:', link)
                  if (!destination
                    || !link ||
                    destination.links.map(x => x.platform.id).includes(link.platform.id)
                  ) return

                  const entryId = destination.id
                  const linkParentId = (sorted.find(x => x.id === e.source.droppableId) || {}).id
                  onDragEnd(e, {
                    dispatch,
                    entryId,
                    linkId: e.draggableId,
                    linkParentId,
                    token: app.token
                  })
                }}>
                  {sorted
                    .map(event => {
                      const isSelected = app.activeEvents.includes(event.id)
                      return (
                        <div key={event.id}>
                          <Grid key={event.id}
                            className={`grid-x details-table-row clickable ${isSelected ? 'selected' : ''}`}
                            onClick={() => this.updateActiveEvents(event.id)}
                          >
                            <Cell className={`shrink accordion-icon ${isSelected ? 'open' : ''}`}>
                              <i className={`icon ${
                                isSelected ? 'ion-chevron-down' : 'ion-chevron-right'
                                }`}
                              />
                            </Cell>
                            <Cell className='auto' style={{
                              flex: 1
                            }}>
                              <Droppable droppableId={event.id}
                                style={{
                                  flex: 1
                                }}>
                                {(droppableProvided, droppableSnapshot) => (
                                  <div
                                    ref={droppableProvided.innerRef}
                                    style={{
                                      ...getListStyle(droppableSnapshot.isDraggingOver),
                                      flex: 1
                                    }}
                                    {...droppableProvided.droppableProps}
                                  >
                                    {sortBy(event.links, 'platform.name').reverse().map((link, index) => {
                                      return (
                                        <Grid className='details-table-row'
                                          key={link.id}
                                          style={{
                                            display: 'flex',
                                            flex: 1,
                                            flexDirection: 'row',
                                            justifyContent: 'flex-start'
                                          }}>
                                          <Cell small={1}>
                                            <Draggable draggableId={link.id}
                                              key={link.id}
                                              index={index}
                                              style={{ flex: 1 }}
                                            >
                                              {(draggableProvided, draggableSnapshot) => (
                                                <div
                                                  ref={draggableProvided.innerRef}
                                                  {...draggableProvided.draggableProps}
                                                  {...draggableProvided.dragHandleProps}
                                                  style={getItemStyle(
                                                    draggableSnapshot.isDragging,
                                                    draggableProvided.draggableProps.style
                                                  )}
                                                >
                                                  <a
                                                    href={getPlatformEventLink(link)}
                                                    rel='noopener noreferrer'
                                                    target='_blank'>{getPlatformIcon(link.platform)}</a>
                                                </div>
                                              )}
                                            </Draggable>
                                          </Cell>
                                          <Cell small={2}>
                                            {link.platformOwnedIdentifier}
                                          </Cell>
                                          <Cell className='auto' style={{ flex: 1 }}>
                                            {link.name}
                                          </Cell>
                                          <Cell small={1} style={{ display: 'contents' }}>
                                            {shortDate(link.date)}
                                          </Cell>
                                          {(index === 0 || event.links.length <= 1)
                                            ? null
                                            : LinkIcon(() => {
                                              if (window.confirm('are you sure you want to unlink these events?'))
                                                dispatch(
                                                  linkEntries({
                                                    linkId: link.id,
                                                    isUnlink: true,
                                                    token: app.token
                                                  })
                                                )
                                            })}
                                        </Grid>
                                      )
                                    })}
                                    {droppableProvided.placeholder}
                                  </div>
                                )}
                              </Droppable>
                            </Cell>
                            <Cell small={1}
                              className='shrink details-table-row-menu'
                              onClick={(e) => e.stopPropagation()}
                            >
                              <MenuPopup>
                                {/* This will have a list of options */}
                                {!event.regChoices.find(x => x.links.length > 1)
                                  ? null
                                  : <MenuItem
                                    label='Compare Registrations'
                                    onClick={() => history.push(Routes.COMPARE_REG + window.location.search)}
                                  />
                                }{!event.races.find(x => x.links.length > 1)
                                  || !event.links.find(x => x.platform.name === 'Athlinks')
                                  ? null
                                  : <MenuItem
                                    label='View Live Results'
                                    onClick={() => history.push(Routes.RESULTS + '?eventId=' +
                                      event.links.find(x => x.platform.name === 'Athlinks').platformOwnedIdentifier)
                                    }
                                  />
                                }
                                <MenuItem
                                  label='Delete'
                                  onClick={() => {
                                    if (window.confirm('are you sure you want to delete this event?'))
                                      dispatch(
                                        deleteDatum({
                                          entryId: event.parentId,
                                          linkId: event.id,
                                          isLinked: event.links.length > 1,
                                          token: app.token
                                        })
                                      )
                                  }}
                                />
                              </MenuPopup>
                            </Cell>
                          </Grid>
                          {
                            isSelected &&
                            <div className='event-children-content'>
                              <RegChoicesTable
                                regChoices={event.regChoices}
                              />
                              <RacesTable
                                races={event.races}
                              />
                            </div>
                          }

                        </div>
                      )
                    })}
                </DragDropContext>
              </div>
            )
          })}
        </Grid>
      </div>
    )
  }
}

export default connect((state: State): RProps => ({
  app: state.app,
  dispatch: undefined,
  history: undefined,
  location: undefined,
  match: undefined
}))(withRouter(EventsTable))
