import React from 'react'
import { Trans } from 'react-i18next'
import { Button } from '@athlinks/component-library'
import './MissingEventHeader.scss'

interface Props {
  title?: string,
  onEventAdd?: Function
}

const MissingEventHeader: React.SFC<Props> = ({
  title = '',
  onEventAdd
}) =>
  <div className='missing-event-header'>
    <h5 className='directory-card-title'>
      <Trans>{title}</Trans>
    </h5>
    <Button
      className={'add-to-directory-button'}
      color='clear'
      maxWidth='225px'
      text='Add to Directory'
      onClick={onEventAdd}
    />
  </div>

export default MissingEventHeader