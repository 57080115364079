import gql from 'graphql-tag'
import { CompareRegArgs } from '../../actions/external/compareReg'
import { EventSearchArgs } from '../../actions/external/eventSearch'
import { PlatformsArgs } from '../../actions/external/platforms'

const platformsQuery =
  gql`
    query Platforms(
      $onlyWithMetadata: Boolean
    ) {
      platforms(
        onlyWithMetadata: $onlyWithMetadata
      ) {
          id
          metadataEndpoint
          name
        }
    }
  `

export const platformsQueryBuilder =
(args: PlatformsArgs) => {
  return {
    query: platformsQuery,
    variables: args
  }
}

const eventSearchQuery =
  gql`
    query EventSearch(
      $platform: ID,
      $type: EntryTypes,
      $id: ID,
      $pullMetadata: Boolean
    ) {
      eventSearch(
        platform: $platform,
        id: $id,
        type: $type,
        pullMetadata: $pullMetadata
      ) {
          __typename
          ... on MasterEvent {
            id
            name
            links {
              id
              name
              platform {
                id
                metadataEndpoint
                name
              }
              platformOwnedIdentifier
            }
            events {
              id
              name
              date {
                timeInMilliseconds
                timeZone
              }
              links {
                id
                name
                date {
                  timeInMilliseconds
                  timeZone
                }
                platform {
                  id
                  metadataEndpoint
                  name
                }
                platformOwnedIdentifier
              }
              races {
                id
                name
                date {
                  timeInMilliseconds
                  timeZone
                }
                distance {
                  distanceInMeters
                  distanceUnit
                }
                links {
                  id
                  name
                  date {
                    timeInMilliseconds
                    timeZone
                  }
                  distance {
                    distanceInMeters
                    distanceUnit
                  }
                  platform {
                    id
                    metadataEndpoint
                    name
                  }
                  platformOwnedIdentifier
                }
              }
              regChoices {
                id
                name
                date {
                  timeInMilliseconds
                  timeZone
                }
                links {
                  id
                  name
                  date {
                    timeInMilliseconds
                    timeZone
                  }
                  platform {
                    id
                    metadataEndpoint
                    name
                  }
                  platformOwnedIdentifier
                }
              }
            }
          }
          ... on PlatformMasterEvent {
            id
            name
          }
          ... on PlatformEvent {
            id
            name
          }
      }
    }
  `

export const eventSearchQueryBuilder =
  (args: EventSearchArgs) => {
    return {
      query: eventSearchQuery,
      variables: args
    }
  }

const compareRegQuery =
gql`
query CompareReg($platform: ID, $type: EntryTypes, $id: ID) {
  compareRegistrations(platform: $platform, id: $id, type: $type) {
    regChoice {
      name
    }
    Athlinks {
      athlete {
        firstName
        lastName
      }
    }
    CTLive {
      athlete {
        firstName
        lastName
      }
    }
    RunSignUp {
      athlete {
        firstName
        lastName
      }
    }
  }
}
`

  export const compareRegQueryBuilder =
  (args: CompareRegArgs) => {
    return {
      query: compareRegQuery,
      variables: args
    }
  }