import { combineReducers } from 'redux'
import { appReducers } from './app'
import { compareRegReducer } from './compareReg'
import { eventSearchReducer } from './eventSearch'
import { eventStatusReducer } from './eventStatus'
import {
  directorySearchReducer,
  directoryCreateDatumReducer,
  directoryDeleteDatumReducer,
  directorySearchDataReducer,
  directoryLinkEntriesReducer,
  directoryRefreshEventReducer,
  directoryUserPrefsReducer,
  platformsReducer
} from './directory'

export const combinedReducers = combineReducers({
  app: appReducers,
  compareRegState: compareRegReducer,
  eventSearchState: eventSearchReducer,
  eventStatusState: eventStatusReducer,
  directoryState: combineReducers({
    createDatum: directoryCreateDatumReducer,
    deleteDatum: directoryDeleteDatumReducer,
    searchData: directorySearchDataReducer,
    searchState: directorySearchReducer,
    refreshEvent: directoryRefreshEventReducer,
    userPrefs: directoryUserPrefsReducer
  }),
  platformData: platformsReducer,
  entriesLink: directoryLinkEntriesReducer
})