import { buildClient } from '../client'

export default function(args, queryCreator, callback) {
  const apolloClient = buildClient(args)
  const queryWithVars = queryCreator(args)

  // execute query
  apolloClient
    .query({
      query: queryWithVars.query,
      variables: queryWithVars.variables
    })
    .then((data: any) => {
      callback(data)
    })
    .catch((error) => {
      callback(error)
    })
}
