import { Action } from 'redux'
import { KeycloakError } from 'keycloak-js'

import { AuthActionTypes } from './types'
import { KeycloakInfo } from '../models/KeycloakUserInfo'

export interface AuthAction extends Action {
  redirectUri: string,
  type: AuthActionTypes.AUTH
}

export const auth = (redirectUri: string): AuthAction => ({
  redirectUri,
  type: AuthActionTypes.AUTH
})

export interface AuthActionComplete extends Action, KeycloakInfo {
  type: AuthActionTypes.AUTH_COMPLETE
}

export const authComplete = (
  keycloakInfo: KeycloakInfo
): AuthActionComplete => ({
  type: AuthActionTypes.AUTH_COMPLETE,
  ...keycloakInfo
})

export interface UnauthAction extends Action {
  type: AuthActionTypes.UNAUTH
}

export const unauth = (): UnauthAction => ({
  type: AuthActionTypes.UNAUTH
})

export interface UnauthActionComplete extends Action {
  type: AuthActionTypes.UNAUTH_COMPLETE
}

export const unauthComplete = (): UnauthActionComplete => ({
  type: AuthActionTypes.UNAUTH_COMPLETE
})

export interface AuthActionError {
  type: AuthActionTypes.AUTH_ERROR,
  error: KeycloakError
}

export const authError = (error: KeycloakError): AuthActionError => ({
  type: AuthActionTypes.AUTH_ERROR,
  error
})

export interface TokenRefreshAction extends Action {
  type: AuthActionTypes.TOKEN_REFRESH
}

export const tokenRefresh = (): TokenRefreshAction => ({
  type: AuthActionTypes.TOKEN_REFRESH
})

export interface TokenRefreshActionComplete extends Action, KeycloakInfo {
  type: AuthActionTypes.TOKEN_REFESH_COMPLETE,

}

export const tokenRefreshComplete = (
  keycloakInfo: KeycloakInfo
): TokenRefreshActionComplete => ({
  ...authComplete(keycloakInfo),
  type: AuthActionTypes.TOKEN_REFESH_COMPLETE
})
