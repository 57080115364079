import React, { Component } from 'react'
import { Trans } from 'react-i18next'
import { EventRow } from './EventRow'
import EventRowHeader from './EventRowHeader'
import Pager from '../pager/Pager'
import { eventStatusChange, eventSearch } from '@athlinks/admin-events-client'
import {
  EventStatus,
  IEvent
} from '@athlinks/admin-events-model'
import { concat, indexOf, get, map, size, without } from 'lodash'
import { Cell, Grid } from 'react-foundation'
import { connect, Dispatch } from 'react-redux'
import { EventSearchState } from '../../models/EventSearchState'
import { EventStatusState } from '../../models/EventStatusState'
import { State } from '../../models/index'
import { App } from '../../models/App'
import { Button } from '@athlinks/component-library'
import { EventRowDivider } from './EventRowDivider'

import styles from './EventsTable.module.scss'

interface IProps {
  app: App,
  dispatch: Dispatch,
  eventSearchState: EventSearchState,
  eventStatusState: EventStatusState,
  pageSize: number
}
interface IState {
  selectedEvents: Array<string>,
  selectAllChecked: boolean,
  selectAllIndeterminate: boolean,
  currentPage: number
}

class EventsTable extends Component<IProps, IState> {
  defaultEventSearchAction = eventSearch({
    status: EventStatus.Pending,
    fro: 0,
    limit: this.props.pageSize,
    token: this.props.app.token || ''
  })

  constructor(props: IProps) {
    super(props)

    this.state = {
      selectedEvents: [],
      selectAllChecked: false,
      selectAllIndeterminate: false,
      currentPage: 0
    }
  }

  componentDidMount() {
    this.props.dispatch(this.defaultEventSearchAction)
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.eventStatusState.isLoading && !nextProps.eventStatusState.isLoading) {
      this.props.dispatch(this.defaultEventSearchAction)
    }
  }

  isSelected = function (eventRows: Array<string>, id: string): boolean {
    return indexOf(eventRows, id) !== -1
  }

  rowCallback = (id: string, results: IEvent[]) => {
    const {
      selectedEvents
    } = this.state

    const selected = !this.isSelected(selectedEvents, id)
      ? concat(selectedEvents, [id])
      : without(selectedEvents, id)
    const indeterminate = (size(selected) > 0) && (size(selected) !== size(results))

    this.setState({
      selectedEvents: selected,
      selectAllIndeterminate: indeterminate
    })
  }

  selectAllHandler = (results: IEvent[]) => {
    const {
      selectAllChecked
    } = this.state

    this.setState({
      selectedEvents: selectAllChecked ? [] : map(results, 'id'),
      selectAllChecked: !selectAllChecked,
      selectAllIndeterminate: false
    })
  }

  pageClickHandler = (currentPage: number) => {
    const { app, dispatch, pageSize } = this.props

    this.setState({
      currentPage
    })

    dispatch(
      eventSearch({
        status: EventStatus.Pending,
        fro: currentPage * pageSize,
        limit: pageSize,
        token: app.token
      }))
  }

  acceptHandler = () => {
    this.props.dispatch(
      eventStatusChange({
        events: this.state.selectedEvents.map(eventId => {
          return { id: parseInt(eventId, 10), status: EventStatus.Approved }
        }),
        token: this.props.app.token
      })
    )

    this.setState({
      selectedEvents: [],
      selectAllChecked: false,
      selectAllIndeterminate: false
    })
  }

  rejectHandler = () => {
    this.props.dispatch(
      eventStatusChange({
        events: this.state.selectedEvents.map(eventId => {
          return { id: parseInt(eventId, 10), status: EventStatus.Rejected }
        }),
        token: this.props.app.token
      })
    )

    this.setState({
      selectedEvents: [],
      selectAllChecked: false,
      selectAllIndeterminate: false
    })
  }

  render() {
    const {
      eventSearchState,
      pageSize
    } = this.props
    const {
      selectedEvents,
      selectAllChecked,
      selectAllIndeterminate,
      currentPage
    } = this.state
    console.log('eventSearchState', eventSearchState)
    console.log('selectedEvents', selectedEvents)
    const results = get(eventSearchState, 'result.results', [])
    const numResults = get(eventSearchState, 'result.totalCount', 0)
    const numSelected: Number = size(selectedEvents)
    const error = eventSearchState.errorMessage
    const message: string = error
      && (
        error.includes('You are not authorized')
        || error.includes('Role check failure')
        || error.includes('Auth failure')
      )
      ? 'Access Denied' : 'Error! Event search failed'

    return (
      <Grid vertical className={`auto ${styles.container}`}>
        <Grid className={`grid-margin-x ${styles.buttonContainer}`}>
          <Cell className={'auto'} />
          <Cell medium={2} className={'shrink'}>
            <Button
              maxWidth={'15rem'}
              text='approve'
              onClick={this.acceptHandler}
              disabled={!numSelected}
              color='ghost'
            />
          </Cell>
          <Cell medium={2} className={'shrink'}>
            <Button
              maxWidth={'15rem'}
              text='reject'
              onClick={this.rejectHandler}
              disabled={!numSelected}
              color='ghost'
            />
          </Cell>
        </Grid>
        <div className={styles.tableContainer}>
          <EventRowHeader
            selectAllIndeterminate={selectAllIndeterminate}
            selectAllChecked={selectAllChecked}
            selectAllDisabled={size(results) < 1}
            selectAllCallback={() => this.selectAllHandler(results)}
          />
          <EventRowDivider />
          <Cell className={`body ${styles.eventRowContainer}`}>
            {
              error ? message :
                !eventSearchState.isLoading
                  ? results.map((event, idx) =>
                    <EventRow
                      key={`event-${idx}`}
                      event={event}
                      callback={() => this.rowCallback(event.id, results)}
                      selected={this.isSelected(selectedEvents, event.id)}
                    />
                  )
                  : <Trans>Loading...</Trans>
            }
          </Cell>
          <EventRowDivider />
          <Grid className='grid-x'>
            <Cell>
              {`${numSelected} of ${numResults} events selected, page ${currentPage + 1}`}
            </Cell>
          </Grid>
          <Grid className='grid-x'>
            <Cell>
              <Pager
                currentPage={currentPage}
                perPage={pageSize}
                total={numResults}
                onClickHandler={this.pageClickHandler}
              />
            </Cell>
          </Grid>
        </div>
      </Grid>
    )
  }
}

export default connect((state: State): IProps => ({
  app: state.app,
  dispatch: undefined,
  eventSearchState: state.eventSearchState,
  eventStatusState: state.eventStatusState,
  pageSize: 25
}))(EventsTable)
