import { showNotification, NotificationTypes } from '../components/toast/Toast'

import {
  DirectoryEventSearchState,
  IDirectoryEventSearchState
} from '../models/DirectoryEventSearchState'

import {
  DirectoryCreateDatumState,
  IDirectoryCreateDatumState
} from '../models/DirectoryCreateDatumState'

import {
  DirectoryDeleteDatumState,
  IDirectoryDeleteDatumState
} from '../models/DirectoryDeleteDatumState'

import {
  DirectorySearchDataState,
  IDirectorySearchDataState
} from '../models/DirectorySearchDataState'

import {
  DirectoryEntriesLinkState,
  IDirectoryEntriesLinkState
} from '../models/DirectoryEntriesLinkState'

import {
  DirectoryEventRefreshState,
  IDirectoryEventRefreshState
} from '../models/DirectoryEventRefreshState'

import {
  DirectoryUserPrefsState,
  IDirectoryUserPrefsState
} from '../models/DirectoryUserPrefsState'

import {
  DirectoryEventSearchAction,
  DirectoryEventSearchCompleteAction,
  DirectoryEventSearchErrorAction,
  DirectoryChangePlatformAction,
  DirectoryChangeTypeAction,
  DirectoryChangeIDAction,
  DirectoryClearEventAction,
  DirectoryCreateDatumAction,
  DirectoryCreateDatumCompleteAction,
  DirectoryCreateDatumErrorAction,
  DirectoryDeleteDatumAction,
  DirectoryDeleteDatumCompleteAction,
  DirectoryDeleteDatumErrorAction,
  PlatformsAction,
  PlatformsCompleteAction,
  PlatformsErrorAction,
  DirectoryEntriesLinkAction,
  DirectoryEntriesLinkCompleteAction,
  DirectoryEntriesLinkErrorAction,
  DirectoryClearLinkingActiveSelection,

  DirectoryEventRefreshAction,
  DirectoryEventRefreshCompleteAction,
  DirectoryEventRefreshErrorAction,
  DirectorySetUserPrefsAction
} from '../actions/directory'

import {
  DirectorySearchDataActionTypes,
  DirectoryUserPrefsActionTypes,
  InternalLinkingDataActionTypes
} from '../actions/types'

import {
  PlatformsState,
  IPlatformsState
} from '../models/Platforms'

import {
  DirectoryPlatformsActionTypes,
  DirectoryLinkingActionTypes,
  DirectoryEventActionTypes
} from '../actions/external/types'

type PlatformsActionTypes = PlatformsAction |
  PlatformsCompleteAction |
  PlatformsErrorAction

type SearchActionTypes = DirectoryEventSearchAction |
  DirectoryEventSearchCompleteAction |
  DirectoryEventSearchErrorAction |
  DirectoryClearEventAction

type CreateDatumActionTypes = DirectoryCreateDatumAction |
  DirectoryCreateDatumCompleteAction |
  DirectoryCreateDatumErrorAction

type DeleteDatumActionTypes = DirectoryDeleteDatumAction |
  DirectoryDeleteDatumCompleteAction |
  DirectoryDeleteDatumErrorAction

type SearchDataTypes = DirectoryChangePlatformAction |
  DirectoryChangeTypeAction |
  DirectoryChangeIDAction |
  PlatformsCompleteAction

type LinkActionTypes = DirectoryEntriesLinkAction |
  DirectoryEntriesLinkCompleteAction |
  DirectoryEntriesLinkErrorAction |
  DirectoryClearLinkingActiveSelection

type RefreshActionTypes = DirectoryEventRefreshAction |
  DirectoryEventRefreshCompleteAction |
  DirectoryEventRefreshErrorAction

type UserPrefsActionTypes = DirectorySetUserPrefsAction

// Platforms Reducer
export const platformsReducer =
  (state: IPlatformsState = new PlatformsState(),
    action: PlatformsActionTypes): IPlatformsState => {
    switch (action.type) {
      case DirectoryPlatformsActionTypes.DIRECTORY_PLATFORMS:
        return PlatformsState.build({
          errorMessage: '',
          isLoading: true,
          result: undefined
        })
      case DirectoryPlatformsActionTypes.DIRECTORY_PLATFORMS_COMPLETE:
        return PlatformsState.build({
          errorMessage: '',
          isLoading: false,
          result: action.platformsResult
        })
      case DirectoryPlatformsActionTypes.DIRECTORY_PLATFORMS_ERROR:
        return PlatformsState.build({
          errorMessage: action.error.message || 'the get platforms operation was unsuccessful',
          isLoading: false,
          result: undefined
        })
      default:
        return state
    }
  }

// Directory Search Reducer
export const directorySearchReducer =
  (state: IDirectoryEventSearchState = new DirectoryEventSearchState(),
    action: SearchActionTypes): IDirectoryEventSearchState => {
    switch (action.type) {
      case DirectoryEventActionTypes.DIRECTORY_EVENT_SEARCH:
        return DirectoryEventSearchState.build({
          ...state,
          errorMessage: '',
          isLoading: true
        })
      case DirectoryEventActionTypes.DIRECTORY_EVENT_SEARCH_COMPLETE:
        return DirectoryEventSearchState.build({
          errorMessage: '',
          isLoading: false,
          result: action.eventSearchResult.eventSearch
        })
      case DirectoryEventActionTypes.DIRECTORY_EVENT_SEARCH_ERROR:
        return DirectoryEventSearchState.build({
          errorMessage: action.error.message || 'the event search operation was unsuccessful',
          isLoading: false,
          result: undefined
        })
      case DirectorySearchDataActionTypes.DIRECTORY_CLEAR_EVENT:
        return DirectoryEventSearchState.build({
          errorMessage: '',
          isLoading: false,
          result: null
        })
      default:
        return state
    }
  }

// directory create datum reducer
export const directoryCreateDatumReducer =
  (state: IDirectoryCreateDatumState = new DirectoryCreateDatumState(),
    action: CreateDatumActionTypes): IDirectoryCreateDatumState => {
    switch (action.type) {
      case DirectoryEventActionTypes.DIRECTORY_CREATE_DATUM:
        return DirectoryCreateDatumState.build({
          errorMessage: '',
          isLoading: true,
          result: undefined
        })
      case DirectoryEventActionTypes.DIRECTORY_CREATE_DATUM_COMPLETE:
        showNotification({
          title: 'Successfully created'
        }, NotificationTypes.SUCCESS)
        return DirectoryCreateDatumState.build({
          errorMessage: '',
          isLoading: false,
          result: action.createDatumResult.createDatum
        })
      case DirectoryEventActionTypes.DIRECTORY_CREATE_DATUM_ERROR:
        return DirectoryCreateDatumState.build({
          errorMessage: action.error.message || 'the create datum operation was unsuccessful',
          isLoading: false,
          result: undefined
        })
      default:
        return state
    }
  }

// directory delete datum reducer
export const directoryDeleteDatumReducer =
  (state: IDirectoryDeleteDatumState = new DirectoryDeleteDatumState(),
    action: DeleteDatumActionTypes): IDirectoryDeleteDatumState => {
    switch (action.type) {
      case DirectoryEventActionTypes.DIRECTORY_DELETE_DATUM:
        return DirectoryDeleteDatumState.build({
          errorMessage: '',
          isLoading: true,
          result: undefined
        })
      case DirectoryEventActionTypes.DIRECTORY_DELETE_DATUM_COMPLETE:
        showNotification({
          title: 'Successfully deleted'
        }, NotificationTypes.SUCCESS)
        return DirectoryDeleteDatumState.build({
          errorMessage: '',
          isLoading: false,
          result: action.deleteDatumResult.deleteDatum
        })
      case DirectoryEventActionTypes.DIRECTORY_DELETE_DATUM_ERROR:
        showNotification({
          title: 'Delete failed' +
            (!action.error.message
              ? null
              : ' due to: ' + action.error.message)
        }, NotificationTypes.ERROR)
        return DirectoryDeleteDatumState.build({
          errorMessage: action.error.message || 'the delete datum operation was unsuccessful',
          isLoading: false,
          result: undefined
        })
      default:
        return state
    }
  }

export const directorySearchDataReducer =
  (state: IDirectorySearchDataState = new DirectorySearchDataState(),
    action: SearchDataTypes): IDirectorySearchDataState => {
    switch (action.type) {
      case DirectoryPlatformsActionTypes.DIRECTORY_PLATFORMS_COMPLETE:
        return DirectorySearchDataState.build({
          ...state,
          platform: (
            state.platform && action.platformsResult.platforms.find(x => x.id === state.platform.id)
          ) || state.platform
        })
      case DirectorySearchDataActionTypes.DIRECTORY_CHANGE_PLATFORM:
        return DirectorySearchDataState.build({
          ...state,
          platform: action.platform
        })
      case DirectorySearchDataActionTypes.DIRECTORY_CHANGE_TYPE:
        return DirectorySearchDataState.build({
          ...state,
          datumType: action.datumType
        })
      case DirectorySearchDataActionTypes.DIRECTORY_CHANGE_ID:
        return DirectorySearchDataState.build({
          ...state,
          id: action.id
        })
      default:
        return state
    }
  }

export const directoryLinkEntriesReducer =
  (state: IDirectoryEntriesLinkState = new DirectoryEntriesLinkState(),
    action: LinkActionTypes): IDirectoryEntriesLinkState => {
    switch (action.type) {
      case DirectoryLinkingActionTypes.DIRECTORY_ENTRIES_LINK:
        return DirectoryEntriesLinkState.build({
          errorMessage: '',
          isLoading: true,
          result: undefined,
          activeEntry: action.args.entryId
        })
      case DirectoryLinkingActionTypes.DIRECTORY_ENTRIES_LINK_COMPLETE:
        return DirectoryEntriesLinkState.build({
          errorMessage: '',
          isLoading: false,
          result: action.linkEntriesResult.linkEntries,
          activeEntry: action.args.entryId
        })
      case DirectoryLinkingActionTypes.DIRECTORY_ENTRIES_LINK_ERROR:
        showNotification({
          title: 'Link failed' +
            (!action.error.message
              ? null
              : ' due to: ' + action.error.message)
        }, NotificationTypes.ERROR)
        return DirectoryEntriesLinkState.build({
          errorMessage: action.error.message || 'the entries link operation was unsuccessful',
          isLoading: false,
          result: undefined,
          activeEntry: action.args.entryId
        })
      case InternalLinkingDataActionTypes.CLEAR_LINKING_ACTIVE_SELECTION:
        return DirectoryEntriesLinkState.build({
          errorMessage: '',
          isLoading: false,
          result: undefined,
          activeEntry: null
        })
      default:
        return state
    }
  }

export const directoryRefreshEventReducer =
  (state: IDirectoryEventRefreshState = new DirectoryEventRefreshState(),
    action: RefreshActionTypes): IDirectoryEventRefreshState => {
    switch (action.type) {
      case DirectoryEventActionTypes.DIRECTORY_EVENT_REFRESH:
        return DirectoryEventRefreshState.build({
          errorMessage: '',
          isLoading: true,
          result: undefined
        })
      case DirectoryEventActionTypes.DIRECTORY_EVENT_REFRESH_COMPLETE:
        const { refreshEventResult: { refreshEvent } } = action
        if (refreshEvent.success) {
          showNotification({
            title: 'Event successfuly updated'
          }, NotificationTypes.SUCCESS)
        }
        showNotification({
          title: 'There are no updates available'
        }, NotificationTypes.INFO)
        return DirectoryEventRefreshState.build({
          errorMessage: '',
          isLoading: false,
          result: action.refreshEventResult.refreshEvent
        })
      case DirectoryEventActionTypes.DIRECTORY_EVENT_REFRESH_ERROR:
        showNotification({
          title: 'Event could not be refreshed' +
            (!action.error.message
              ? null
              : ' due to: ' + action.error.message)
        }, NotificationTypes.ERROR)
        return DirectoryEventRefreshState.build({
          errorMessage: action.error.message || 'the event refresh operation was unsuccessful',
          isLoading: false,
          result: undefined
        })
      default:
        return state
    }
  }

export const directoryUserPrefsReducer =
  (state: IDirectoryUserPrefsState = new DirectoryUserPrefsState(),
    action: UserPrefsActionTypes): IDirectoryUserPrefsState => {
    switch (action.type) {
      case DirectoryUserPrefsActionTypes.DIRECTORY_SET_USER_PREFS:
        return {
          ...state,
          ...action.userPrefs
        }
      default:
        return state
    }
  }