import {
  InternalAction,
  ExternalAction
} from '../../actions'
import { Action } from 'redux'
import {
  DirectoryEventActionTypes,
  DirectoryPlatformsActionTypes,
  DirectoryLinkingActionTypes
} from '../../actions/external/types'
import {
  createDatumComplete,
  CreateDatumErrorAction,
  createDatumError,
  CreateDatumAction
} from '../../actions/external/createDatum'

import {
  CreateDatumCompleteInternalAction,
  createDatumInternal
} from '../../actions/internal/createDatum'

import {
  eventSearchComplete,
  EventSearchErrorAction,
  eventSearchError,
  EventSearchAction
} from '../../actions/external/eventSearch'

import {
  deleteDatumComplete,
  DeleteDatumErrorAction,
  deleteDatumError,
  DeleteDatumAction
} from '../../actions/external/deleteDatum'

import {
  linkEntriesComplete,
  LinkEntriesErrorAction,
  linkEntriesError,
  LinkEntriesAction
} from '../../actions/external/linkEntries'

import {
  refreshEventComplete,
  RefreshEventErrorAction,
  refreshEventError,
  RefreshEventAction
} from '../../actions/external/refreshEvent'

import {
  RefreshEventCompleteInternalAction,
  refreshEventInternal
} from '../../actions/internal/refreshEvent'

import {
  EventSearchCompleteInternalAction,
  eventSearchInternal
} from '../../actions/internal/eventSearch'

import {
  DeleteDatumCompleteInternalAction,
  deleteDatumInternal
} from '../../actions/internal/deleteDatum'

import {
  PlatformsAction,
  platformsComplete,
  platformsError
} from '../../actions/external/platforms'
import {
  platformsInternal,
  PlatformsCompleteInternalAction,
  PlatformsErrorInternalAction
} from '../../actions/internal/platforms'

import {
  LinkEntriesCompleteInternalAction,
  linkEntriesInternal
} from '../../actions/internal/linkEntries'

import { InternalClientActionType } from '../../actions/internal/types'

export interface IActionCreator<A extends Action, T extends Action = undefined> {
  (T?): A
}

export interface IExternalActionCreator<
  Out extends ExternalAction<object>,
  In extends InternalAction> extends IActionCreator<Out, In> {}

export interface IInternalActionCreator<
  Out extends InternalAction,
  In extends ExternalAction<object>> extends IActionCreator<Out, In> {}

export interface IExternalToInternalActionMapper {
  [x: string]: IActionCreator<InternalAction, ExternalAction<object>>
}
export interface IInternalToExternalActionMapper {
  [x: string]: IActionCreator<ExternalAction<object>, InternalAction>
}

export const externalToInternalTypeMap: IExternalToInternalActionMapper = {
  [DirectoryEventActionTypes.DIRECTORY_CREATE_DATUM]:
    (action: CreateDatumAction) => createDatumInternal(action),
  [DirectoryEventActionTypes.DIRECTORY_EVENT_SEARCH]:
    (action: EventSearchAction) => eventSearchInternal(action),
  [DirectoryEventActionTypes.DIRECTORY_DELETE_DATUM]:
    (action: DeleteDatumAction) => deleteDatumInternal(action),
  [DirectoryPlatformsActionTypes.DIRECTORY_PLATFORMS]:
    (action: PlatformsAction) => platformsInternal(action),
  [DirectoryLinkingActionTypes.DIRECTORY_ENTRIES_LINK]:
    (action: LinkEntriesAction) => linkEntriesInternal(action),
  [DirectoryEventActionTypes.DIRECTORY_EVENT_REFRESH]:
    (action: RefreshEventAction) => refreshEventInternal(action)
}

export const internalToExternalTypeMap: IInternalToExternalActionMapper = {
  [InternalClientActionType.CREATE_DATUM_COMPLETE]:
    (action: CreateDatumCompleteInternalAction) =>
      createDatumComplete(action),
  [InternalClientActionType.CREATE_DATUM_ERROR]:
    (action: CreateDatumErrorAction) =>
      createDatumError(action),
  [InternalClientActionType.EVENT_SEARCH_COMPLETE]:
    (action: EventSearchCompleteInternalAction) =>
      eventSearchComplete(action),
  [InternalClientActionType.EVENT_SEARCH_ERROR]:
    (action: EventSearchErrorAction) =>
      eventSearchError(action),
  [InternalClientActionType.DELETE_DATUM_COMPLETE]:
    (action: DeleteDatumCompleteInternalAction) =>
      deleteDatumComplete(action),
  [InternalClientActionType.DELETE_DATUM_ERROR]:
    (action: DeleteDatumErrorAction) =>
      deleteDatumError(action),
  [InternalClientActionType.PLATFORMS_COMPLETE]:
    (action: PlatformsCompleteInternalAction) =>
      platformsComplete(action),
  [InternalClientActionType.PLATFORMS_ERROR]:
    (action: PlatformsErrorInternalAction) =>
      platformsError(action),
  [InternalClientActionType.LINK_ENTRIES_COMPLETE]:
    (action: LinkEntriesCompleteInternalAction) =>
      linkEntriesComplete(action),
  [InternalClientActionType.LINK_ENTRIES_ERROR]:
    (action: LinkEntriesErrorAction) =>
      linkEntriesError(action),
  [InternalClientActionType.REFRESH_EVENT_COMPLETE]:
    (action: RefreshEventCompleteInternalAction) =>
      refreshEventComplete(action),
  [InternalClientActionType.REFRESH_EVENT_ERROR]:
    (action: RefreshEventErrorAction) =>
      refreshEventError(action)
}
