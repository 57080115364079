import {
    ExternalAction,
    ExternalErrorAction,
    QueryArgs
  } from '../../actions'
  import { DirectoryEventActionTypes } from './types'
  import { IExternalActionCreator } from '../../reducers/external/actionMapper'
  import {
    DeleteDatumCompleteInternalAction,
    DeleteDatumErrorInternalAction
  } from '../internal/deleteDatum'

  export interface DeleteDatumArgs extends QueryArgs {
    entryId: string,
    linkId?: string,
    isLinked: boolean,
    token: string
  }

  export interface DeleteDatumAction
    extends ExternalAction<DeleteDatumArgs> {
    type: DirectoryEventActionTypes.DIRECTORY_DELETE_DATUM
  }

  export const deleteDatum =
    (args: DeleteDatumArgs): DeleteDatumAction => ({
      args,
      type: DirectoryEventActionTypes.DIRECTORY_DELETE_DATUM
    })

  export interface DeleteDatumCompleteAction
    extends ExternalAction<DeleteDatumArgs> {
      type: DirectoryEventActionTypes.DIRECTORY_DELETE_DATUM_COMPLETE,
      deleteDatumResult: any // TODO Pending
  }

  export const deleteDatumComplete:
    IExternalActionCreator<DeleteDatumCompleteAction,
    DeleteDatumCompleteInternalAction> = (
      action: DeleteDatumCompleteInternalAction
    ): DeleteDatumCompleteAction => ({
      ...action,
      type: DirectoryEventActionTypes.DIRECTORY_DELETE_DATUM_COMPLETE
    })

  export interface DeleteDatumErrorAction
    extends ExternalErrorAction<DeleteDatumArgs> {
    type: DirectoryEventActionTypes.DIRECTORY_DELETE_DATUM_ERROR
  }

  export const deleteDatumError:
    IExternalActionCreator<DeleteDatumErrorAction,
    DeleteDatumErrorInternalAction> = (
      action: DeleteDatumErrorInternalAction
    ): DeleteDatumErrorAction => ({
      ...action,
      type: DirectoryEventActionTypes.DIRECTORY_DELETE_DATUM_ERROR
    })