import { InternalClientActionType } from '../../actions/internal/types'
import { IDataState } from '../../models/state'
import {
  EventSearchCompleteInternalAction,
  EventSearchInternalAction,
  EventSearchErrorInternalAction
} from '../../actions/internal/eventSearch'

export function eventSearchReducer(
  state: IDataState<any> = { // TODO Pending
    fetching: undefined,
    data: undefined
  },
  action: EventSearchInternalAction |
    EventSearchCompleteInternalAction |
    EventSearchErrorInternalAction
): IDataState<any> { // TODO Pending
  switch (action.type) {
    case InternalClientActionType.EVENT_SEARCH_COMPLETE:
      return {
        fetching: false,
        data: action.eventSearchResult
      }
    case InternalClientActionType.EVENT_SEARCH:
      return {
        ...state,
        fetching: true
      }
    default:
      return state
  }
}