import { InternalClientActionType } from '../../actions/internal/types'
import { IDataState } from '../../models/state'
import {
  DeleteDatumCompleteInternalAction,
  DeleteDatumInternalAction,
  DeleteDatumErrorInternalAction
} from '../../actions/internal/deleteDatum'

export function deleteDatumReducer(
  state: IDataState<any> = { // TODO Pending
    fetching: undefined,
    data: undefined
  },
  action: DeleteDatumInternalAction |
    DeleteDatumCompleteInternalAction |
    DeleteDatumErrorInternalAction
): IDataState<any> { // TODO Pending
  switch (action.type) {
    case InternalClientActionType.DELETE_DATUM_COMPLETE:
      return {
        fetching: false,
        data: action.deleteDatumResult
      }
    case InternalClientActionType.DELETE_DATUM:
      return {
        ...state,
        fetching: true
      }
    default:
      return state
  }
}