import React from 'react'
import { Route, Redirect, RouteProps } from 'react-router'
import { Component } from 'react'
import { connect } from 'react-redux'
import { State } from '../models'

export const renderComponent = (
  props: RouteProps
) => (<Route {...props} />)

 export const renderRedirect = (
   props: RouteProps,
   from = props.location.pathname + props.location.search
  ) => (<Redirect
     to={{
       pathname: '/login',
       state: {
         from: encodeURIComponent(from)
       }
     }} />)

interface Props extends RouteProps {
  authenticated: boolean
}

class ProtectedRouteComponent extends Component<Props> {
  render() {
    const {
      authenticated,
      ...rest
    } = this.props
    const rend = authenticated ? (
      renderComponent(rest)
    ) : ( renderRedirect(rest))

    return rend
  }
}

export const ProtectedRoute = connect((state: State): Props => ({
  authenticated: state.app.authenticated
}))(ProtectedRouteComponent)