import { Transerializable } from '@athlinks/get-or'

export interface IPlatform {
  id: string,
  metadataEndpoint?: string
  name: string
}

export interface IPlatformsResult {
  platforms: Array<IPlatform>
}

export interface IPlatformsState {
  errorMessage: string,
  isLoading: boolean,
  result: IPlatformsResult
}

export interface IPlatformsState {
  errorMessage: string,
  isLoading: boolean,
  result: IPlatformsResult
}

export class PlatformsState extends Transerializable
  implements IPlatformsState {
  errorMessage: string = ''
  isLoading: boolean = false
  result: IPlatformsResult = {
    platforms: []
  }
}