import {
  ExternalAction,
  ExternalErrorAction,
  QueryArgs
} from '../../actions'
import { DirectoryEventActionTypes } from './types'
import { IExternalActionCreator } from '../../reducers/external/actionMapper'
import {
  CreateDatumCompleteInternalAction,
  CreateDatumErrorInternalAction
} from '../internal/createDatum'

export interface CreateDatumArgs extends QueryArgs {
  platform: string,
  type: string,
  id: string,
  parent: string,
  token: string
}

export interface CreateDatumAction
  extends ExternalAction<CreateDatumArgs> {
  type: DirectoryEventActionTypes.DIRECTORY_CREATE_DATUM
}

export const createDatum =
  (args: CreateDatumArgs): CreateDatumAction => ({
    args,
    type: DirectoryEventActionTypes.DIRECTORY_CREATE_DATUM
  })

export interface CreateDatumCompleteAction
  extends ExternalAction<CreateDatumArgs> {
    type: DirectoryEventActionTypes.DIRECTORY_CREATE_DATUM_COMPLETE,
    createDatumResult: any // TODO Pending
}

export const createDatumComplete:
  IExternalActionCreator<CreateDatumCompleteAction,
  CreateDatumCompleteInternalAction> = (
    action: CreateDatumCompleteInternalAction
  ): CreateDatumCompleteAction => ({
    ...action,
    type: DirectoryEventActionTypes.DIRECTORY_CREATE_DATUM_COMPLETE
  })

export interface CreateDatumErrorAction
  extends ExternalErrorAction<CreateDatumArgs> {
  type: DirectoryEventActionTypes.DIRECTORY_CREATE_DATUM_ERROR
}

export const createDatumError:
  IExternalActionCreator<CreateDatumErrorAction,
  CreateDatumErrorInternalAction> = (
    action: CreateDatumErrorInternalAction
  ): CreateDatumErrorAction => ({
    ...action,
    type: DirectoryEventActionTypes.DIRECTORY_CREATE_DATUM_ERROR
  })