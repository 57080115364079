import { getEnvironment, Environment } from '../util/getEnvironment'
import stageConfig from './config/keycloak-stage'
import prodConfig from './config/keycloak-prod'

let keycloakConfig = undefined

const configMap = {
  [`${Environment.LOCAL}`]: stageConfig,
  [`${Environment.STAGE}`]: stageConfig,
  [`${Environment.PROD}`]: prodConfig
}

export const findKeycloakFile = (
  environment: Environment
) => configMap[environment] || stageConfig

export const getKeycloakConfig = (
  environment: Environment = getEnvironment()
) => {
  if (!keycloakConfig) {
    keycloakConfig = findKeycloakFile(environment)
  }
  return keycloakConfig
}

export const resetKeycloakConfig = () => {
  keycloakConfig = undefined
}
