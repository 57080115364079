export enum AppActionTypes {
  SET_MODAL_OPEN = 'SET_MODAL_OPEN',
  SET_MODAL_INPUT = 'SET_MODAL_INPUT',
  SET_ACTIVE_EVENTS = 'SET_ACTIVE_EVENTS'
}

export enum AuthActionTypes {
  AUTH = 'AUTH_START',
  AUTH_COMPLETE = 'AUTH_START_COMPLETE',
  UNAUTH = 'UNAUTH',
  UNAUTH_COMPLETE = 'UNAUTH_COMPLETE',
  AUTH_ERROR = 'AUTH_ERROR',
  TOKEN_REFRESH = 'TOKEN_REFRESH',
  TOKEN_REFESH_COMPLETE = 'TOKEN_REFRESH_COMPLETE'
}

export enum DirectorySearchDataActionTypes {
  DIRECTORY_CHANGE_PLATFORM = 'DIRECTORY_CHANGE_PLATFORM',
  DIRECTORY_CHANGE_TYPE = 'DIRECTORY_CHANGE_TYPE',
  DIRECTORY_CHANGE_ID = 'DIRECTORY_CHANGE_ID',
  DIRECTORY_CLEAR_EVENT = 'DIRECTORY_CLEAR_EVENT'
}

export enum InternalLinkingDataActionTypes {
  CLEAR_LINKING_ACTIVE_SELECTION = 'CLEAR_LINKING_ACTIVE_SELECTION'
}

export enum DirectoryUserPrefsActionTypes {
  DIRECTORY_SET_USER_PREFS = 'DIRECTORY_SET_USER_PREFS'
}