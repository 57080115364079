import {
  ExternalAction,
  ExternalErrorAction,
  QueryArgs
} from '../../actions'
import { DirectoryEventActionTypes } from './types'
import { IExternalActionCreator } from '../../reducers/external/actionMapper'
import {
  EventSearchCompleteInternalAction,
  EventSearchErrorInternalAction
} from '../internal/eventSearch'

export interface EventSearchArgs extends QueryArgs {
  platform: string,
  type: string, // TODO: change this to a concrete type defined in the api
  id: string,
  pullMetadata: boolean,
  token: string
}

export interface EventSearchAction
  extends ExternalAction<EventSearchArgs> {
  type: DirectoryEventActionTypes.DIRECTORY_EVENT_SEARCH
}

export const eventSearch =
  (args: EventSearchArgs): EventSearchAction => ({
    args,
    type: DirectoryEventActionTypes.DIRECTORY_EVENT_SEARCH
  })

export interface EventSearchCompleteAction
  extends ExternalAction<EventSearchArgs> {
    type: DirectoryEventActionTypes.DIRECTORY_EVENT_SEARCH_COMPLETE,
    eventSearchResult: any // TODO Pending
}

export const eventSearchComplete:
  IExternalActionCreator<EventSearchCompleteAction,
  EventSearchCompleteInternalAction> = (
    action: EventSearchCompleteInternalAction
  ): EventSearchCompleteAction => ({
    ...action,
    type: DirectoryEventActionTypes.DIRECTORY_EVENT_SEARCH_COMPLETE
  })

export interface EventSearchErrorAction
  extends ExternalErrorAction<EventSearchArgs> {
  type: DirectoryEventActionTypes.DIRECTORY_EVENT_SEARCH_ERROR
}

export const eventSearchError:
  IExternalActionCreator<EventSearchErrorAction,
  EventSearchErrorInternalAction> = (
    action: EventSearchErrorInternalAction
  ): EventSearchErrorAction => ({
    ...action,
    type: DirectoryEventActionTypes.DIRECTORY_EVENT_SEARCH_ERROR
  })