import fetch from 'node-fetch'
import { ApolloLink } from 'apollo-boost'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import ApolloClient from 'apollo-client'
import { graphUrl } from '../config'
import { TokenedArgs } from '../../src/actions/index'

const httpLink = createHttpLink({
  uri: graphUrl,
  fetch: fetch
})

export const buildClient = (args: TokenedArgs) => {
  const token = args.token
  const authLink = new ApolloLink((operation, forward) => {
    operation.setContext({
      headers: {
        Authorization: token ? `Bearer ${token}` : ''
      }
    })
    return forward(operation)
  })

  return new ApolloClient({
    cache: new InMemoryCache(),
    defaultOptions: {
      query: {
        fetchPolicy: 'no-cache'
      }
    },
    link: authLink.concat(httpLink)
  })
}
