import React, { Component } from 'react'
import { Grid } from 'react-foundation'
import { connect, Dispatch } from 'react-redux'
import { Redirect } from 'react-router'

import { DirectoryState } from '../../models/Directory'
import { App } from '../../models/App'
import { PlatformsState } from '../../models/Platforms'
import { HistoryProps } from '../../models/HistoryProps'
import { State } from '../../models'
import { compareReg } from '../../actions/external/compareReg'
import { Routes } from '../../util/routes'
import { CompareRegState, IRegMatch, ICompareRegResult } from '../../models/CompareRegState'
import { Loading } from '../../components/loading/Loading'
import Header from '../../components/header/Header'

import './CompareRegPage.module.scss'

interface IProps {
  compareRegState: CompareRegState
  directoryState: DirectoryState
  app: App
  dispatch: Dispatch
  platformData: PlatformsState
}

class CompareRegComponent extends Component<IProps & HistoryProps> {

  parseSearch(search: string) {
    const params = new URLSearchParams(search)
    return {
      platform: params.get('platform'),
      type: params.get('type'),
      id: params.get('id')
    }
  }

  componentDidMount() {
    const {
      app: {
        token
      },
      directoryState: {
        searchData:
        {
          datumType,
          id,
          platform
        }
      },
      dispatch
    } = this.props

    if (id && platform.id && datumType) {
      dispatch(
        compareReg({
          id,
          platform: platform.id,
          type: datumType.value,
          token
        })
      )
    }
  }

  getMatches(item: ICompareRegResult) {
    const matches: IRegMatch[] = []
    if (item.RunSignUp.length > 0) {
      item.RunSignUp.forEach(RunSignUp => {
        matches.push({
          Athlinks: item.Athlinks.find(x =>
            x.athlete.firstName === `${RunSignUp.athlete.firstName} ${RunSignUp.athlete.lastName}`
          ),
          CTLive: item.CTLive.find(x =>
            x.athlete.firstName === RunSignUp.athlete.firstName
            && x.athlete.lastName === RunSignUp.athlete.lastName
          ),
          RunSignUp
        })
      })
    }
    else { // probably not linked to RSU
      item.CTLive.forEach(CTLive => {
        matches.push({
          Athlinks: item.Athlinks.find(x =>
            x.athlete.firstName === `${CTLive.athlete.firstName} ${CTLive.athlete.lastName}`
          ),
          CTLive
        })
      })
    }
    return matches
  }

  render() {
    const {
      app: {
        token
      },
      compareRegState,
      directoryState: {
        searchData:
        {
          datumType,
          id,
          platform
        }
      },
      dispatch
    } = this.props

    /*
      If Search Data is not set,
      redirect to Directory page
    */
    if (!platform.id || !datumType.value || !id) {
      return <Redirect to={{
        pathname: Routes.PARTNER_SYNC,
        search: window.location.search
      }} />
    }

    return (
      <div className='auto'>
        <Header title={'COMPARE REGISTRATIONS'} subTitle={'PartnerSync'} />
        <div className='refresh-controls'>
          <input type='button'
            onClick={() => {
              dispatch(
                compareReg({
                  id,
                  platform: platform.id,
                  type: datumType.value,
                  token
                })
              )
            }}
            value='Refresh' />
        </div>
        {!compareRegState || compareRegState.isLoading
          ? <Loading />
          : <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
            {compareRegState.result.map((item, itemKey) => (
              <Grid className='reg-table' key={itemKey} style={{ display: 'flex', alignContent: 'flex-start' }}>
                <h2>{item.regChoice.name}</h2>
                <table>
                  <thead>
                    <tr>
                      <th></th>
                      <th>RunSignUp</th>
                      <th>CTLive</th>
                      <th>Athlinks</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.getMatches(item).map((match, matchKey) => {
                      const maybeAthlete = (
                        match.RunSignUp || match.CTLive || match.Athlinks || {
                          athlete: {
                            firstName: '???',
                            lastName: '???'
                          }
                        }
                      ).athlete
                      return (
                        <tr key={matchKey}>
                          <td>{maybeAthlete.firstName + ' ' + maybeAthlete.lastName}</td>
                          {match.RunSignUp ? <td>&#10004;</td> : <td>X</td>}
                          {match.CTLive ? <td>&#10004;</td> : <td>X</td>}
                          {match.Athlinks ? <td>&#10004;</td> : <td>X</td>}
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </Grid>
            ))}
          </div>
        }
      </div>
    )
  }
}

export default connect(
  (state: State): IProps => ({
    compareRegState: state.compareRegState,
    directoryState: state.directoryState,
    app: state.app,
    dispatch: undefined,
    platformData: state.platformData
  })
)(CompareRegComponent)
