import React, { Component } from 'react'
import { Cell, Grid, GridContainer } from 'react-foundation'
import ServicesLogo from '../serviceslogo/ServicesLogo'
import { Link } from 'react-router-dom'
import { Routes } from '../../util/routes'

import styles from './SideBar.module.scss'

export default class SideBar extends Component {
  render() {
    return (
      <GridContainer>
        <Grid gridFrame='medium' className={'grid-y ' + styles.container}>
          <Cell className={styles.logoContainer}>
            <ServicesLogo height='4' width='10' units='rem' />
          </Cell>
          <Cell>
            <Grid>
              <Cell className={styles.admin}>Admin</Cell>
              <Cell className={styles.navItem}>
                <Link to={Routes.PARTNER_SYNC}>PartnerSync</Link>
              </Cell>
            </Grid>
          </Cell>
        </Grid>
      </GridContainer>
    )
  }
}
