import React, { ReactNode } from 'react'
import { Trans } from 'react-i18next'
import './DirectoryActionButton.scss'

interface Props {
  className?: string,
  label: string,
  onClick: () => void,
  icon: ReactNode,
  disabled?: Boolean,
  primary?: Boolean
}

const DirectoryActionButton: React.SFC<Props> = ({
  className,
  label,
  icon,
  onClick,
  disabled,
  primary
}) =>
  <div
    onClick={disabled ? () => null : onClick}
    className={`action-button-wrapper ${primary ? 'primary ' : ''}${disabled ? 'disabled ' : ''}${className || ''}`}
  >
    {icon}
    <div className='action-button-text'>
      <Trans>{label}</Trans>
    </div>
  </div>

export default DirectoryActionButton