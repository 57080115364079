import { Transerializable } from '@athlinks/get-or'
import { IEvent } from '@athlinks/admin-events-model'

export interface IEventSearchResponse {
  eventSearch: {
    results: Array<IEvent>,
    totalCount: number
  }
}

export interface IEventSearchResult {
  totalCount: number,
  results: Array<IEvent>
}

export interface IEventSearchState {
  errorMessage: string,
  isLoading: boolean,
  result: IEventSearchResult
}

export class EventSearchState extends Transerializable
  implements IEventSearchState {
  errorMessage: string = ''
  isLoading: boolean = false
  result: IEventSearchResult = {
    results: [],
    totalCount: 0
  }
}