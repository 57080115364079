import { linkEntries } from '../actions/external/linkEntries'
import { Dispatch } from 'redux'

export interface OnDragEndArgs {
  destination: {
    droppableId: string
  },
  draggableId: string,
  source: {
    droppableId: string
  }
}
export const onDragEnd = (e: OnDragEndArgs,
  {
    dispatch,
    entryId,
    linkId,
    linkParentId,
    token
  }: {
    dispatch: Dispatch
    entryId: string,
    linkId: string,
    linkParentId: string,
    token: string
  }
) => {
  if (!e.destination) {
    return
  }

  if (e.destination.droppableId === e.source.droppableId) {
    return
  }

  console.log('linking:', linkId)
  console.log('entryId:', entryId)
  console.log('linkParentId:', linkParentId)
  if (!entryId || !linkId || !linkParentId) {
    console.warn('invalid args!')
    return
  }

  dispatch(
    linkEntries({
      entryId,
      linkId,
      linkParentId,
      isUnlink: false,
      token
    })
  )
}
