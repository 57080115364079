import { checkAction, actionPayloadOnly, overish } from '@athlinks/lib-redux-utils'
import { flow } from 'lodash'

import { App, IApp } from '../models/App'
import { AppActionTypes, AuthActionTypes } from '../actions/types'
import {
  UnauthAction,
  UnauthActionComplete,
  AuthActionError,
  AuthAction,
  AuthActionComplete,
  TokenRefreshActionComplete
} from '../actions/auth'
import {
  SetModalInputAction,
  SetModalOpenAction
} from '../actions/app'

type ActionTypes =
  AuthAction |
  AuthActionComplete |
  UnauthAction |
  UnauthActionComplete |
  AuthActionError |
  TokenRefreshActionComplete |
  SetModalOpenAction |
  SetModalInputAction

const isAuthComplete = checkAction(AuthActionTypes.AUTH_COMPLETE)
const isUnauthComplete = checkAction(AuthActionTypes.UNAUTH_COMPLETE)
const isTokenRefreshed = checkAction(AuthActionTypes.TOKEN_REFESH_COMPLETE)
const isAuthError = checkAction(AuthActionTypes.AUTH_ERROR)
const setModalOpenRule = checkAction(AppActionTypes.SET_MODAL_OPEN)
const setModalInputRule = checkAction(AppActionTypes.SET_MODAL_INPUT)
const setActiveEventsRule = checkAction(AppActionTypes.SET_ACTIVE_EVENTS)

const emptyAuth = (
  state: IApp
) => (
  action
) => App.build({
  ...state,
  token: undefined,
  authenticated: false,
  exp: undefined,
  sub: undefined
}) as IApp

const completeAuth = (
  state: IApp
) => flow([
  actionPayloadOnly,
  (keycloakInfo) => App.build({
    ...state,
    ...keycloakInfo
  })
])

const printError = flow([
  actionPayloadOnly,
  (err) => {
    console.error(err)
    return err
  }
])

const setModalOpen = (
  state: IApp
) => flow([
  actionPayloadOnly,
  (modalOpen) => App.build({
    ...state,
    ...modalOpen
  })
])

const setModalInput = (
  state: IApp
) => flow([
  actionPayloadOnly,
  (modalInput) => App.build({
    ...state,
    ...modalInput
  })
])

const setActiveEvents = (
  state: IApp
) => flow([
  actionPayloadOnly,
  ({ activeEvents }) => App.build({
    ...state,
    activeEvents
  })
])

export function appReducers(
  state: IApp = new App(),
  action: ActionTypes
): IApp {
  const removeAuthFromState = emptyAuth(state)
  return overish<ActionTypes, IApp>(state,
    {
      rule: isAuthComplete,
      processor: completeAuth(state)
    },
    {
      rule: isUnauthComplete,
      processor: removeAuthFromState
    },
    {
      rule: isTokenRefreshed,
      processor: completeAuth(state) // treat the refresh the same
    },
    {
      rule: isAuthError,
      processor: flow(
        [printError,
          removeAuthFromState]
      )
    },
    {
      rule: setModalOpenRule,
      processor: setModalOpen(state)
    },
    {
      rule: setModalInputRule,
      processor: setModalInput(state)
    },
    {
      rule: setActiveEventsRule,
      processor: setActiveEvents(state)
    }
  )(action)
}