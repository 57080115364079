import React from 'react'
import { Grid, Cell } from 'react-foundation'
import { Trans } from 'react-i18next'
import { connect, Dispatch } from 'react-redux'
import { eventSearchQueryBuilder } from '../../../epics/util/queries'
import directQuery from '../../../util/directQuery'
import Button from '../../shared/Button'
import { isEmpty } from 'lodash'
import './AddEvent.scss'
import { Dropdown, IChoice } from '../../dropdown/Dropdown'
import { IPlatform } from '../../../models/Platforms'
import { App } from '../../../models/App'
import { State } from '../../../models'
import { setModalInput } from '../../../actions/app'

interface AEProps {
  isMaster: boolean
  masterEventName: string
  masterId: string
  platforms: IPlatform[],
  platformsToExclude: string[]
}

interface AERProps {
  app: App
  dispatch: Dispatch
}

const AddEventBodyComponent: React.SFC<AEProps & AERProps> = (props) => {
  const {
    app,
    dispatch,
    isMaster,
    masterEventName,
    masterId,
    platforms,
    platformsToExclude
  } = props

  const [id, setId] = React.useState<string>('')
  const $platforms = platforms.filter(i => !platformsToExclude?.includes(i.name))
  const [event, setEvent] = React.useState<any>(undefined)
  const [input, setInput] = React.useState<any>(undefined)
  const [isLoading, setIsLoading] = React.useState(false)
  const [hasSearched, setHasSearched] = React.useState(false)
  const [selectedPlatform, setSelectedPlatform] = React.useState<IPlatform>($platforms[0])

  const searchInput = React.useRef(null)

  React.useEffect(() => {
    if (event && input) {
      dispatch(setModalInput(input))
    }
    else {
      dispatch(setModalInput(undefined))
    }
  }, [event, input, dispatch])

  const onSearchResponse = (response, _input) => {
    setEvent(response.data?.eventSearch)
    setIsLoading(false)
    setHasSearched(true)
    setInput({ masterId, ..._input })
  }

  const onSearchClick = () => {
    const modalInput = {
      platform: selectedPlatform.id,
      id: searchInput.current.value,
      type: isMaster ? 'MasterEvent' : 'Event',
      parent: masterId
    }

    const queryArgs = {
      ...modalInput,
      token: app.token,
      pullMetadata: true
    }

    setIsLoading(true)
    setEvent(undefined)
    setHasSearched(false)

    directQuery(queryArgs, eventSearchQueryBuilder, (r) =>
      onSearchResponse(r, modalInput)
    )
  }

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && searchInput.current.value) {
      onSearchClick()
    }
  }

  const renderResponse = () => {
    if ((!event && !hasSearched) || isLoading) {
      const message = isLoading
        ? 'Searching...'
        : isMaster
          ? 'Add partner details and ID to add a master event to this PartnerSync entry'
          : 'Add partner details and ID to add an event to this PartnerSync entry'

      return (
        <Grid className='grid-x'>
          <Cell className='auto add-event-searching'>
            <Trans>{message}</Trans>
          </Cell>
        </Grid>
      )
    }

    if (!event && hasSearched) {
      return (
        <Grid className='grid-x'>
          <Cell className='auto add-event-searching'>
            <Trans>
              {isMaster ? 'No Master Event Found' : 'No Event Found'}
            </Trans>
          </Cell>
        </Grid>
      )
    }

    const eventName = event.name

    return (
      <div>
        <Grid className='grid-x add-event-response'>
          <Cell className='auto'>
            <div className='add-event-response-name'>{eventName}</div>
            {/* <div className='add-event-response-header'>
              <span>{eventDate}</span>
              <span className='date-location-divider'>|</span>
              <span>{eventLocation}</span>
            </div> */}
          </Cell>
          {isMaster ? (
            <Cell large={1} className='add-event-response-column'>
              <div className='add-event-response-header'>
                <Trans>Events</Trans>
              </div>
              <div className='add-event-response-data'>{0}</div>
            </Cell>
          ) : null}
          <Cell large={1} className='add-event-response-column'>
            <div className='add-event-response-header'>
              <Trans>Reg. Choices</Trans>
            </div>
            <div className='add-event-response-data'>{0}</div>
          </Cell>
          <Cell large={1} className='add-event-response-column'>
            <div className='add-event-response-header'>
              <Trans>Races</Trans>
            </div>
            <div className='add-event-response-data'>{0}</div>
          </Cell>
        </Grid>
        <Grid className='grid-x add-event-message'>
          <Cell>
            <Trans>
              This event will be added to the
              <span className='add-event-message-event-name'>{` ${masterEventName} `}</span>
              entry
            </Trans>
          </Cell>
        </Grid>
      </div>
    )
  }

  const searchDisabled = isEmpty(id) || isEmpty(selectedPlatform)

  return (
    <Grid className='grid-y add-event-body'>
      <Grid className='grid-x add-event-search'>
        <Cell className='shrink'>
          <Dropdown
            title={selectedPlatform.name}
            translateTitle={isEmpty(selectedPlatform)}
            choices={
              $platforms.map((p) => {
                return {
                  label: p.name,
                  onClick: () => setSelectedPlatform(p)
                } as IChoice
              })
            }
            buttonClass={'add-event-dropdown'}
            menuClass={'add-event-dropdown-menu'}
            disabled={isEmpty(platforms)}
          />
        </Cell>
        <Cell className='shrink'>
          <input
            ref={searchInput}
            disabled={isEmpty(selectedPlatform)}
            className={'add-event-input'}
            placeholder={isMaster ? 'Master Event ID' : 'Event ID'}
            spellCheck={false}
            autoComplete='off'
            autoCorrect='off'
            value={id}
            onKeyDown={onKeyDown}
            onChange={() => setId(searchInput.current.value)}
          />
        </Cell>
        <Cell className='shrink'>
          <Button
            className='primary add-event-search-button'
            disabled={searchDisabled || isLoading}
            label={'Search'}
            onClick={onSearchClick}
          />
        </Cell>
      </Grid>
      {renderResponse()}
    </Grid>
  )
}

export const AddEventBody = connect(
  (state: State): AERProps => ({
    app: state.app,
    dispatch: undefined
  })
)(AddEventBodyComponent)
