import { InternalClientActionType } from './types'
import {
  InternalAction,
  DataAction,
  DataActionCreator,
  InternalErrorAction,
  InternalDataErrorCreator
} from '../../actions'
import {
  LinkEntriesArgs,
  LinkEntriesAction
} from '../external/linkEntries'
import { IInternalActionCreator } from '../../reducers/external/actionMapper'

export interface LinkEntriesInternalAction
  extends InternalAction, DataAction<LinkEntriesArgs> {
  type: InternalClientActionType.LINK_ENTRIES
}

export const linkEntriesInternal:
  IInternalActionCreator<LinkEntriesInternalAction, LinkEntriesAction> = (
    action: LinkEntriesAction
  ): LinkEntriesInternalAction => ({
    ...action,
    type: InternalClientActionType.LINK_ENTRIES
  })

export interface LinkEntriesCompleteInternalAction
  extends InternalAction, DataAction<LinkEntriesArgs> {
  type: InternalClientActionType.LINK_ENTRIES_COMPLETE,
  linkEntriesResult: any // TODO Pending
}

export const linkEntriesCompleteInternal:
  DataActionCreator<LinkEntriesCompleteInternalAction, LinkEntriesArgs> = (
    linkEntriesResult: any, // TODO Pending
    args: LinkEntriesArgs
  ): LinkEntriesCompleteInternalAction => {
    if (!linkEntriesResult.linkEntries.success) {
      console.error(linkEntriesResult.linkEntries.message)
      throw new Error(linkEntriesResult.linkEntries.message || 'unspecified')
    }

    return ({
      type: InternalClientActionType.LINK_ENTRIES_COMPLETE,
      linkEntriesResult,
      args
    })
  }

export interface LinkEntriesErrorInternalAction
  extends InternalErrorAction<LinkEntriesArgs>, DataAction<LinkEntriesArgs> {
  type: InternalClientActionType.LINK_ENTRIES_ERROR
}

export const linkEntriesErrorInternal:
  InternalDataErrorCreator<LinkEntriesErrorInternalAction, LinkEntriesArgs> = (
    args: LinkEntriesArgs,
    error: any
  ): LinkEntriesErrorInternalAction => {
    return {
      type: InternalClientActionType.LINK_ENTRIES_ERROR,
      error,
      args
    }
  }