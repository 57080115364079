import React, { Component } from 'react'
import { Button } from '@athlinks/component-library'
import { Cell, Grid, GridContainer } from 'react-foundation'
import { Trans } from 'react-i18next'

import Footer from '../../components/footer/Footer'
import Header from '../../components/header/Header'
import SideBar from '../../components/sidebar/SideBar'

import styles from './Error.module.scss'

interface Props {
  history: {
    goBack: () => void
  }
}

export default class Error extends Component<Props> {
  render() {
    return (
      <Grid>
        <Cell className={'sidebar'} medium={2}>
          <SideBar>
            <Footer/>
          </SideBar>
        </Cell>
        <Cell className={'auto '}>
          <GridContainer className={'full'}>
            <Grid>
              <Cell>
                <Header />
              </Cell>
            </Grid>
          </GridContainer>
          <div className={'grid-container-wrapper'}>
            <div className={'grid-container'}>
              <div className={styles['main-text']}>404</div>
              <div className={styles['sub-text']}>
                <Trans>This page does not exist</Trans>
              </div>
              <Button
                color={'dark-blue'}
                maxWidth={'15rem'}
                onClick={() => this.props.history.goBack()}
                text={'GO BACK'}/>
            </div>
          </div>
        </Cell>
      </Grid>
    )
  }
}