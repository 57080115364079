import { ActionsObservable, ofType } from 'redux-observable'
import {
  Observable,
  from,
  of
} from 'rxjs'
import {
  flatMap,
  catchError
} from 'rxjs/operators'

import {
  LinkEntriesInternalAction,
  LinkEntriesCompleteInternalAction,
  LinkEntriesErrorInternalAction,
  linkEntriesCompleteInternal,
  linkEntriesErrorInternal
} from '../actions/internal/linkEntries'
import { InternalClientActionType } from '../actions/internal/types'
import { waitForMutationResponse } from './util'
import { linkEntriesMutationBuilder } from './util/mutations'

export function linkEntriesEpic(
  actions$: ActionsObservable<LinkEntriesInternalAction>
): Observable<LinkEntriesCompleteInternalAction |
  LinkEntriesErrorInternalAction> {
    return actions$.pipe(
      ofType(InternalClientActionType.LINK_ENTRIES),
      flatMap(action$ => {
        const { args } = action$

        return from(
          waitForMutationResponse(
            args,
            linkEntriesMutationBuilder,
            linkEntriesCompleteInternal,
            linkEntriesErrorInternal
          )
        ).pipe(
          catchError(err => of(err))
        )
      }),
      catchError(err => of(err))
    )
  }