
export const getItemStyle = (isDragging, draggableStyle) => ({
  ...draggableStyle,
  background: isDragging ? 'orange' : 'transparent',
  userSelect: 'none'
})

export const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'lightblue' : 'transparent'
})
