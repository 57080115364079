import { ActionsObservable, ofType } from 'redux-observable'
import {
  Observable,
  from,
  of
} from 'rxjs'
import {
  flatMap,
  catchError
} from 'rxjs/operators'

import {
  DeleteDatumInternalAction,
  DeleteDatumCompleteInternalAction,
  DeleteDatumErrorInternalAction,
  deleteDatumCompleteInternal,
  deleteDatumErrorInternal
} from '../actions/internal/deleteDatum'
import { InternalClientActionType } from '../actions/internal/types'
import { waitForMutationResponse } from './util'
import { deleteDatumMutationBuilder } from './util/mutations'

export function deleteDatumEpic(
  actions$: ActionsObservable<DeleteDatumInternalAction>
): Observable<DeleteDatumCompleteInternalAction |
  DeleteDatumErrorInternalAction> {
    return actions$.pipe(
      ofType(InternalClientActionType.DELETE_DATUM),
      flatMap(action$ => {
        const { args } = action$

        return from(
          waitForMutationResponse(
            args,
            deleteDatumMutationBuilder,
            deleteDatumCompleteInternal,
            deleteDatumErrorInternal
          )
        ).pipe(
          catchError(err => of(err))
        )
      }),
      catchError(err => of(err))
    )
  }