import {
  ExternalAction,
  ExternalErrorAction,
  QueryArgs
} from '..'
import { DirectoryCompareActionTypes } from './types'
import { IExternalActionCreator } from '../../reducers/external/actionMapper'
import {
  CompareRegCompleteInternalAction,
  CompareRegErrorInternalAction
} from '../internal/compareReg'
import { ICompareRegResponse } from '../../models/CompareRegState'

export interface CompareRegArgs extends QueryArgs {
  platform: string,
  type: string,
  id: string,
  token: string
}

export interface CompareRegAction
  extends ExternalAction<CompareRegArgs> {
  type: DirectoryCompareActionTypes.COMPARE_REG
}

export const compareReg = (args: CompareRegArgs): CompareRegAction => ({
  type: DirectoryCompareActionTypes.COMPARE_REG,
  args
})

export interface CompareRegCompleteAction
  extends ExternalAction<CompareRegArgs> {
  type: DirectoryCompareActionTypes.COMPARE_REG_COMPLETE,
  compareRegResult: ICompareRegResponse
}

export const compareRegComplete:
  IExternalActionCreator<CompareRegCompleteAction,
    CompareRegCompleteInternalAction> = (
      action: CompareRegCompleteInternalAction
    ):
    CompareRegCompleteAction => ({
      ...action,
      type: DirectoryCompareActionTypes.COMPARE_REG_COMPLETE
    })

export interface CompareRegErrorAction
  extends ExternalErrorAction<CompareRegArgs> {
  type: DirectoryCompareActionTypes.COMPARE_REG_ERROR
}

export const compareRegError:
  IExternalActionCreator<CompareRegErrorAction,
    CompareRegErrorInternalAction> = (
      action: CompareRegErrorInternalAction
    ): CompareRegErrorAction => ({
      ...action,
      type: DirectoryCompareActionTypes.COMPARE_REG_ERROR
    })
