import { mergeMap } from 'rxjs/operators'
import { Observable } from 'rxjs'
import {
  DirectoryEventActionTypes
} from '../../actions/external/types'
import {
  eventSearch
} from '../../actions/external/eventSearch'
import { StateObservable, ofType } from 'redux-observable'
import { IState } from '../../models/index'

import {
    DirectoryDeleteDatumCompleteAction,
    DirectoryEventSearchAction,
    DirectoryEventRefreshCompleteAction
  } from '../../actions/directory'

type IRefreshActions = DirectoryDeleteDatumCompleteAction |
  DirectoryEventRefreshCompleteAction

export function refreshUpdatedEvent(
  actions$: Observable<IRefreshActions>,
  state$: StateObservable<IState>
): Observable<DirectoryEventSearchAction> {
  return actions$.pipe(
    ofType(
      DirectoryEventActionTypes.DIRECTORY_DELETE_DATUM_COMPLETE,
      DirectoryEventActionTypes.DIRECTORY_EVENT_REFRESH_COMPLETE
    ),
    mergeMap(async (action$: IRefreshActions) => {
      const {
        app,
        directoryState: {
          searchData: {
            platform,
            datumType,
            id
          }
        }
      } = state$.value
      const searchAction = eventSearch({
        platform: platform.id,
        type: datumType.value,
        id,
        pullMetadata: true,
        token: app.token
      })
      return searchAction
    })
  )
}