
export enum DirectoryCompareActionTypes {
  COMPARE_REG = 'COMPARE_REG',
  COMPARE_REG_COMPLETE = 'COMPARE_REG_COMPLETE',
  COMPARE_REG_ERROR = 'COMPARE_REG_ERROR'
}

export enum DirectoryEventActionTypes {
  DIRECTORY_CREATE_DATUM = 'DIRECTORY_CREATE_DATUM',
  DIRECTORY_CREATE_DATUM_COMPLETE = 'DIRECTORY_CREATE_DATUM_COMPLETE',
  DIRECTORY_CREATE_DATUM_ERROR = 'DIRECTORY_CREATE_DATUM_ERROR',
  DIRECTORY_DELETE_DATUM = 'DIRECTORY_DELETE_DATUM',
  DIRECTORY_DELETE_DATUM_COMPLETE = 'DIRECTORY_DELETE_DATUM_COMPLETE',
  DIRECTORY_DELETE_DATUM_ERROR = 'DIRECTORY_DELETE_DATUM_ERROR',
  DIRECTORY_EVENT_SEARCH = 'DIRECTORY_EVENT_SEARCH',
  DIRECTORY_EVENT_SEARCH_COMPLETE = 'DIRECTORY_EVENT_SEARCH_COMPLETE',
  DIRECTORY_EVENT_SEARCH_ERROR = 'DIRECTORY_EVENT_SEARCH_ERROR',
  DIRECTORY_EVENT_REFRESH = 'DIRECTORY_EVENT_REFRESH',
  DIRECTORY_EVENT_REFRESH_COMPLETE = 'DIRECTORY_EVENT_REFRESH_COMPLETE',
  DIRECTORY_EVENT_REFRESH_ERROR = 'DIRECTORY_EVENT_REFRESH_ERROR'
}

export enum DirectoryPlatformsActionTypes {
  DIRECTORY_PLATFORMS = 'DIRECTORY_PLATFORMS',
  DIRECTORY_PLATFORMS_COMPLETE = 'DIRECTORY_PLATFORMS_COMPLETE',
  DIRECTORY_PLATFORMS_ERROR = 'DIRECTORY_PLATFORMS_ERROR'
}

export enum DirectoryLinkingActionTypes {
  DIRECTORY_ENTRIES_LINK = 'DIRECTORY_ENTRIES_LINK',
  DIRECTORY_ENTRIES_LINK_COMPLETE = 'DIRECTORY_ENTRIES_LINK_COMPLETE',
  DIRECTORY_ENTRIES_LINK_ERROR = 'DIRECTORY_ENTRIES_LINK_ERROR'
}