import React, { Component } from 'react'
import { Button, Menu, MenuItem } from 'react-foundation'
import { Trans } from 'react-i18next'
import { Link, withRouter, RouteProps } from 'react-router-dom'
import { Routes } from '../../util/routes'
import styles from './Dropdown.module.scss'

export interface IChoice {
  label: string
  onClick?: () => void,
  route?: Routes
}

interface Props extends RouteProps {
  buttonClass: string
  choices: Array<IChoice>
  disabled?: boolean
  menuClass: string
  title: string
  translateTitle: boolean
}

interface DropdownState {
  listOpen: boolean
}

class DropdownComponent extends Component<Props, Partial<DropdownState>> {
  private compRef = React.createRef<HTMLDivElement>()

  constructor(props) {
    super(props)

    this.state = {
      listOpen: false
    }
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClick)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick)
  }

  handleClick = (e) => {
    const { disabled } = this.props
    if (disabled) {
      return null
    }

    if (this.compRef.current && !this.compRef.current.contains(e.target)) { // Outside clicks
      this.setState({
        listOpen: false
      })
    }
    else {
      this.setState(state => ({
        listOpen: !state.listOpen
      }))
    }
  }

  renderClosedDropdown = (
    title: string,
    translateTitle: boolean,
    buttonClass: string,
    disabled: boolean
  ) => (
      <div className={styles.ddMenu} ref={this.compRef}>
        <Button
          className={'small dropdown clear dd-menu-title ' + buttonClass}
          disabled={disabled}
        >
          {
            translateTitle
              ? <Trans>{title}</Trans>
              : title
          }
        </Button>
      </div>
    )

  renderDropdownOption = (
    buttonClass: string,
    choice: IChoice,
    key: number
  ) => {
    const { label, onClick, route } = choice

    if (route) {
      return (
        <MenuItem key={key} className={'dd-menu-item'}>
          <Link
            className={'dd-menu-item ' + buttonClass}
            onClick={onClick}
            to={route}
            key={`link-${key}`}
          >{label}</Link>
        </MenuItem>
      )
    }

    return (
      <MenuItem key={key} onClick={onClick} className={'dd-menu-item'}>
        <div>{label}</div>
      </MenuItem>
    )
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.disabled !== this.props.disabled &&
      this.props.disabled
    ) {
      this.setState({
        listOpen: false
      })
    }
  }

  renderOpenedDropdown = (
    title: string,
    translateTitle: boolean,
    choices: Array<IChoice>,
    buttonClass: string,
    menuClass: string
  ) => (
      <div className={styles.ddMenu} ref={this.compRef}>
        <Button
          className={'small clear dropdown dd-menu-title ' + buttonClass}
        >
          {
            translateTitle
              ? <Trans>{title}</Trans>
              : title
          }
        </Button>
        <Menu className={'small vertical ' + menuClass}>
          {choices.map((choice, idx, arr) => (
            [
              this.renderDropdownOption(buttonClass, choice, idx),
              (idx < arr.length - 1) ? <hr key={'hr' + idx} /> : null
            ]
          ))}
        </Menu>
      </div>
    )

  render() {
    const {
      buttonClass,
      choices,
      disabled,
      menuClass,
      title,
      translateTitle
    } = this.props
    const { listOpen } = this.state
    return listOpen
      ? this.renderOpenedDropdown(title, translateTitle, choices, buttonClass, menuClass)
      : this.renderClosedDropdown(title, translateTitle, buttonClass, disabled)
  }
}

export const Dropdown = withRouter<any, any>(
  DropdownComponent
)