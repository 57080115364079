import * as log from 'loglevel'

export interface ILog extends log.Logger {
  name: string
}

export const LogLevel = log.levels
export const Log = log.getLogger('admin-directory-client') as ILog

Log.setDefaultLevel(LogLevel.INFO)

export function setLogLevel(logLevel: log.LogLevelDesc) {
  Log.setLevel(logLevel)
}
