import { InternalClientActionType } from './types'
import {
  InternalAction,
  DataAction,
  DataActionCreator,
  InternalErrorAction,
  InternalDataErrorCreator
} from '..'
import {
  CompareRegArgs,
  CompareRegAction
} from '../external/compareReg'
import { IInternalActionCreator } from '../../reducers/external/actionMapper'
import { ICompareRegResponse } from '../../models/CompareRegState'

export interface CompareRegInternalAction
  extends InternalAction, DataAction<CompareRegArgs> {
  type: InternalClientActionType.COMPARE_REG
}

export const compareRegInternal:
  IInternalActionCreator<CompareRegInternalAction, CompareRegAction> = (
    action: CompareRegAction
  ): CompareRegInternalAction => ({
    ...action,
    type: InternalClientActionType.COMPARE_REG
  })

export interface CompareRegCompleteInternalAction
  extends InternalAction, DataAction<CompareRegArgs> {
    type: InternalClientActionType.COMPARE_REG_COMPLETE,
    compareRegResult: ICompareRegResponse
  }

export const compareRegCompleteInternal:
  DataActionCreator<CompareRegCompleteInternalAction, CompareRegArgs> = (
    compareRegResult: ICompareRegResponse,
    args: CompareRegArgs
  ): CompareRegCompleteInternalAction => ({
    type: InternalClientActionType.COMPARE_REG_COMPLETE,
    compareRegResult,
    args
  })

export interface CompareRegErrorInternalAction
  extends InternalErrorAction<CompareRegArgs>, DataAction<CompareRegArgs> {
    type: InternalClientActionType.COMPARE_REG_ERROR
  }

export const compareRegErrorInternal:
  InternalDataErrorCreator<CompareRegErrorInternalAction, CompareRegArgs> = (
    args: CompareRegArgs,
    error: any
  ): CompareRegErrorInternalAction => {
    return {
      type: InternalClientActionType.COMPARE_REG_ERROR,
      error,
      args
    }
  }