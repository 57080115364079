import React from 'react'
import { connect } from 'react-redux'
import { Component } from 'react'
import { Dispatch } from 'redux'

import { State } from '../../models'
import { auth } from '../../actions/auth'
import { Redirect, RouteComponentProps, StaticContext, withRouter } from 'react-router'
import { Routes } from '../../util/routes'

const styles = require('./Login.scss')

interface Params {
}

interface LocationState {
  from: string
}
interface Props {
  dispatch: Dispatch,
  authenticated: boolean,
}

class LoginComponent extends Component<Props & RouteComponentProps<Params, StaticContext, LocationState>> {

  componentDidMount() {
    if (!this.props.authenticated) {
      const { state: { from = '' } = {} } = this.props.location
      this.props.dispatch(auth(from))
    }
  }

  render() {
    const {
      authenticated,
      location: {
        search = '',
        state: { from = '' } = {}
      }
    } = this.props
    if (authenticated) {
      if (search) {
        const redirectUri = decodeURIComponent(new URLSearchParams(search).get('redirectUri'))
        const split = redirectUri.split('?')
        return (
          <Redirect to={{
            pathname: split[0] || Routes.DEFAULT,
            search: split[1]
          }} />
        )
      }
      else return (
        <Redirect to={{
          pathname: decodeURIComponent(from) || Routes.DEFAULT
        }} />
      )
    }
    else return (
      <div className={styles.container}>
        Initializing Login...
      </div>
    )
  }
}

const mapStateToProps = (state: State): Props => ({
  authenticated: state.app.authenticated,
  dispatch: undefined
})

const connected = connect(mapStateToProps)(LoginComponent)

export default withRouter(connected)