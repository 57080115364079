import React from 'react'
import { toast } from 'react-toastify'
import { Trans } from 'react-i18next'
import successIcon from '../../shared/images/success.svg'
import errorIcon from '../../shared/images/error.svg'
import infoIcon from '../../shared/images/info.svg'
import './Toast.scss'

toast.configure({
  draggable: false,
  position: toast.POSITION.TOP_CENTER,
  closeButton: false,
  hideProgressBar: true
})

const autoCloseTime = 3000
// Defined to prevent duplicates
const errorId = 1
const successId = 2

export enum NotificationTypes {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info'
}

const getIcon = (type: string) => {
  if (type === NotificationTypes.SUCCESS) return successIcon
  if (type === NotificationTypes.ERROR) return errorIcon
  if (type === NotificationTypes.INFO) return infoIcon
  // Need default icon
  return successIcon
}

const Notification = ({ message, type }) => (
  <div className='wrapper'>
    <div className='content'>
      <img src={getIcon(type)} className='icon' alt='icon' />
      <div className='messageWrap'>
        <div className='title'>
          <Trans>{message.title}</Trans>
        </div>
        {
          message.subTitle &&
            <div className='subTitle'>
              <Trans>{message.subTitle}</Trans>
            </div>
        }
      </div>
    </div>
    <span className='actionButton'>
      <Trans>Okay</Trans>
    </span>
  </div>
)

export const showNotification = (message, type: string) => {
  const isError = type === NotificationTypes.ERROR
  toast(
    <Notification message={message} type={type} />, {
      className: type,
      autoClose: isError ? false : autoCloseTime,
      toastId: isError ? errorId : successId
    })
}