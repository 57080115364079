import { InternalClientActionType } from '../../actions/internal/types'
import { IDataState } from '../../models/state'
import {
  CreateDatumCompleteInternalAction,
  CreateDatumInternalAction,
  CreateDatumErrorInternalAction
} from '../../actions/internal/createDatum'

export function createDatumReducer(
  state: IDataState<any> = { // TODO Pending
    fetching: undefined,
    data: undefined
  },
  action: CreateDatumInternalAction |
    CreateDatumCompleteInternalAction |
    CreateDatumErrorInternalAction
): IDataState<any> { // TODO Pending
  switch (action.type) {
    case InternalClientActionType.CREATE_DATUM_COMPLETE:
      return {
        fetching: false,
        data: action.createDatumResult
      }
    case InternalClientActionType.CREATE_DATUM:
      return {
        ...state,
        fetching: true
      }
    default:
      return state
  }
}