import { InternalClientActionType } from './types'
import {
  InternalAction,
  DataAction,
  DataActionCreator,
  InternalErrorAction,
  InternalDataErrorCreator
} from '../..//actions'
import {
  EventSearchArgs,
  EventSearchAction
} from '../external/eventSearch'
import { IInternalActionCreator } from '../../reducers/external/actionMapper'

export interface EventSearchInternalAction
  extends InternalAction, DataAction<EventSearchArgs> {
  type: InternalClientActionType.EVENT_SEARCH
}

export const eventSearchInternal:
  IInternalActionCreator<EventSearchInternalAction, EventSearchAction> = (
    action: EventSearchAction
  ): EventSearchInternalAction => ({
    ...action,
    type: InternalClientActionType.EVENT_SEARCH
  })

export interface EventSearchCompleteInternalAction
  extends InternalAction, DataAction<EventSearchArgs> {
    type: InternalClientActionType.EVENT_SEARCH_COMPLETE,
    eventSearchResult: any // TODO Pending
  }

export const eventSearchCompleteInternal:
  DataActionCreator<EventSearchCompleteInternalAction, EventSearchArgs> = (
    eventSearchResult: any, // TODO Pending
    args: EventSearchArgs
  ): EventSearchCompleteInternalAction => ({
    type: InternalClientActionType.EVENT_SEARCH_COMPLETE,
    eventSearchResult,
    args
  })

export interface EventSearchErrorInternalAction
  extends InternalErrorAction<EventSearchArgs>, DataAction<EventSearchArgs> {
    type: InternalClientActionType.EVENT_SEARCH_ERROR
  }

export const eventSearchErrorInternal:
  InternalDataErrorCreator<EventSearchErrorInternalAction, EventSearchArgs> = (
    args: EventSearchArgs,
    error: any
  ): EventSearchErrorInternalAction => {
    return {
      type: InternalClientActionType.EVENT_SEARCH_ERROR,
      error,
      args
    }
  }