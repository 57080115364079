import React, { Component } from 'react'
import { Cell, Grid } from 'react-foundation'
import { connect } from 'react-redux'
import { get, indexOf } from 'lodash'
import HttpError from '../../components/error/HttpError'
import EventsTable from '../../components/events/EventsTable'
import Header from '../../components/header/Header'
import { App } from '../../models/App'
import { State } from '../../models'
import { Roles } from '../../models/KeycloakUserInfo'

import styles from './EventsPage.module.scss'

interface Props {
  app: App
}

class EventsPageComponent extends Component<Props> {
  render() {
    const { app } = this.props
    const roles = get(app, 'realmAccess.roles', [])
    const isForbidden = indexOf(roles, Roles.EVENT_MANAGEMENT) === -1

    return (
      <div className={`auto ${styles.eventsPageContainer}`}>
        <Grid>
          <Cell>
            <Header title={'EVENT MANAGEMENT'} subTitle={'PENDING EVENTS'} />
          </Cell>
          <Cell>
            {
              isForbidden
                ? <HttpError
                  statusCode={403}
                  text={'You do not have access to Pending Events'}
                  subText={'Please contact your organization administrator to grant you the necessary privileges.'}
                />
                : <EventsTable />
            }
          </Cell>
        </Grid>
      </div>
    )
  }
}

export default connect((state: State): Props => ({
  app: state.app
}))(EventsPageComponent)
