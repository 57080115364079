import React from 'react'
import { Cell, Grid } from 'react-foundation'
import { Trans } from 'react-i18next'
import { sortBy } from 'lodash'
import './EventDetailsTable.scss'
import { IMasterEvent } from '../../models/Directory'
import { getPlatformMasterEventLink } from '../../util/getPlatformLink'
import { getPlatformIcon } from '../../util/getPlatformIcon'
import MenuPopup, { MenuItem } from '../menuPopup/MenuPopup'
import { State } from '../../models'
import { App } from '../../models/App'
import { connect } from 'react-redux'
import { showNotification, NotificationTypes } from '../toast/Toast'

interface RProps {
  app: App
}

interface IProps extends RProps {
  app: App
  masterEvents: Array<IMasterEvent>
}

const sortFields = ['platform']

// TODO: don't disable the button once scoping is implemented
const MasterEventsTable: React.SFC<IProps> = ({
  app,
  masterEvents = []
}) => (
  <div className='event-details-table'>
    <Grid vertical>
      {
        // header
      }
      <Grid className={`grid-x details-table-header`}>
        <Cell small={1}>
          <Trans>Partner</Trans>
        </Cell>
        <Cell small={1}>
          <Trans>ID</Trans>
        </Cell>
        <Cell className='auto'>
          <Trans>Name</Trans>
        </Cell>
      </Grid>
      {// data rows
        masterEvents.length
          ? sortBy(masterEvents, sortFields).map((master) => {
            const link = {
              id: master.id,
              name: master.name,
              platform: {
                id: '???',
                metadataEndpoint: master.platformMetadataEndpoint,
                name: master.platform
              },
              platformOwnedIdentifier: master.platformOwnedId
            }
            return (
              <Grid
                className='grid-x details-table-row'
                key={`${master.platform}-${master.id}`}
              >
                <Cell small={1}>
                  <a
                    href={getPlatformMasterEventLink(link)}
                    rel='noopener noreferrer'
                    target='_blank'>{getPlatformIcon(link.platform)}</a>
                </Cell>
                <Cell small={1}>{master.platformOwnedId}</Cell>
                <Cell className='auto'>{master.name}</Cell>
                <ActionsMenu />
              </Grid>
            )

            function ActionsMenu() {
              return <Cell small={1}
                className='shrink details-table-row-menu'
                onClick={(e) => e.stopPropagation()}
              >
                {master.platform.toUpperCase() !== 'RUNSIGNUP'
                  ? null
                  : <MenuPopup>
                    {/* This will have a list of options */}
                    <MenuItem
                      label='Backfill Participants'
                      onClick={async () => {
                        if (window.confirm('are you sure you want to backfill participants?')) {
                          try {
                            await backfill('ct-athlinks')
                          }
                          catch (err1) {
                            console.error(err1)
                            try {
                              await backfill('runsignup_chronotrack')
                            }
                            catch (err2) {
                              console.error(err2)
                              showNotification({
                                title: String(err1)
                              },
                                NotificationTypes.ERROR
                              )
                              showNotification({
                                title: String(err2)
                              },
                                NotificationTypes.ERROR
                              )
                            }
                          }
                        }

                        async function backfill(superPartner: 'ct-athlinks' | 'runsignup_chronotrack') {
                          const result = await fetch(`${master.platformMetadataEndpoint}/backfill/rsu/superPartner/${superPartner}/race/${master.platformOwnedId}`, {
                            method: 'POST',
                            headers: {
                              authorization: 'Bearer ' + app.token
                            }
                          })
                          if (result.status >= 400) {
                            throw Error(await result.text())
                          }
                          showNotification({
                            title: await result.text()
                          },
                            NotificationTypes.SUCCESS
                          )
                        }
                      }} />
                  </MenuPopup>
                }
              </Cell>
            }
          })
          : null}
    </Grid>
  </div>
)

export default connect((state: State): RProps => ({
  app: state.app
}))(MasterEventsTable)
