import { Transerializable } from '@athlinks/get-or'
import { RegChoice, Registration } from '@athlinks/eventmetadata-types'

export interface ICompareRegResponse {
  compareRegistrations: Array<ICompareRegResult>
}

export interface ICompareRegResult {
  regChoice: RegChoice,
  Athlinks: Registration[],
  CTLive: Registration[],
  RunSignUp: Registration[]
}

export interface IRegMatch {
  Athlinks?: Registration,
  CTLive?: Registration,
  RunSignUp?: Registration
}

export interface ICompareRegState {
  errorMessage: string,
  isLoading: boolean,
  result: ICompareRegResult[]
}

export class CompareRegState extends Transerializable
  implements ICompareRegState {
  errorMessage: string = ''
  isLoading: boolean = false
  result: ICompareRegResult[] = []
}