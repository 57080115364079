
import { getEnvironment, Environment } from './getEnvironment'

export interface IAppConfig {
  adminBase: string,
  athleteApiWsBase: string,
  athlinksBase: string,
  ctliveBase: string,
  directoryGraphUrl: string,
  eventsGraphUrl: string,
  rsuBase: string,
  startBase: string,
}

const localConfig: IAppConfig = {
  adminBase: 'http://admin.athlinks.com',
  athleteApiWsBase: 'wss://alycqndko5.execute-api.us-east-1.amazonaws.com/stage',
  athlinksBase: 'https://stage.athlinks.com',
  ctliveBase: 'https://admin-qa.chronotrack.com',
  directoryGraphUrl: 'http://localhost:6001/graphql',
  eventsGraphUrl: 'http://localhost:6001/graphql',
  rsuBase: 'https://test.runsignup.com',
  startBase: 'https://startstaging.athlinks.com'
}

const stageConfig: IAppConfig = {
  adminBase: 'http://adminstaging.athlinks.com',
  athleteApiWsBase: 'wss://alycqndko5.execute-api.us-east-1.amazonaws.com/stage',
  athlinksBase: 'https://stage.athlinks.com',
  ctliveBase: 'https://admin-qa.chronotrack.com',
  directoryGraphUrl: 'https://u35l31il4d.execute-api.us-east-1.amazonaws.com/graphql',
  eventsGraphUrl: 'https://u35l31il4d.execute-api.us-east-1.amazonaws.com/graphql',
  rsuBase: 'https://test.runsignup.com',
  startBase: 'https://startstaging.athlinks.com'
}

const prodConfig: IAppConfig = {
  adminBase: 'http://admin.athlinks.com',
  athleteApiWsBase: 'wss://???.execute-api.us-east-1.amazonaws.com/prod',
  athlinksBase: 'https://www.athlinks.com',
  ctliveBase: 'https://admin.chronotrack.com',
  directoryGraphUrl: 'https://hub-api.athlinks.com/directory/graphql',
  eventsGraphUrl: 'https://hub-api.athlinks.com/events/graphql',
  rsuBase: 'https://runsignup.com',
  startBase: 'https://start.athlinks.com'
}

export const getAppConfig = () => {
  const environment = getEnvironment()

  switch (environment) {
    case Environment.PROD:
      return prodConfig
    case Environment.STAGE:
    case Environment.LOCALSTAGE:
      return stageConfig
    default:
      return localConfig
  }
}