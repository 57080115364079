import { mergeMap } from 'rxjs/operators'
import { Observable } from 'rxjs'

import {
  authError,
  AuthActionError,
  UnauthAction,
  UnauthActionComplete,
  unauthComplete
} from '../../actions/auth'
import { AuthActionTypes } from '../../actions/types'
import { ofType } from 'redux-observable'
import { logout } from '../../keycloak/KeycloakService'

export function unauthenticate(
  actions$: Observable<UnauthAction>
): Observable<UnauthActionComplete | AuthActionError> {
  return actions$.pipe(
    ofType(AuthActionTypes.UNAUTH),
    mergeMap(async (action$: UnauthAction) => {
      try {
        await logout()
        return unauthComplete()
      } catch (error) {
        return authError(error)
      }
    })
  )
}