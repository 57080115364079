import { Transerializable } from '@athlinks/get-or'
import { EventStatus } from '@athlinks/admin-events-model'

export interface IEventStatusResponse {
  updateEventStatus: {
    id: number,
    status: EventStatus,
    success: boolean
  }
}

export interface IEventStatusResult {
  id: number,
  status: EventStatus,
  success: boolean
}

export interface IEventStatusState {
  errorMessage: string,
  isLoading: boolean,
  result: Array<IEventStatusResult>
}

export class EventStatusState extends Transerializable
  implements IEventStatusState {
  errorMessage: string = ''
  isLoading: boolean = false
  result: Array<IEventStatusResult> = [{
    id: 0,
    status: EventStatus.NotSet,
    success: false
  }]
}