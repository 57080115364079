import gql from 'graphql-tag'
import { CreateDatumArgs } from '../../actions/external/createDatum'
import { DeleteDatumArgs } from '../../actions/external/deleteDatum'
import { LinkEntriesArgs } from '../../actions/external/linkEntries'
import { RefreshEventArgs } from '../../actions/external/refreshEvent'

// create
const createDatum =
  gql`
    mutation Create(
      $platform: ID,
      $type: EntryTypes,
      $id: ID,
      $parent: ID
    ) {
      createEvent(
        platform: $platform,
        type: $type,
        id: $id,
        parent: $parent
      ) {
        id,
        success,
        message
      }
    }
  `

export const createDatumMutationBuilder = (args: CreateDatumArgs) => (
  {
    query: createDatum,
    variables: args
  }
)

// delete
const deleteDatum =
  gql`
    mutation Delete(
      $entryId: ID,
      $linkId: ID,
      $isLinked: Boolean
    ) {
      deleteEvent(
        entryId: $entryId,
        linkId: $linkId,
        isLinked: $isLinked
      ) {
        success,
        message
      }
    }
  `

export const deleteDatumMutationBuilder = (args: DeleteDatumArgs) => (
  {
    query: deleteDatum,
    variables: args
  }
)

// refresh
const refreshEvent =
  gql`
    mutation Refresh(
      $platform: ID,
      $type: EntryTypes,
      $id: ID
    ) {
      refreshEvent(
        platform: $platform,
        type: $type,
        id: $id
      ) {
        success,
        message
      }
    }
  `

export const refreshEventMutationBuilder = (args: RefreshEventArgs) => (
  {
    query: refreshEvent,
    variables: args
  }
)

const linkEntriesMutation =
  gql`
    mutation LinkEntries(
      $entryId: String,
      $linkId: String!,
      $linkParentId: String,
      $isUnlink: Boolean!
    ) {
      linkEntries(
        entryId: $entryId,
        linkId: $linkId,
        linkParentId: $linkParentId,
        isUnlink: $isUnlink
      ) {
        success,
        message
      }
    }
  `

export const linkEntriesMutationBuilder =
  ({ entryId, linkId, linkParentId, token, isUnlink }: LinkEntriesArgs) => {
    const mappedArgs = {
      entryId,
      linkId,
      isUnlink,
      linkParentId,
      token
    }

    return {
      query: linkEntriesMutation,
      variables: mappedArgs
    }
  }