import { ILink } from '../models/Directory'
import { getAppConfig } from './config'

export const getPlatformEventLink = (link: ILink) => {
  switch (link.platform.name) {
    case 'Athlinks':
      return `${getAppConfig().athlinksBase}/events/${link.platformOwnedIdentifier}`
    case 'CTLive':
      return `${getAppConfig().ctliveBase}/admin/event/index/eventID/${link.platformOwnedIdentifier}`
    default:
      return null
  }
}

export const getPlatformMasterEventLink = (link: ILink) => {
  switch (link.platform.name) {
    case 'Athlinks':
      return `${getAppConfig().athlinksBase}/event/${link.platformOwnedIdentifier}`
    case 'RunSignUp':
      return `${getAppConfig().rsuBase}/Race/Dashboard/Overview/${link.platformOwnedIdentifier}`
    default:
      return null
  }
}