import { getAppConfig } from './config'

export const getStartEventUrl = (id: number | string) => {
  if (!id) {
    return undefined
  }

  return `${getAppConfig().startBase}/Event/Dashboard/${id}`
}

export const getRequestLogUrl = (id: number | string) => {
  if (!id) {
    return undefined
  }
  return `${getAppConfig().adminBase}/RequestLog.aspx?eventid=${id}&page=1`
}