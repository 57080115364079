import { Transerializable } from '@athlinks/get-or'
import {
  IDirectoryEventSearchResult
} from './Directory'

export interface IDirectoryEventSearchState {
  errorMessage: string,
  isLoading: boolean,
  result: IDirectoryEventSearchResult
}

export class DirectoryEventSearchState extends Transerializable
  implements IDirectoryEventSearchState {
  errorMessage: string = ''
  isLoading: boolean = false
  result: IDirectoryEventSearchResult = undefined
}