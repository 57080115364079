import React, { Component } from 'react'
import { Cell, Grid } from 'react-foundation'
import { connect, Dispatch } from 'react-redux'
import { eventSearch } from '../../actions/external/eventSearch'
import { Button } from '@athlinks/component-library'
import { Trans } from 'react-i18next'
import './Search.scss'
import { Dropdown, IChoice } from '../dropdown/Dropdown'
import { App } from '../../models/App'
import {
  DirectoryState,
  IDatumType,
  datumTypes
} from '../../models/Directory'
import { IPlatform } from '../../models/Platforms'
import { State } from '../../models/index'
import { isEmpty } from 'lodash'

import {
  changePlatform,
  changeType,
  changeEventId
} from '../../actions/directory'
import { IDirectorySearchDataState } from '../../models/DirectorySearchDataState'

interface RProps {
  app: App,
  directoryState: DirectoryState,
  dispatch: Dispatch
}

interface IProps extends RProps {
  input: string,
  isLoading: boolean,
  platforms: IPlatform[],
  searchCallback: (data: IDirectorySearchDataState) => void
}
interface IState {
  inputValid: boolean
}

class SearchComponent extends Component<IProps, IState> {
  state: IState = {
    inputValid: false
  }

  onKeyDown = (e) => {
    const { inputValid } = this.state

    switch (e.key) {
      case 'Enter':
        if (inputValid) {
          this.onLookup()
        }
        break
      default:
        break
    }
  }

  onPlatformSelectChange = (platform: IPlatform) => {
    const { dispatch } = this.props
    dispatch(
      changePlatform(platform)
    )
  }

  onTypeSelectChange = (type: IDatumType) => {
    const { dispatch } = this.props
    dispatch(
      changeType(type)
    )
  }

  onInputChange = (e) => {
    const { dispatch } = this.props
    const input = e.target.value
    const inputValid = input.length > 0
    this.setState({
      inputValid
    })

    dispatch(
      changeEventId(input)
    )
  }

  onLookup = () => {
    const {
      app: { token },
      dispatch,
      searchCallback,
      directoryState: {
        searchData
      }
    } = this.props

    const {
      platform,
      datumType,
      id
    } = searchData
    searchCallback(searchData)

    dispatch(
      eventSearch({
        platform: platform.id,
        type: datumType.value,
        id,
        pullMetadata: true,
        token
      })
    )
  }

  renderErrorMessage = () =>
    <div className={'directory-search-error-container'}>
      <span className={'directory-search-error'}>
        <Trans>Invalid Event ID</Trans>
      </span>
    </div>

  render() {
    const {
      directoryState: {
        searchData
      },
      isLoading,
      platforms = []
    } = this.props

    const {
      platform,
      datumType,
      id
    } = searchData

    const inputValid = !!id.length
    // TODO: remove this once the api returns the correct set
    const unsupportedPlatforms = ['Directory']

    return (
      <Grid className={'grid-y'}>
        <Grid className={'grid-x'} style={{ flexWrap: 'nowrap' }}>
          <Cell className={'shrink'}>
            <Dropdown
              title={!isEmpty(platform) ? platform.name : 'Partner'}
              translateTitle={isEmpty(platform)}
              choices={
                platforms
                  .filter((p) => unsupportedPlatforms.indexOf(p.name) === -1)
                  .sort((one, two) => (one.name < two.name ? -1 : 1))
                  .map((p) => {
                    return {
                      'label': p.name,
                      'onClick': () => this.onPlatformSelectChange(p)
                    }
                  }) as IChoice[]
              }
              buttonClass={'search-dropdown'}
              menuClass={'search-dropdown-menu'}
              disabled={isEmpty(platforms)}
            />
          </Cell>
          <Cell className={'shrink'}>
            <Dropdown
              title={datumType.displayName || 'Types'}
              translateTitle={!datumType.displayName}
              choices={
                datumTypes.map((type: IDatumType) => {
                  return {
                    'label': type.displayName,
                    'onClick': () => this.onTypeSelectChange(type)
                  }
                }) as IChoice[]
              }
              buttonClass={'search-dropdown'}
              menuClass={'search-dropdown-menu'}
              disabled={isEmpty(platform)}
            />
          </Cell>
          <Cell className={'shrink'}>
            <input
              disabled={isEmpty(datumType)}
              onChange={(e) => this.onInputChange(e)}
              className={'directory-search-input'}
              placeholder='Event ID'
              spellCheck={false}
              autoComplete='off'
              autoCorrect='off'
              value={id}
              onKeyDown={this.onKeyDown}
            />
          </Cell>
          <Cell className={'large-3'}>
            <Button
              className={'directory-search-button'}
              disabled={!inputValid || isEmpty(datumType) || isLoading}
              color='dark-blue'
              maxWidth='225px'
              text='LOOKUP'
              onClick={this.onLookup}
            />
          </Cell>
        </Grid>
        <Cell className={'auto'}>
          {
            id && !inputValid && this.renderErrorMessage()
          }
        </Cell>
      </Grid>
    )
  }
}

export const Search = connect((state: State): RProps => ({
  app: state.app,
  directoryState: state.directoryState,
  dispatch: undefined
}))(SearchComponent)
