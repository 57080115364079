import React, { Component } from 'react'
import { Cell, Grid } from 'react-foundation'

import styles from './Footer.module.scss'

export default class Footer extends Component {
  render() {
    return (
      <div className={styles.container}>
        <Grid>
          <Cell auto />
          <Cell small={4}>
            <h6>&copy;{new Date().getFullYear()} Athlinks Services</h6>
          </Cell>
          <Cell auto />
        </Grid>
      </div>
    )
  }
}
