import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { Provider } from 'react-redux'

import { configureStore } from './store/index'
import CompareRegPage from './containers/compareregpage/CompareRegPage'
import ResultsPage from './containers/resultspage/ResultsPage'
import DirectoryPage from './containers/directorypage/DirectoryPage'
import Error from './containers/error/Error'
import EventsPage from './containers/eventspage/EventsPage'
import Login from './containers/login/Login'
import Logout from './containers/logout/Logout'
import PropositionPage from './containers/propositionpage/PropositionPage'
import { ProtectedRoute } from './keycloak/ProtectedRoute'
import { Routes } from './util/routes'
import * as serviceWorker from './serviceWorker'

import './i18n'
import 'react-toastify/dist/ReactToastify.css'

import './index.scss'

export const store = configureStore()

/**
 * If you do not add any new Routes to the Routes enum, users will be logged
 * into Routes.HOME until they are added.
 */
const routing = (
  <Provider store={store}>
    <BrowserRouter>
      <Switch>
        <Route exact path={Routes.PROPOSITION} component={PropositionPage} />
        <Route path={Routes.LOGIN} component={Login} />
        <Route path={Routes.LOGOUT} component={Logout} />
        <ProtectedRoute path={Routes.PARTNER_SYNC} component={DirectoryPage} />
        <ProtectedRoute path={Routes.EVENTS} component={EventsPage} />
        <ProtectedRoute path={Routes.COMPARE_REG} component={CompareRegPage} />
        <Route path={Routes.RESULTS} component={ResultsPage} />
        <ProtectedRoute component={Error}/>
      </Switch>
    </BrowserRouter>
  </Provider>
)

ReactDOM.render(routing, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
