import { Log } from '../../logger'
import {
  InternalErrorAction,
  InternalDataAction,
  DataActionCreator,
  InternalDataErrorCreator
} from '../../actions'
import { buildClient } from '../../client'
import { store } from '../..'

export const parseErrorMessage = (
  e: { error: string } | any
): string => {
  if (typeof e === 'object' && e.error) {
    const cleaner = JSON.parse(e.error)
    return (cleaner.message) ? cleaner.message : e
  } else {
    return `${e}`
  }
}

export const checkForErrorResponse = <E extends InternalErrorAction<A>, A>(
  args: A,
  response: any,
  errorCreator: InternalDataErrorCreator<E, A>,
  reject?: (reason?: any) => void,
  st: any = store
): any => {
  // check if reject is there (this might be outside of a promise lifecycle)
  const rejectWithError = (error: any) => {
    const which = reject || st.dispatch
    which(errorCreator(args, parseErrorMessage(error)))
  }
  try {
    const { data, error } = response
    if (error) {
      rejectWithError(error)
    } else if (data) {
      return data
    } else {
      rejectWithError(error)
    }
  } catch (error) {
    rejectWithError(error)
  }
}

const callback = <R extends InternalDataAction<A>, E extends InternalErrorAction<A>, A>(
  response: any,
  args: A,
  actionCreator: DataActionCreator<R, A>,
  errorCreator: InternalDataErrorCreator<E, A>,
  resolve: (value?: R | PromiseLike<E>) => void,
  reject: (reason?: any) => void
) => {
  try {
    const json = checkForErrorResponse(
      args,
      response,
      errorCreator,
      reject
    )
    Log.info('Response for args', args, 'received:', json)
    if (resolve && json) {
      resolve(actionCreator(json, args))
      resolve = undefined
    }
  } catch (error) {
    reject(errorCreator(args, error))
  }
}

export const waitForData =
  <R extends InternalDataAction<A>, E extends InternalErrorAction<A>, A>(
    args: A,
    queryCreator,
    actionCreator: DataActionCreator<R, A>,
    errorCreator: InternalDataErrorCreator<E, A>
  ): Promise<R | E> =>
    new Promise((resolve, reject) => {
      const queryWithVars = queryCreator(args)
      const apolloClient = buildClient(args)

      // execute query
      apolloClient
        .query({
          query: queryWithVars.query,
          variables: queryWithVars.variables
        })
        .then((data: any) => {
          callback(
            data,
            args,
            actionCreator,
            errorCreator,
            resolve,
            reject
          )
        })
        .catch((error) => {
          reject(errorCreator(args, error))
        })
    })

export const waitForMutationResponse =
  <R extends InternalDataAction<A>, E extends InternalErrorAction<A>, A>(
    args: A,
    mutationCreator,
    actionCreator: DataActionCreator<R, A>,
    errorCreator: InternalDataErrorCreator<E, A>
  ): Promise<R | E> =>
    new Promise((resolve, reject) => {
      const mutationWithVars = mutationCreator(args)

      const apolloClient = buildClient(args)

      // execute query
      apolloClient
        .mutate({
          mutation: mutationWithVars.query,
          variables: mutationWithVars.variables
        })
        .then((data: any) => {
          console.log('data:', data)
          callback(
            data,
            args,
            actionCreator,
            errorCreator,
            resolve,
            reject
          )
        })
        .catch((error) => {
          reject(errorCreator(args, error))
        })
    })
