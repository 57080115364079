import React, { Component, ReactNode } from 'react'
import './MenuPopup.scss'

interface MenuPopupState {
  listOpen: boolean
}

interface IProps {
  children: ReactNode
}

interface IMenuItem {
  label: string,
  onClick?: () => void
}

export const MenuItem: React.SFC<IMenuItem> = ({
  label,
  onClick
}) => (
  <div
    className='item'
    onClick={onClick}
  >
    {label}
  </div>
)

export default class MenuPopup extends Component<IProps, Partial<MenuPopupState>> {
  private compRef = React.createRef<HTMLDivElement>()

  constructor(props) {
    super(props)
    this.state = {
      listOpen: false
    }
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClick)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick)
  }

  handleClick = (e) => {
    if (this.compRef.current && !this.compRef.current.contains(e.target))
      this.setState({listOpen: false})
    else
      this.setState(prevState => ({
        listOpen: !prevState.listOpen
      }))
  }

  render() {
    const { children } = this.props
    const { listOpen } = this.state
    return (
      <div
        className={`menuPopup ${listOpen && 'active'}`}
        ref={this.compRef}
      >
        <i className='icon icon ion-more'/>
        {
          listOpen &&
          <div className='popupList'>
            {children}
          </div>
        }
      </div>
    )
  }
}