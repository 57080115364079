import { Action } from 'redux'
import { AppActionTypes } from './types'
import { ModalData } from '../models/Modal'

export interface SetModalOpenAction extends Action {
  type: AppActionTypes.SET_MODAL_OPEN,
  modalOpen: ModalData<any>
}

export function setModalOpen<T>(modalOpen: ModalData<T>): SetModalOpenAction {
  console.log('modalOpen:', modalOpen)
  return {
    type: AppActionTypes.SET_MODAL_OPEN,
    modalOpen
  }
}
// export const setModalOpen = (
//   modalOpen: ModalData
// ): SetModalOpenAction => ({
//   type: AppActionTypes.SET_MODAL_OPEN,
//   modalOpen
// })

export interface SetModalInputAction extends Action {
  type: AppActionTypes.SET_MODAL_INPUT,
  modalInput: any
}

export const setModalInput = (
  modalInput: any
): SetModalInputAction => ({
  type: AppActionTypes.SET_MODAL_INPUT,
  modalInput
})