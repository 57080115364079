import React from 'react'
import { connect, Dispatch } from 'react-redux'
import { IDirectoryEventSearchMasterEventResult } from '../../models/Directory'
import './EventDetails.scss'
import MasterEventsTable from './MasterEventsTable'
import {
  getMasterEvents
} from '../../util/directoryUtil'
import { Trans } from 'react-i18next'
import { setModalOpen } from '../../actions/app'
import { ModalTypes } from '../../models/Modal'
import DirectoryActionButton from './DirectoryActionButton'
import { createDatum } from '../../actions/external/createDatum'
import EventsTable from './EventsTable'

interface RProps {
  dispatch: Dispatch
}

interface IProps extends RProps {
  dispatch: Dispatch
  eventData: IDirectoryEventSearchMasterEventResult
}

function getPlatforms(ed) {
  return ed.map(function (i) {
    return i.platform.name
  })
}

const EventDetails: React.SFC<IProps> = ({
  dispatch,
  eventData
}) => {
  const {
    name,
    id,
    links
  } = eventData
  const platformsToExclude = getPlatforms(links)
  return (
    <div>
      <h2 className='event-details-event-name'>
        {name}
      </h2>
      <div className='details-header'>
        <h5 className='details-title'>
          <Trans>{'Master Events'}</Trans>
        </h5>
        <div className='action-buttons'>
          <DirectoryActionButton
            className='event-details-add-event-button event-action-button'
            label='ADD MASTER EVENT'
            icon={<i className='icon icon ion-plus' />}
            // disabled={masterEvents.length > 0}
            // TODO: This button should only be disabled if the masterEvents.length === platforms.length
            primary={true}
            onClick={() =>
              dispatch(
                setModalOpen({
                  type: ModalTypes.DIRECTORY_ADD_MASTER_EVENT,
                  args: {
                    masterEventName: name,
                    masterId: id,
                    platformsToExclude
                  },
                  action: {
                    label: 'ADD MASTER EVENT',
                    dispatchHandler: createDatum
                  }
                })
              )
            }
          />
        </div>
      </div>
      <MasterEventsTable
        masterEvents={getMasterEvents(id, links)}
      />
      <div className='details-header'>
        <h5 className='details-title'>
          <Trans>{'Events'}</Trans>
        </h5>
        <div className='action-buttons'>
          <DirectoryActionButton
            className='event-details-add-event-button event-action-button'
            label='Add Event'
            icon={<i className='icon icon ion-plus' />}
            primary={true}
            onClick={() => dispatch(setModalOpen({
              type: ModalTypes.DIRECTORY_ADD_EVENT,
              args: {
                masterEventName: name,
                masterId: id
              },
              action: {
                label: 'ADD EVENT',
                dispatchHandler: createDatum
              }
            }))}
          />
        </div>
      </div>
      <EventsTable
        events={eventData.events}
      />
    </div>
  )
}
export default connect((): RProps => ({
  dispatch: undefined
}))(EventDetails)