import React, { Component } from 'react'
import { Cell, Grid } from 'react-foundation'
import Checkbox from 'react-three-state-checkbox'

import styles from './EventRow.module.scss'

interface IProps {
  selectAllCallback: () => void
  selectAllChecked: boolean,
  selectAllDisabled: boolean,
  selectAllIndeterminate: boolean
}

export default class EventRowHeader extends Component<IProps> {
  render() {
    const {
      selectAllCallback,
      selectAllChecked,
      selectAllDisabled,
      selectAllIndeterminate
    } = this.props

    return (
      <Grid className={`grid-margin-x ${styles.styleDetailsSubdued}`}>
        <Cell className={'shrink'}>
          <Checkbox
            checked={selectAllChecked}
            indeterminate={selectAllIndeterminate}
            disabled={selectAllDisabled}
            onChange={selectAllCallback}
          />
        </Cell>
        <Cell large={4} className={'auto'}>
          EVENT
        </Cell>
        <Cell large={5}  className={'auto'}>
          DATE SUBMITTED
        </Cell>
      </Grid>
    )
  }
}
