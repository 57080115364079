import { InternalClientActionType } from './types'
import {
  InternalAction,
  DataAction,
  DataActionCreator,
  InternalErrorAction,
  InternalDataErrorCreator
} from '..'
import {
  CreateDatumArgs,
  CreateDatumAction
} from '../external/createDatum'
import { IInternalActionCreator } from '../../reducers/external/actionMapper'

export interface CreateDatumInternalAction
  extends InternalAction, DataAction<CreateDatumArgs> {
  type: InternalClientActionType.CREATE_DATUM
}

export const createDatumInternal:
  IInternalActionCreator<CreateDatumInternalAction, CreateDatumAction> = (
    action: CreateDatumAction
  ): CreateDatumInternalAction => ({
    ...action,
    type: InternalClientActionType.CREATE_DATUM
  })

export interface CreateDatumCompleteInternalAction
  extends InternalAction, DataAction<CreateDatumArgs> {
  type: InternalClientActionType.CREATE_DATUM_COMPLETE,
  createDatumResult: any // TODO Pending
}

export const createDatumCompleteInternal:
  DataActionCreator<CreateDatumCompleteInternalAction, CreateDatumArgs> = (
    createDatumResult: any, // TODO Pending
    args: CreateDatumArgs
  ): CreateDatumCompleteInternalAction => {
    if (!createDatumResult.createEvent.success) {
      console.error(createDatumResult.createEvent.message)
      throw new Error(createDatumResult.createEvent.message || 'unspecified')
    }
    return ({
      type: InternalClientActionType.CREATE_DATUM_COMPLETE,
      createDatumResult,
      args
    })
  }

export interface CreateDatumErrorInternalAction
  extends InternalErrorAction<CreateDatumArgs>, DataAction<CreateDatumArgs> {
  type: InternalClientActionType.CREATE_DATUM_ERROR
}

export const createDatumErrorInternal:
  InternalDataErrorCreator<CreateDatumErrorInternalAction, CreateDatumArgs> = (
    args: CreateDatumArgs,
    error: any
  ): CreateDatumErrorInternalAction => {
    return {
      type: InternalClientActionType.CREATE_DATUM_ERROR,
      error,
      args
    }
  }