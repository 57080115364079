import React from 'react'

export default ({ size = 12, color = 'rgba(0, 0, 0, 1)' }) => (
  <svg
    className='sc-bdVaJa fUuvxv'
    fill={color}
    width={size}
    height={size}
    viewBox='0 0 1024 1024' rotate='0'>
    <path d='M574.6 512l-254.6 255.2 64.6 64.8 319.4-320-319.4-320-64.6 64.8z'/>
  </svg>
)
