import React from 'react'
import { Cell } from 'react-foundation'
import MissingEventHeader from '../../components/directory/MissingEventHeader'
import Card from '../../components/card/Card'
import {
  IDirectoryEventSearchPlatformMasterEventResult,
  IDirectoryEventSearchPlatformEventResult
} from '../../models/Directory'

interface Props {
  onEventAdd?: Function
  eventData:
  | IDirectoryEventSearchPlatformMasterEventResult
  | IDirectoryEventSearchPlatformEventResult
  platform: String
}

const PlatformEvent: React.SFC<Props> = ({ onEventAdd, eventData }) => (
  <Cell>
    <Card
      className='event-details'
      header={
        <MissingEventHeader title='Event Details' onEventAdd={onEventAdd} />
      }
      // This is temp content
      // -------- TODO ------- build section
      // This is just for displaying current data
      section={
        <div key={eventData.id} style={{ display: 'flex' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '10px 30px 0px 0px'
            }}
          >
            <span style={{ textTransform: 'uppercase' }}>
              <b>ID</b>
            </span>
            {eventData.id}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '10px 10px 0px 0px'
            }}
          >
            <span style={{ textTransform: 'uppercase' }}>
              <b>NAME</b>
            </span>
            {eventData.name}
          </div>
        </div>
      }
    />
  </Cell>
)

export default PlatformEvent
