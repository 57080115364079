import { Distance } from '@athlinks/eventmetadata-types'
import { Transerializable } from '@athlinks/get-or'
import { IDirectoryEventSearchState } from './DirectoryEventSearchState'
import { IDirectoryCreateDatumState } from './DirectoryCreateDatumState'
import { IDirectorySearchDataState } from './DirectorySearchDataState'
import { IDirectoryDeleteDatumState } from './DirectoryDeleteDatumState'
import { IDirectoryEntriesLinkState } from './DirectoryEntriesLinkState'
import { IDirectoryEventRefreshState } from './DirectoryEventRefreshState'
import { IDirectoryUserPrefsState } from './DirectoryUserPrefsState'
import { IPlatform } from './Platforms'

export const DIRECTORY_RESPONSES = ['MasterEvent']

// TODO: this does not belong here
export interface IExternalCalendarTime {
  timeInMilliseconds: string,
  timeZone: string
}

export interface IEntity {
  id: string,
  name: string,
}

export type IChild = IEntity & { parentId: string }

// external (coming from GraphQL)
export interface ILink extends IEntity {
  platform: IPlatform
  platformOwnedIdentifier: string
}

export type IMasterEventLink = ILink

export interface ILinkWithDate extends ILink {
  date: IExternalCalendarTime,
}

export type IEventLink = ILinkWithDate

export interface IRaceLink extends ILinkWithDate {
  distance: Distance,
}

export type IRegChoiceLink = ILinkWithDate

export type IHasLinks<T extends ILink> = {
  links: T[]
}

export type IEntityWithLinks<T extends ILink> = IEntity & IHasLinks<T>

export type IExternalRace = IChild & IHasLinks<IRaceLink> & {
  date: IExternalCalendarTime,
  distance: Distance
}

export interface IExternalRegChoice extends IChild, IHasLinks<IRegChoiceLink> {
  date: IExternalCalendarTime,
}

export interface IExternalEvent extends IChild, IHasLinks<IEventLink> {
  date: IExternalCalendarTime,
  races: IExternalRace[],
  regChoices: IExternalRegChoice[]
}

export interface IDirectoryEventSearchMasterEventResult extends IEntity, IHasLinks<IMasterEventLink> {
  events: IExternalEvent[]
}

export type IDirectoryEventSearchPlatformMasterEventResult = IEntity
export type IDirectoryEventSearchPlatformEventResult = IEntity

export type IDirectoryEventSearchResult = IDirectoryEventSearchMasterEventResult |
  IDirectoryEventSearchPlatformMasterEventResult |
  IDirectoryEventSearchPlatformEventResult

export interface IDirectoryDataMutationResult {
  id: string,
  success: boolean
}

// internal (for component use)
export interface IMasterEvent extends IChild {
  platform: string,
  platformMetadataEndpoint?: string
  platformOwnedId: string
}

export interface IEvent extends IEntity {
  date: IExternalCalendarTime,
  parentId: string,
  parentPlatformOwnedId: string,
  platform: string,
  platformOwnedId: string
}

export interface IRegChoice extends IEntity {
  date: IExternalCalendarTime,
  parentId: string,
  parentPlatformOwnedId: string,
  platform: string,
  platformOwnedId: string,
  eventId?: string
}

export interface IRace extends IEntity {
  date: IExternalCalendarTime,
  distance: Distance,
  parentId: string,
  parentPlatformOwnedId: string,
  platform: string,
  platformOwnedId: string,
  eventId?: string
}

export interface IDirectoryEventSearchResponse {
  eventSearch: IDirectoryEventSearchResult
}

export interface IDirectoryCreateDatumResponse {
  createDatum: IDirectoryDataMutationResult
}

export interface IDirectoryDeleteDatumResponse {
  deleteDatum: IDirectoryDataMutationResult
}

export interface IDirectoryEntriesLinkResponse {
  linkEntries: IDirectoryEntriesLinkResult
}

export interface IDirectoryEntriesLinkResult {
  success: boolean
}

export interface IDirectoryEventRefreshResult {
  refreshEvent: IDirectoryEventRefreshResult
}

export interface IDirectoryEventRefreshResult {
  success: boolean
}

export interface IDirectoryState {
  searchState: IDirectoryEventSearchState,
  createDatum: IDirectoryCreateDatumState,
  deleteDatum: IDirectoryDeleteDatumState,
  searchData: IDirectorySearchDataState,
  entriesLink: IDirectoryEntriesLinkState,
  refreshEvent: IDirectoryEventRefreshState,
  userPrefs: IDirectoryUserPrefsState
}

export class DirectoryState extends Transerializable
  implements IDirectoryState {
  createDatum: IDirectoryCreateDatumState = undefined
  deleteDatum: IDirectoryDeleteDatumState = undefined
  searchData: IDirectorySearchDataState = undefined
  searchState: IDirectoryEventSearchState = undefined
  entriesLink: IDirectoryEntriesLinkState = undefined
  refreshEvent: IDirectoryEventRefreshState = undefined
  userPrefs: IDirectoryUserPrefsState = undefined
}

export interface IDatumType {
  value: string,
  displayName: string
}

export const datumTypes: IDatumType[] = [
  { value: 'MasterEvent', displayName: 'Master Event' },
  { value: 'Event', displayName: 'Event' }
]