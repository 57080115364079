import React, { Component } from 'react'
import { Trans } from 'react-i18next'
import { Cell, Grid } from 'react-foundation'
import {
  createDatum
} from '../../actions/external/createDatum'
import {
  platforms as getPlatforms
} from '../../actions/external/platforms'
import { isEmpty } from 'lodash'
import {
  DIRECTORY_RESPONSES,
  DirectoryState,
  IDirectoryEventSearchMasterEventResult
} from '../../models/Directory'
import { PlatformsState } from '../../models/Platforms'
import { ModalTypes, ModalData } from '../../models/Modal'
import { App } from '../../models/App'
import Card from '../../components/card/Card'
import { connect, Dispatch } from 'react-redux'
import HttpError from '../../components/error/HttpError'
import Header from '../../components/header/Header'
import { Search } from '../../components/directory/Search'
import EventDetailsCard from '../../components/directory/EventDetailsCard'
import PlatformEvent from '../../components/directory/PlatformEvent'
import Modal from '../../components/modal/Modal'
import { State } from '../../models'
import { Roles } from '../../models/KeycloakUserInfo'
import { get, indexOf } from 'lodash'

import {
  changeEventId,
  clearEventSelection
} from '../../actions/directory'
import { AddEventBody } from '../../components/directory/modal/AddEvent'
import { handleSearch } from '../../util/handleSearch'
import { HistoryProps } from '../../models/HistoryProps'
import { Routes } from '../../util/routes'

import { IDirectoryEntriesLinkState } from '../../models/DirectoryEntriesLinkState'
import { eventSearch } from '../../actions/external/eventSearch'

import './DirectoryPage.module.scss'
import { Loading } from '../../components/loading/Loading'

interface IProps {
  directoryState: DirectoryState
  entriesLink: IDirectoryEntriesLinkState
  app: App
  dispatch: Dispatch
  platformData: PlatformsState
}

class DirectoryPageComponent extends Component<IProps & HistoryProps> {

  parseSearch(search: string) {
    const params = new URLSearchParams(search)
    return {
      platform: params.get('platform'),
      type: params.get('type'),
      id: params.get('id')
    }
  }

  componentDidMount() {
    console.log('DirectoryPage.componentDidMount')
    const {
      app: {
        token
      },
      dispatch,
      directoryState: {
        searchData
      },
      location
    } = this.props

    dispatch(
      getPlatforms({
        onlyWithMetadata: true,
        token
      })
    )

    handleSearch(dispatch, location, searchData, token)

    // dispatch(clearLinkingActiveSelection())
  }

  componentDidUpdate(prevProps) {
    /*
      NOTE: This is all quick workaround for now
      all this could be avoided whit returning whole updated
      event tree structure on Linking action, and then update
      store on the client side, (even local state would not be needed
      for keeping linked data). Will also remove a lot of uneeded logic
      inside the component.

      When initially implemented there was no time to do implementation like that
      and we kept linking data locally

      Will need to check if we have a time to update this now.
    */
    if (
      this.props.entriesLink.result &&
      !prevProps.entriesLink.result &&
      this.props.entriesLink.result.success &&
      prevProps.entriesLink.activeEntry === this.props.entriesLink.activeEntry
    ) {
      /*
        If linking result is changed for activeEntry, do lookup
        to update tree structure inside Redux store
      */
      const {
        app,
        directoryState: {
          searchData: {
            platform,
            datumType,
            id
          }
        }
      } = this.props

      this.props.dispatch(
        eventSearch({
          platform: platform.id,
          type: datumType.value,
          id,
          pullMetadata: false,
          token: app.token
        })
      )
    }
  }

  clearSelection = () => {
    this.props.dispatch(changeEventId(''))
    this.props.dispatch(clearEventSelection())
  }

  onSearch = (data) => {
    this.props.history.push(Routes.PARTNER_SYNC +
      `?platform=${data.platform.id}&type=${data.datumType.value}&id=${data.id}`
    )

    this.setState({
      searched: true
    })
  }

  getAddEventModal = (modalData: ModalData<any>, isMaster) => {
    const { platformData } = this.props

    // TODO: remove this once the api returns the correct set
    const unsupportedPlatforms = ['Directory']
    const platforms = get(platformData, 'result.platforms', [])
      .filter((p) => unsupportedPlatforms.indexOf(p.name) === -1)
      .sort((one, two) => (one.name < two.name ? -1 : 1))
    const {
      args: { masterEventName, masterId, platformsToExclude }
    } = modalData

    return {
      title: isMaster ? 'Add Master Event' : 'Add Event',
      body: (
        <AddEventBody
          isMaster={isMaster}
          masterEventName={masterEventName}
          masterId={masterId}
          platforms={platforms}
          platformsToExclude={platformsToExclude}
        />
      ),
      action: modalData.action,
      isOpen: true
    }
  }

  getModalProps = (modalData: ModalData<any>) => {
    const type = (modalData || {}).type

    switch (type) {
      case ModalTypes.DIRECTORY_ADD_EVENT:
        return this.getAddEventModal(modalData, false)
      case ModalTypes.DIRECTORY_ADD_MASTER_EVENT:
        return this.getAddEventModal(modalData, true)
      default:
        return {}
    }
  }

  render() {
    const {
      app,
      directoryState: { searchState, searchData },
      dispatch,
      entriesLink,
      platformData
    } = this.props
    console.log('render:', searchState, searchData)
    const isLoading = searchState.isLoading || entriesLink.isLoading

    const { platform, datumType, id } = searchData

    const roles = get(app, 'realmAccess.roles', [])
    const isForbidden = indexOf(roles, Roles.EVENT_MANAGEMENT) === -1
    const modalProps: any = this.getModalProps(app.modalOpen)
    const platforms = get(platformData, 'result.platforms', [])
    const responseType = get(searchState, 'result.__typename', '')
    const foundInDirectory = indexOf(DIRECTORY_RESPONSES, responseType) !== -1

    return (
      <div className='auto'>
        <Modal
          title={modalProps.title}
          body={modalProps.body}
          action={modalProps.action}
          isOpen={modalProps.isOpen}
        />
        <Grid style={{ minWidth: 890 }}>
          <Cell>
            <Header title={'EVENT MANAGEMENT'} subTitle={'PartnerSync'} />
          </Cell>
          {
            isForbidden
              ? <HttpError
                statusCode={403}
                text={'You do not have access to PartnerSync'}
                subText={'Please contact your organization administrator to grant you the necessary privileges.'}
              />
              : <Cell>
                <Cell>
                  <Card
                    header={
                      <h5 className='directory-card-title'>
                        <Trans>Choose Partner, Type and ID</Trans>
                      </h5>
                    }
                    section={
                      <Search
                        isLoading={isLoading}
                        platforms={platforms}
                        searchCallback={this.onSearch}
                      />
                    }
                  />
                </Cell>
                {
                  isLoading &&
                  <div style={{
                    position: 'fixed',
                    zIndex: 3,
                    top: 0,
                    left: 0,
                    height: '100%',
                    width: '100%',
                    background: 'rgba(255,255,255,0.5)',
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '50px'
                  }}>
                    <Loading />
                  </div>
                }
                {/* Platform Event Data */}
                {
                  !foundInDirectory && !isEmpty(searchState.result) &&
                  <PlatformEvent
                    eventData={searchState.result}
                    platform={platform.name}
                    onEventAdd={() =>
                      dispatch(
                        createDatum({
                          platform: platform.id,
                          id,
                          type: datumType.value,
                          parent: null,
                          token: app.token
                        })
                      )}
                  />
                }
                {/* Event Data */}
                {
                  foundInDirectory &&
                  <EventDetailsCard
                    eventData={searchState.result as IDirectoryEventSearchMasterEventResult}
                    clearSelection={this.clearSelection}
                    dispatch={dispatch}
                  />
                }
              </Cell>
          }
        </Grid>
      </div>
    )
  }
}

export default connect(
  (state: State): IProps => ({
    directoryState: state.directoryState,
    entriesLink: state.entriesLink,
    app: state.app,
    dispatch: undefined,
    platformData: state.platformData
  })
)(DirectoryPageComponent)
