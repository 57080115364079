import {
  ExternalAction,
  ExternalErrorAction,
  QueryArgs
} from '../../actions'
import { DirectoryPlatformsActionTypes } from './types'
import { IExternalActionCreator } from '../../reducers/external/actionMapper'
import {
  PlatformsCompleteInternalAction,
  PlatformsErrorInternalAction
} from '../internal/platforms'

export interface PlatformsArgs extends QueryArgs {
  onlyWithMetadata: boolean
}

export interface PlatformsAction
  extends ExternalAction<PlatformsArgs> {
  type: DirectoryPlatformsActionTypes.DIRECTORY_PLATFORMS
}

export const platforms =
  (args: PlatformsArgs): PlatformsAction => ({
    args,
    type: DirectoryPlatformsActionTypes.DIRECTORY_PLATFORMS
  })

export interface PlatformsCompleteAction
  extends ExternalAction<PlatformsArgs> {
    type: DirectoryPlatformsActionTypes.DIRECTORY_PLATFORMS_COMPLETE,
    platformsResult: any // TODO Pending
}

export const platformsComplete:
  IExternalActionCreator<PlatformsCompleteAction,
  PlatformsCompleteInternalAction> = (
    action: PlatformsCompleteInternalAction
  ): PlatformsCompleteAction => ({
    ...action,
    type: DirectoryPlatformsActionTypes.DIRECTORY_PLATFORMS_COMPLETE
  })

export interface PlatformsErrorAction
  extends ExternalErrorAction<PlatformsArgs> {
  type: DirectoryPlatformsActionTypes.DIRECTORY_PLATFORMS_ERROR
}

export const platformsError:
  IExternalActionCreator<PlatformsErrorAction,
  PlatformsErrorInternalAction> = (
    action: PlatformsErrorInternalAction
  ): PlatformsErrorAction => ({
    ...action,
    type: DirectoryPlatformsActionTypes.DIRECTORY_PLATFORMS_ERROR
  })