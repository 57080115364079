import { Dispatch } from 'redux'
import { changePlatform, changeType, changeEventId } from '../actions/directory'
import { datumTypes } from '../models/Directory'
import { eventSearch } from '../actions/external/eventSearch'
import { IDirectorySearchDataState } from '../models/DirectorySearchDataState'

export const parseSearch = (search: string) => {
  const params = new URLSearchParams(search)
  return {
    platform: params.get('platform'),
    type: params.get('type'),
    id: params.get('id')
  }
}

export const handleSearch = (
  dispatch: Dispatch,
  location: { search?: string },
  searchData: IDirectorySearchDataState,
  token: string
) => {
  const searchParsed = parseSearch(location.search)

  if (searchParsed.platform) {
    dispatch(
      changePlatform({
        id: searchParsed.platform,
        name: '???'
      })
    )
  }
  if (searchParsed.type) {
    const datumType = datumTypes.find(x => x.value === searchParsed.type)
    if (datumType) {
      dispatch(
        changeType(datumType)
      )
    }
  }
  if (searchParsed.type) {
    dispatch(
      changeEventId(searchParsed.id)
    )
  }

  const platform = searchParsed.platform || searchData.platform.id
  const type = searchParsed.type || searchData.datumType.value
  const id = searchParsed.id || searchData.id

  if (platform && type && id) {
    dispatch(
      eventSearch({
        platform,
        type,
        id,
        pullMetadata: true,
        token
      })
    )
  }
}