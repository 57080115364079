import { InternalClientActionType } from './types'
import {
  InternalAction,
  DataAction,
  DataActionCreator,
  InternalErrorAction,
  InternalDataErrorCreator
} from '../../actions'
import {
  DeleteDatumArgs,
  DeleteDatumAction
} from '../external/deleteDatum'
import { IInternalActionCreator } from '../../reducers/external/actionMapper'

export interface DeleteDatumInternalAction
  extends InternalAction, DataAction<DeleteDatumArgs> {
  type: InternalClientActionType.DELETE_DATUM
}

export const deleteDatumInternal:
  IInternalActionCreator<DeleteDatumInternalAction, DeleteDatumAction> = (
    action: DeleteDatumAction
  ): DeleteDatumInternalAction => ({
    ...action,
    type: InternalClientActionType.DELETE_DATUM
  })

export interface DeleteDatumCompleteInternalAction
  extends InternalAction, DataAction<DeleteDatumArgs> {
    type: InternalClientActionType.DELETE_DATUM_COMPLETE,
    deleteDatumResult: any // TODO Pending
  }

export const deleteDatumCompleteInternal:
  DataActionCreator<DeleteDatumCompleteInternalAction, DeleteDatumArgs> = (
    deleteDatumResult: any, // TODO Pending
    args: DeleteDatumArgs
  ): DeleteDatumCompleteInternalAction => {
    if (!deleteDatumResult.deleteEvent.success) {
      console.error(deleteDatumResult.deleteEvent.message)
      throw new Error(deleteDatumResult.deleteEvent.message || 'unspecified')
    }
    return ({
    type: InternalClientActionType.DELETE_DATUM_COMPLETE,
    deleteDatumResult,
    args
  })
}

export interface DeleteDatumErrorInternalAction
  extends InternalErrorAction<DeleteDatumArgs>, DataAction<DeleteDatumArgs> {
    type: InternalClientActionType.DELETE_DATUM_ERROR
  }

export const deleteDatumErrorInternal:
  InternalDataErrorCreator<DeleteDatumErrorInternalAction, DeleteDatumArgs> = (
    args: DeleteDatumArgs,
    error: any
  ): DeleteDatumErrorInternalAction => {
    return {
      type: InternalClientActionType.DELETE_DATUM_ERROR,
      error,
      args
    }
  }