import { InternalClientActionType } from '../../actions/internal/types'
import { IDataState } from '../../models/state'
import {
  RefreshEventCompleteInternalAction,
  RefreshEventInternalAction,
  RefreshEventErrorInternalAction
} from '../../actions/internal/refreshEvent'

export function refreshEventReducer(
  state: IDataState<any> = {
    fetching: undefined,
    data: undefined
  },
  action: RefreshEventInternalAction |
    RefreshEventCompleteInternalAction |
    RefreshEventErrorInternalAction
): IDataState<any> {
  switch (action.type) {
    case InternalClientActionType.REFRESH_EVENT_COMPLETE:
      return {
        fetching: false,
        data: action.refreshEventResult
      }
    case InternalClientActionType.REFRESH_EVENT:
      return {
        ...state,
        fetching: true
      }
    default:
      return state
  }
}