import { Transerializable } from '@athlinks/get-or'
import { KeycloakInfo } from './KeycloakUserInfo'
import { ModalData } from './Modal'
import { KeycloakResourceAccess, KeycloakRoles } from 'keycloak-js'

export interface IApp extends KeycloakInfo {
}

export class App extends Transerializable implements IApp {
  token: string
  authenticated: boolean
  exp: number
  roles: string[]
  city?: string
  dateOfBirth?: string
  email?: string
  emailVerified?: string
  familyName?: string
  givenName?: string
  name?: string
  preferredUsername?: string
  sub?: string
  modalOpen: ModalData<any>
  modalInput: any
  realmAccess: KeycloakRoles
  resourceAccess: KeycloakResourceAccess
  activeEvents: string[] = []
}