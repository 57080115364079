import {
  Middleware,
  MiddlewareAPI,
  Dispatch
} from 'redux'
import { toast } from 'react-toastify'

import {
  DirectoryEventActionTypes
} from '../actions/external/types'

import { showNotification, NotificationTypes } from '../components/toast/Toast'
import parseGraphQLmsg from '../util/parseGraphQLmsg'

const searchError = (
    getState: Function,
    error: { message: string },
    isDatum: Boolean
  ) => {
    const isPermission = error.message.includes('403')
    const isDuplicate = error.message.includes('409')

    const directoryState = getState()
    const {
      directoryState: {
        searchData: {
          datumType: {
            displayName
          }
        }
      }
    } = directoryState

    const entryType = isDatum ? 'Event' : displayName

    if (isPermission) {
      showNotification({
        title: `We do not have access to this ${entryType}`,
        subTitle: `Please contact partner to allow Athlinks access to this ${entryType}`
      },
      NotificationTypes.ERROR)
    } else if (isDuplicate) {
      const parsedGraphQLMsg = parseGraphQLmsg(error.message).replace('409:', ' ').trim()
      showNotification({
        title: `Cannot link this ${entryType}`,
        subTitle: `${entryType} is already linked to ${parsedGraphQLMsg}`
      },
      NotificationTypes.ERROR)
    } else {
      showNotification({
        title: `Could not find this ${entryType}`,
        subTitle: `Make sure the ${entryType} information is correct`
      }, NotificationTypes.ERROR)
    }
}

export default function() {
    const loggerMiddleware: Middleware = ({ getState }: MiddlewareAPI) => (
      next: Dispatch
    ) => action => {

      const directorySearchAction = action.type === DirectoryEventActionTypes.DIRECTORY_EVENT_SEARCH_ERROR
      const datumSearchAction = action.type     === DirectoryEventActionTypes.DIRECTORY_CREATE_DATUM_ERROR
      const isSearch = action.type              === DirectoryEventActionTypes.DIRECTORY_EVENT_SEARCH
      const isEventSearch = action.type         === DirectoryEventActionTypes.DIRECTORY_CREATE_DATUM

      if (directorySearchAction || datumSearchAction) {
        searchError(getState, action.error, datumSearchAction)
      }

      if (
        isSearch ||
        isEventSearch
      ) {
        // Dissmiss error toasts
        toast.dismiss(1)
      }

      const returnValue = next(action)
      return returnValue
    }

    return loggerMiddleware
  }