import React, {Component} from 'react'
import { Cell, Grid } from 'react-foundation'
import { IEvent } from '@athlinks/admin-events-model'
import { EventRowDivider } from './EventRowDivider'
import { getStartEventUrl, getRequestLogUrl } from '../../util/eventsUtil'

import styles from './EventRow.module.scss'

interface IProps {
  callback: Function
  event: IEvent
  selected: boolean
}
export class EventRow extends Component<IProps> {
  render() {
    const {
      callback,
      event: {
        id,
        name,
        date,
        location,
        timestamp
      },
      selected
    } = this.props

    const eventDate = date.dateTime || ''
    const startUrl = getStartEventUrl(id)
    const requestLogUrl = getRequestLogUrl(id)
    return (
      <div>
        <Grid className={'grid-margin-x'} large={8}>
          <Cell className={'shrink'}>
          <input
              id={`checkbox-${id}`}
              type='checkbox'
              onChange={() => callback(id)}
              checked={selected}
            />
          </Cell>
          <Cell large={4} className={'auto'}>
            <Grid vertical>
              <Cell className={styles.title}>{name}</Cell>
              <Cell className={styles.detailsSubdued}>
                {`#${id}`}
                {/* TODO: space fields with css */}
                {' | '}
                {eventDate.substring(0, eventDate.indexOf('T'))}
                {' | '}
                {`${location.locality}, ${location.region}, ${location.country}`}
              </Cell>
              <Cell>
                {
                  startUrl &&
                  <a className={styles.detailsLink} href={startUrl} target='_blank' rel='noopener noreferrer'>
                    View on Start
                  </a>
                }
                {' | '}
                {
                  requestLogUrl &&
                  <a className={styles.detailsLink} href={requestLogUrl} target='_blank' rel='noopener noreferrer'>
                    View in request log
                  </a>
                }
              </Cell>
            </Grid>
          </Cell>
          <Cell large={5}>
            <Grid className={'grid-margin-x'}>
              <Cell large={4} className={styles.details}>
                {new Date(timestamp.dateTime).toLocaleDateString()}
              </Cell>
            </Grid>
          </Cell>
        </Grid>
        <EventRowDivider />
      </div>
    )
  }
}
