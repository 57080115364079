import {
  EventActionTypes as Action,
  EventStatusChangeAction,
  EventStatusChangeCompleteAction,
  EventStatusChangeErrorAction
} from '@athlinks/admin-events-client'
import {
  EventStatusState,
  IEventStatusState
} from '../models/EventStatusState'

type ActionTypes = EventStatusChangeAction |
                   EventStatusChangeCompleteAction |
                   EventStatusChangeErrorAction

export const eventStatusReducer =
  (state: IEventStatusState = new EventStatusState(),
   action: ActionTypes): IEventStatusState => {
  switch (action.type) {
    case Action.EVENT_STATUS_CHANGE:
      return EventStatusState.build({
        errorMessage: '',
        isLoading: true,
        result: undefined
      })
    case Action.EVENT_STATUS_CHANGE_COMPLETE:
      return EventStatusState.build({
        errorMessage: '',
        isLoading: false,
        result: action.eventStatusResponse.updateEventStatus
      })
    case Action.EVENT_STATUS_CHANGE_ERROR:
      return EventStatusState.build({
        ...state,
        errorMessage: 'the event approve operation was unsuccessful',
        isLoading: false
      })
    default:
      return state
  }
}
