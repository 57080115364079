import React from 'react'
import { Trans } from 'react-i18next'
import { refreshEvent } from '../../actions/external/refreshEvent'
import { connect, Dispatch } from 'react-redux'
import DirectoryAction from './DirectoryActionButton'
import { App } from '../../models/App'
import { State } from '../../models'
import { DirectoryState } from '../../models/Directory'
import './EventDetailsHeader.scss'
import { RefreshIcon } from './Icons'

interface RProps {
  dispatch: Dispatch
  app: App,
  directoryState: DirectoryState,
}

interface IProps extends RProps {
  title?: string,
  onClear: () => void
}

const EventDetailsHeader: React.SFC<IProps> = ({
  title = '',
  onClear,
  dispatch,
  directoryState: {
    searchData,
    refreshEvent: refreshData
  },
  app
}) => {
  const {
    platform,
    datumType,
    id
  } = searchData

  const {
    isLoading: refreshLoading
  } = refreshData

  return (
    <div className='details-header'>
      <h5 className='details-title'>
        <Trans>{title}</Trans>
      </h5>
      <div className='action-buttons top'>
        <DirectoryAction
          className='event-details-action-button'
          label='Refresh Event'
          disabled={refreshLoading}
          icon={RefreshIcon}
          onClick={() => dispatch(
            refreshEvent({
              platform: platform.id,
              type: datumType.value,
              id,
              token: app.token
            })
          )}
        />
        <DirectoryAction
          className='event-details-action-button'
          label='Clear Selection'
          icon={<i className='icon icon ion-close' />}
          onClick={onClear}
        />
      </div>
    </div>
  )
}

export default connect((state: State): RProps => ({
  app: state.app,
  directoryState: state.directoryState,
  dispatch: undefined
}))(EventDetailsHeader)