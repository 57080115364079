import React, { Component, ReactNode } from 'react'
import { Trans } from 'react-i18next'
import { connect, Dispatch } from 'react-redux'
import Button from '../shared/Button'
import { isEmpty } from 'lodash'
import { setModalInput, setModalOpen } from '../../actions/app'
import { App } from '../../models/App'
import { ModalAction } from '../../models/Modal'
import { State } from '../../models'
import './Modal.scss'

interface RProps {
  app: App
  dispatch: Dispatch
}

interface Props {
  title: string
  body: ReactNode
  action?: ModalAction,
  isOpen: boolean
}

class Modal extends Component<Props & RProps> {
  onCancel = () => {
    this.props.dispatch(setModalOpen(undefined))
    this.props.dispatch(setModalInput(undefined))
  }

  onDispatch = (handler, modalInput) => {
    const {
      app: { token },
      dispatch
    } = this.props
    const dispatchArgs = { ...modalInput, token }

    dispatch(handler(dispatchArgs))
  }

  render() {
    const {
      app: {
        modalInput
      },
      title,
      body,
      action,
      isOpen
    } = this.props

    if (!isOpen) {
      return null
    }

    return (
      <div className='modal-outer' onClick={this.onCancel}>
        <div className='modal-inner' onClick={(e) => e.stopPropagation()}>
          <div className='modal-header'>
            <div className='modal-title'>
              <Trans>{title}</Trans>
            </div>
            <i
              className='icon icon ion-close modal-close-icon'
              onClick={this.onCancel}
            />
            <div className='modal-body'>
              {body}
            </div>
            <div className='modal-footer'>
              <Button
                className={'secondary modal-button'}
                disabled={false}
                label='Cancel'
                onClick={this.onCancel}
              />
              {!action
                ? null
                : <Button
                  className={'modal-button action'}
                  disabled={isEmpty(modalInput)}
                  label={action.label}
                  onClick={() => {
                    if (action.dispatchHandler) {
                      this.onDispatch(action.dispatchHandler, modalInput)
                    }
                    this.onCancel()
                  }}
                />
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect((state: State): RProps => ({
  app: state.app,
  dispatch: undefined
}))(Modal)