import React, { Component } from 'react'
import { Button } from '@athlinks/component-library'
import { Cell, Grid, GridContainer, Menu, MenuItem } from 'react-foundation'
import { connect } from 'react-redux'

import { App as AppState } from '../../models/App'
import { Routes } from '../../util/routes'
import { State } from '../../models/index'

import athlinksServices from '../../shared/images/athlinks-services.svg'
import styles from './PropositionPage.module.scss'

interface Props {
  App: AppState
}

class PropositionPage extends Component<Props> {
  constructor(props: Props) {
    super(props)

    this.renderFooter = this.renderFooter.bind(this)
    this.renderLinking = this.renderLinking.bind(this)
  }

  renderFooter() {
    return (
      <div className={styles['footer-container']}>

      </div>
    )
  }

  renderLinking() {
    return (
      <div className={styles['proposition-header']}>
        <Menu>
          <MenuItem>
            <img className={styles['athlinks-flame']} src={athlinksServices} alt='Athlinks Services'/>
          </MenuItem>
        </Menu>
        <div className={'grid-container-wrapper'}>
          <div className={'grid-container'}>
            <GridContainer>
              <Grid className={styles['sign-in-button-container']}>
                <Cell medium={12} float={'center'}>
                  <Button
                    maxWidth={'20rem'}
                    route={Routes.PARTNER_SYNC}
                    color={'orange'}
                    text={'SIGN INTO ATHINKS SERVICES'}
                  />
                </Cell>
              </Grid>
            </GridContainer>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div>
        {this.renderLinking()}
        {this.renderFooter()}
      </div>
    )
  }
}

export default connect((state: State): Props => ({
  App: state.app
}))(PropositionPage)
