import React, { Component } from 'react'
import { Cell, Grid } from 'react-foundation'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import { Dropdown, IChoice } from '../dropdown/Dropdown'
import { Routes } from '../../util/routes'
import { State } from '../../models'

import styles from './Header.module.scss'

interface Dispatchable {
  authenticated: boolean
  dispatch: Dispatch,
  name: string
}
interface Props extends Dispatchable {
  subtitle: String,
  title: String
}

interface IProps {
  title: String,
  subTitle: String
}

class Header extends Component<Props & IProps> {
  render() {
    const {
      authenticated,
      title,
      subTitle
    } = this.props

    const dropdownValues: IChoice[] = [
      {
        label: 'LOGOUT',
        route: Routes.LOGOUT
      }
    ]
    return (
      <Cell className={styles.headerGridContainer}>
        <Grid>
          <Cell small={12} medium={6}>
            <div className={styles.title}>{title}</div>
            <div className={styles.subtitleContainer}>
              <div className={styles.subtitle}>{subTitle}</div>
              <div className={styles.lastUpdated}>
                Last Updated {new Date().toLocaleTimeString()}
              </div>
            </div>
          </Cell>
          <Cell id='logout' small={12} medium={6} className={styles.userContextContainer}>
            {!authenticated
              ? null
              : <Dropdown title={this.props.name}
                choices={dropdownValues}
                buttonClass={'header-dropdown-button'}
                menuClass={'header-dropdown-menu'}
              />
            }
          </Cell>
        </Grid>
      </Cell>
    )
  }
}

export default connect((state: State): Dispatchable => ({
  authenticated: state.app.authenticated,
  dispatch: undefined,
  name: state.app.name
}))(Header)