import { ActionsObservable, ofType } from 'redux-observable'
import {
  EventSearchInternalAction,
  EventSearchCompleteInternalAction,
  EventSearchErrorInternalAction,
  eventSearchCompleteInternal,
  eventSearchErrorInternal
} from '../actions/internal/eventSearch'
import {
  Observable,
  from,
  of
} from 'rxjs'
import { InternalClientActionType } from '../actions/internal/types'
import {
  flatMap,
  catchError
} from 'rxjs/operators'
import { waitForData } from './util'
import { eventSearchQueryBuilder } from './util/queries'

export function eventSearchEpic(
  actions$: ActionsObservable<EventSearchInternalAction>
): Observable<EventSearchCompleteInternalAction |
  EventSearchErrorInternalAction> {
    return actions$.pipe(
      ofType(InternalClientActionType.EVENT_SEARCH),
      flatMap(action$ => {
        const { args } = action$
        return from(
          waitForData(
            args,
            eventSearchQueryBuilder,
            eventSearchCompleteInternal,
            eventSearchErrorInternal
          )
        ).pipe(
          catchError(err => of(err))
        )
      }),
      catchError(err => of(err))
    )
  }