import React from 'react'
import { connect } from 'react-redux'
import { Component } from 'react'
import { Dispatch } from 'redux'

import { State } from '../../models'
import { unauth } from '../../actions/auth'
import { Routes } from './../../util/routes'
import { Redirect } from 'react-router'

interface Props {
  dispatch: Dispatch,
  authenticated: boolean
}

class Logout extends Component<Props> {

  componentDidMount() {
    if (this.props.authenticated) {
      this.props.dispatch(unauth())
    }
  }

  render() {
    const {
      authenticated
    } = this.props
    return authenticated ? (
      <div>
        Logging out...
      </div>
    ) : (
      <Redirect to={{
        pathname: Routes.PROPOSITION
      }} />
    )
  }
}

export default connect((state: State): Props => ({
   dispatch: undefined,
   authenticated: state.app.authenticated
 }
))(Logout)